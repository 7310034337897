import React from 'react';

import './radio.scss';

export default ({ name, legend, options, onChange, selectedValue }) => (
    <form onChange={onChange} className="Radio">
        <fieldset>
            <legend>{legend}</legend>
            <div className="RadioButtons">
                {options.map((option, index) => (
                    <span key={index}>
                        <label htmlFor={option.name}>{option.name}</label>
                        <input
                            type="radio"
                            name={name}
                            value={option.value}
                            id={option.name}
                            checked={option.value.toString() === selectedValue}
                            onChange={() => {}}
                        />
                    </span>
                ))}
            </div>
        </fieldset>
    </form>
);
