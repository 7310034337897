import { INSTRUMENT_HOTFIX_STATUS } from '../../common/utils/hotfixdetails';

export const isApplied = status =>
    INSTRUMENT_HOTFIX_STATUS[status] === INSTRUMENT_HOTFIX_STATUS.APPLIED;
export const isRevoked = status =>
    INSTRUMENT_HOTFIX_STATUS[status] === INSTRUMENT_HOTFIX_STATUS.REVOKED;
export const isNotApplied = status =>
    INSTRUMENT_HOTFIX_STATUS[status] === INSTRUMENT_HOTFIX_STATUS.NOT_APPLIED;

export const buildInstrumentsHotfixesMatrix = (
    platformType,
    instruments,
    hotfixes
) => {
    const hotfixesByPlatformType = hotfixes.filter(
        m => m.platformType === platformType
    );

    const header = ['Platform Type', 'Size', 'Serial Number'];
    hotfixesByPlatformType.forEach(m => header.push(`""="${m.id}"`)); //interpret numbers as string

    return instruments.reduce(
        (acc, inst) => {
            const instrumentData = [];

            instrumentData.push(platformType);
            instrumentData.push(`""="${inst.instrumentId.size}"`);
            instrumentData.push(`""="${inst.instrumentId.serialNumber}"`);

            const instrumentHotFixes = inst.metadata.hotfixes;

            hotfixesByPlatformType.forEach(m => {
                const currentHotFix = instrumentHotFixes.find(
                    hotfix => hotfix.id === m.id
                );
                instrumentData.push(
                    currentHotFix ? currentHotFix.status : 'NOT APPLIED'
                );
            });

            acc.push(instrumentData);
            return acc;
        },
        [header]
    );
};
