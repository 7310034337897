import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import HotFixInfo from './HotFixInfo';
import InstrumentsByHotFixList from './InstrumentsByHotFixList';
import { fetchInstrumentsByHotFixAction } from '../../controllers/hotfix/actions';
import { applyHotFixAction, undoHotFixAction } from '../../controllers/hotfix';
import Loading from '../loading/Loading';

const InstrumentsByHotFix = props => {
    const {
        fetchInstrumentsByHotFixAction,
        applyHotFixAction,
        undoHotFixAction,
        instruments,
        hotfixes,
        selectedId,
        loading
    } = props;

    const getCurrentHotFix = () =>
        selectedId ? hotfixes.find(hotfix => hotfix.id === selectedId) : null;

    useEffect(
        () => {
            const currentHotFix = getCurrentHotFix();
            if (currentHotFix) {
                fetchInstrumentsByHotFixAction(currentHotFix);
            }
        },
        [selectedId]
    );

    const currentHotFix = getCurrentHotFix();

    return currentHotFix ? (
        <Loading loading={loading}>
            <div className="RightSideVerticalContainer">
                <HotFixInfo {...currentHotFix} />
                <InstrumentsByHotFixList
                    instruments={instruments}
                    loading={loading}
                    applyHotFixAction={applyHotFixAction}
                    undoHotFixAction={undoHotFixAction}
                    hotfix={currentHotFix}
                />
            </div>
        </Loading>
    ) : (
        <p className="Empty">Please select a HotFix from the left menu.</p>
    );
};

InstrumentsByHotFix.propTypes = {
    selectedId: PropTypes.string,
    hotfixes: PropTypes.array,
    instruments: PropTypes.array,
    loading: PropTypes.bool,
    fetchInstrumentsByHotFixAction: PropTypes.func,
    applyHotFixAction: PropTypes.func,
    undoHotFixAction: PropTypes.func
};

export default connect(
    ({
        instrumentsByHotFix: { instruments, loading },
        hotfixes: { items: hotfixes }
    }) => ({
        instruments: Array.isArray(instruments) ? instruments : [],
        loading: !!loading,
        hotfixes
    }),
    {
        fetchInstrumentsByHotFixAction,
        applyHotFixAction,
        undoHotFixAction
    }
)(InstrumentsByHotFix);
