import { FETCH_SUCCESS } from '../../fetch';
import { INSTRUMENT_MODKIT_STATUS } from '../../../common/utils/details';
import { FETCH_INSTRUMENTS_BY_MODKIT } from '../constants';

const setModKitData = (instrument, id) => {
    const modkit = instrument.metadata.modKits.find(m => m.id === id);

    return {
        modified: modkit ? modkit.modified : null,
        status: modkit
            ? modkit.status
            : INSTRUMENT_MODKIT_STATUS.NOT_APPLIED.toUpperCase()
    };
};

const setStatus = (instruments, id) => {
    if (!instruments) return [];
    return instruments.map(instrument => ({
        ...instrument,
        ...setModKitData(instrument, id)
    }));
};

export const formatInstrumentsByModkitResponse = (
    state,
    { type, meta, passToResponse }
) => {
    if (
        meta &&
        meta.namespace === FETCH_INSTRUMENTS_BY_MODKIT &&
        type === FETCH_SUCCESS
    ) {
        return {
            ...state,
            instruments: setStatus(state.instruments, passToResponse.id)
        };
    } else {
        return state;
    }
};
