import {
    CREATE_TAG,
    CLEAR_CREATE_TAG,
    ASSIGN_TAG_TO_INSTRUMENTS,
    CLEAR_ASSIGN_TAG,
    FETCH_TAGS_LIST,
    UNASSIGN_TAG_FROM_INSTRUMENTS,
    DELETE_TAG,
    CLEAR_DELETE_TAG,
    FETCH_INSTRUMENTS_BY_TAG,
    CLEAR_INSTRUMENTS_BY_TAG
} from './constants';

export const fetchTagListAction = () => ({
    type: FETCH_TAGS_LIST
});

export const createTagAction = tagText => ({
    type: CREATE_TAG,
    tagText
});

export const clearCreateTagAction = () => ({
    type: CLEAR_CREATE_TAG
});

export const clearAssignTagAction = () => ({
    type: CLEAR_ASSIGN_TAG
});

export const assignTagToInstruments = (tagId, instrumentIds) => ({
    type: ASSIGN_TAG_TO_INSTRUMENTS,
    tagId,
    instrumentIds
});

export const unassignTagFromInstrumentsAction = (tagId, instrumentIds) => ({
    type: UNASSIGN_TAG_FROM_INSTRUMENTS,
    tagId,
    instrumentIds
});

export const deleteTagAction = tagId => ({
    type: DELETE_TAG,
    tagId
});

export const clearDeleteTagAction = () => ({
    type: CLEAR_DELETE_TAG
});

export const fetchInstrumentsByTag = tagId => ({
    type: FETCH_INSTRUMENTS_BY_TAG,
    tagId
});

export const clearInstrumentsByTag = () => ({
    type: CLEAR_INSTRUMENTS_BY_TAG
});
