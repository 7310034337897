export const FETCH_TAGS_LIST = 'tags.fetchTagList';
export const CREATE_TAG = 'tags.createTag';
export const DELETE_TAG = 'tags.deleteTag';
export const CLEAR_DELETE_TAG = 'tags.clearDeleteTag';
export const CLEAR_CREATE_TAG = 'tags.clearCreateTag';
export const CLEAR_ASSIGN_TAG = 'tags.clearAssignTag';
export const ASSIGN_TAG_TO_INSTRUMENTS = 'tags.assignTagToInstruments';
export const UNASSIGN_TAG_FROM_INSTRUMENTS = 'tags.unassignTagFromInstruments';
export const FETCH_INSTRUMENTS_BY_TAG = 'tags.fetchInstrumentsByTag';
export const CLEAR_INSTRUMENTS_BY_TAG = 'tags.clearInstrumentsByTag';
