import React, { useState } from 'react';
import Button from '../../button/Button';
import Checkbox from '../../checkbox/Checkbox';
import { exportToHtml } from '../../../common/utils/exportToFile';
import { getRevisionInHtmlFormat } from './getRevisionInHtmlFormat';
import { capitalize } from '../../../common/utils';
import styles from './ExportHtmlForm.module.scss';

const sectionNames = [
    'hardware',
    'software',
    'modKits',
    'updates',
    'logbook',
    'hotfixes'
];

const ExportHtmlForm = ({ data }) => {
    const [options, setOptions] = useState({});
    const isDisabled =
        Object.keys(options).filter(name => options[name]).length === 0;

    const handleSubmit = () =>
        exportToHtml(
            getRevisionInHtmlFormat(data, options),
            `${data.id}-${new Date(
                data.currentRevision.modificationTime
            ).getTime()}`
        );

    const handleChange = ({ target: { checked, value } }) =>
        setOptions({
            ...options,
            [value]: checked
        });

    return (
        <form className="ExportForm">
            {sectionNames.map(name => (
                <label className={styles.OptionLabel} key={name}>
                    <Checkbox
                        className={styles.OptionCheckbox}
                        value={name}
                        onChange={handleChange}
                    />
                    {capitalize(name)}
                </label>
            ))}
            <Button
                className={styles.ExportButton}
                onClick={handleSubmit}
                disabled={isDisabled}
                look="Primary"
            >
                Export
            </Button>
        </form>
    );
};

export default ExportHtmlForm;
