import {
    CHANGE_ADDRESS,
    CLOSE_LOCATIONS_LIST,
    ENABLE_ADDRESS_EDIT,
    OPEN_LOCATIONS_LIST,
    RESET_STATE,
    SAVE_LOCATION,
    SELECT_LOCATION,
    UPDATE_SEARCH_RESULTS
} from './constants';

export const changeAddressAction = address => ({
    type: CHANGE_ADDRESS,
    address
});

export const closeLocationsListAction = () => ({
    type: CLOSE_LOCATIONS_LIST
});

export const enableAddressEditAction = () => ({
    type: ENABLE_ADDRESS_EDIT
});

export const openLocationsListAction = () => ({
    type: OPEN_LOCATIONS_LIST
});

export const resetStateAction = initialState => ({
    type: RESET_STATE,
    initialState
});

export const saveLocationAction = () => ({
    type: SAVE_LOCATION
});

export const selectLocationAction = ({ address, selectedLocation }) => ({
    type: SELECT_LOCATION,
    address,
    selectedLocation
});

export const updateSearchResultsAction = searchResults => ({
    type: UPDATE_SEARCH_RESULTS,
    searchResults
});
