import React, { Component } from 'react';
import Criteria from './criteria/Criteria';
import Button from '../button/Button';
import { PropTypes } from 'prop-types';
import CriteriaData, {
    validateCriterias
} from '../../common/utils/CriteriaData';
import AddIcon from '../image/AddIcon';

import './criterias.scss';

class Criterias extends Component {
    state = {
        criterias: [],
        valid: null
    };

    validateCriterias = () => {
        const { criterias } = this.state;
        const valid = validateCriterias(criterias);
        this.setState(
            state => {
                const newState = { ...state };
                newState.criterias = Array.from(criterias);
                newState.valid = valid;
                return newState;
            },
            () => {
                const queryParams = this.state.criterias.map(
                    q => q.queryParams
                );

                this.props.onChange({ queryParams, valid: this.state.valid });
            }
        );
    };

    renderCriterias = criterias =>
        criterias.map(criteriaData => (
            <Criteria
                {...criteriaData}
                removeCriteria={this.removeCriteria.bind(
                    null,
                    criteriaData.key
                )}
                serialize={data => {
                    criteriaData.serialize(data);
                    this.validateCriterias();
                }}
            />
        ));

    addCriteria = () => {
        this.setState(
            state => ({
                criterias: state.criterias.concat(
                    new CriteriaData(this.props.queryParamsMetaData)
                )
            }),
            () => this.validateCriterias()
        );
    };

    checkEmptyCriterias = criterias => {
        if (criterias.length === 0) {
            this.timer = setTimeout(this.addCriteria, 0);
        }

        return criterias;
    };

    componentWillUnmount = () => {
        if (this.timer) {
            clearTimeout(this.timer);
        }
    };

    removeCriteria = key => {
        this.setState(
            state => {
                const newState = { ...state };
                newState.criterias = state.criterias.filter(c => c.key !== key);
                return newState;
            },
            () => this.validateCriterias()
        );
    };
    render = () => {
        const criterias = this.checkEmptyCriterias(this.state.criterias);

        return (
            <div className="Criterias">
                <div>{this.renderCriterias(criterias)}</div>
                <div>
                    <Button
                        look="Transparent"
                        onClick={this.addCriteria}
                        className="AddCriteria"
                    >
                        <AddIcon className="AddIcon" />
                    </Button>
                </div>
            </div>
        );
    };
}

Criterias.propTypes = {
    queryParamsMetaData: PropTypes.shape({
        hardware: PropTypes.object,
        hardwareModules: PropTypes.array,
        software: PropTypes.object,
        metadata: PropTypes.array,
        modKits: PropTypes.object,
        operators: PropTypes.object
    }).isRequired,
    onChange: PropTypes.func.isRequired
};

export default Criterias;
