import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import Header from '../header/Header';
import Loading from '../loading/Loading';
import { getCloseModalWindowAction } from '../../controllers/modal';
import Modal from '../modal/Modal';
import Snackbar from '../snackbar/Snackbar';
import {
    ERROR_DISPLAY_TIMEOUT,
    MESSAGE_DISPLAY_TIMEOUT
} from '../../common/constants';

import './main.scss';

const Main = props => {
    const {
        username,
        userRoles,
        loading,
        modalTitle,
        modalComponent,
        modalProps,
        snackbarMessage,
        snackbarMessageId,
        snackbarMode,
        snackbarOnHide,
        getCloseModalWindowAction
    } = props;

    const mainArea = <Loading loading={loading}>{props.children}</Loading>;

    return (
        <div className="App">
            <Header username={username} userRoles={userRoles} />
            <main className="Main">{mainArea}</main>
            <Modal
                title={modalTitle}
                component={modalComponent}
                isOpen={modalComponent !== null}
                onClose={getCloseModalWindowAction}
                {...modalProps}
            />
            <Snackbar
                position="bottom-left"
                defaultOpen={!!snackbarMessage}
                mode={snackbarMode}
                message={snackbarMessage}
                messageId={snackbarMessageId}
                onHide={snackbarOnHide}
                stayVisibleInterval={
                    snackbarMode === 'error'
                        ? ERROR_DISPLAY_TIMEOUT
                        : MESSAGE_DISPLAY_TIMEOUT
                }
            />
        </div>
    );
};

Main.propTypes = {
    username: PropTypes.string.isRequired,
    userRoles: PropTypes.array,
    loading: PropTypes.bool,
    history: PropTypes.object,
    location: PropTypes.object
};

export default withRouter(
    connect(
        ({
            user: { firstName, lastName, roles, loading = false },
            modal: { component, title, props },
            snackbar: { message, messageId, mode, onHide }
        }) => ({
            username: `${firstName} ${lastName}`,
            userRoles: roles,
            loading,
            modalComponent: component,
            modalTitle: title,
            modalProps: props,
            snackbarMessage: message,
            snackbarMessageId: messageId,
            snackbarMode: mode,
            snackbarOnHide: onHide
        }),
        {
            getCloseModalWindowAction
        }
    )(Main)
);
