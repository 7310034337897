import React from 'react';

const AddIcon = ({ className, onClick }) => (
    <svg onClick={onClick} viewBox="0 0 48 48" className={className}>
        <circle
            cx="24"
            cy="24"
            r="22"
            fill="none"
            stroke="currentColor"
            strokeWidth="2px"
        />
        <path d="M23,12h2V36H23V12ZM12,23H36v2H12V23Z" fill="currentColor" />
    </svg>
);

export default AddIcon;
