import { all, put, take, takeEvery, takeMaybe } from 'redux-saga/effects';
import { UPDATE_LOCATION } from './constants';
import {
    FETCH_SUCCESS,
    fetchDataAction,
    onError,
    onSuccessful
} from '../fetch';
import { openSnackbarAction } from '../snackbar/actions';
import { selectInstrumentAction } from '../query';

let messageId = 1;

function* updateLocation({ instrumentId, location }) {
    yield put(
        fetchDataAction(UPDATE_LOCATION)(
            `/api/v1/instruments/${instrumentId}/location`,
            {
                method: 'put',
                data: location
            }
        )
    );

    const action = yield takeMaybe([
        onSuccessful(UPDATE_LOCATION),
        onError(UPDATE_LOCATION)
    ]);

    if (action && action.type === FETCH_SUCCESS) {
        yield put(
            openSnackbarAction({
                messageId: messageId++,
                message: 'The location has been updated successfully',
                mode: 'success'
            })
        );

        yield put(selectInstrumentAction(instrumentId));
    }
}

function* watchUpdateLocation() {
    yield takeEvery(UPDATE_LOCATION, updateLocation);
}

export function* locationSagas() {
    yield all([watchUpdateLocation()]);
}
