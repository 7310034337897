import {
    FETCH_HOTFIX_LIST,
    HOTFIX_LIST_IS_UPDATING,
    CREATE_HOTFIX,
    CLEAR_CREATE_HOTFIX,
    APPLY_HOTFIX,
    UNDO_HOTFIX,
    CLEAR_CHANGE_HOTFIX_STATUS,
    DELETE_HOTFIX,
    RETIRE_HOTFIX,
    HOTFIX_STATUS,
    REFRESH_HOTFIX_LIST,
    FETCH_TYPES,
    FETCH_INSTRUMENTS_BY_HOTFIX,
    EXPORT_HOTFIXS_MATRIX,
    PROMOTE_HOTFIX
} from './constants';

export const fetchHotFixListAction = () => ({
    type: FETCH_HOTFIX_LIST
});

export const refreshHotFixListAction = () => ({
    type: REFRESH_HOTFIX_LIST
});

export const hotfixListIsUpdatingAction = () => ({
    type: HOTFIX_LIST_IS_UPDATING
});

export const createHotFixAction = formData => ({
    type: CREATE_HOTFIX,
    formData
});

export const applyHotFixAction = (
    { id, comment, instrumentId },
    reloadInstrument
) => ({
    type: APPLY_HOTFIX,
    id,
    comment,
    instrumentId,
    reloadInstrument
});

export const undoHotFixAction = (
    { id, comment, instrumentId },
    reloadInstrument
) => ({
    type: UNDO_HOTFIX,
    id,
    comment,
    instrumentId,
    reloadInstrument
});

export const deleteHotFixAction = id => ({
    type: DELETE_HOTFIX,
    id
});

export const retireHotFixAction = id => ({
    type: RETIRE_HOTFIX,
    id
});

export const displayHotFixStatusAction = (id, namespace, message, status) => ({
    type: HOTFIX_STATUS,
    id,
    namespace,
    message,
    status
});

export const clearCreateHotFixAction = () => ({
    type: CLEAR_CREATE_HOTFIX
});

export const clearChangeHotFixStatusAction = () => ({
    type: CLEAR_CHANGE_HOTFIX_STATUS
});

export const fetchTypesAction = () => ({
    type: FETCH_TYPES
});

export const fetchInstrumentsByHotFixAction = modKit => ({
    type: FETCH_INSTRUMENTS_BY_HOTFIX,
    id: modKit.id,
    platformType: modKit.platformType
});

export const promoteHotFixAction = id => ({
    type: PROMOTE_HOTFIX,
    id
});

export const exportHotfixesMatrixAction = ({ platformType, hotfixes }) => ({
    type: EXPORT_HOTFIXS_MATRIX,
    platformType,
    hotfixes
});
