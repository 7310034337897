import React from 'react';

const MenuIcon = ({ className }) => (
    <svg className={className} viewBox="0 0 24 24">
        <path
            fill="currentColor"
            d="M 12 3 C 10.895431 3 10 3.8954305 10 5 C 10 6.1045695 10.895431 7 12 7 C 13.104569 7 14 6.1045695 14 5 C 14 3.8954305 13.104569 3 12 3 z M 12 10 C 10.895431 10 10 10.895431 10 12 C 10 13.104569 10.895431 14 12 14 C 13.104569 14 14 13.104569 14 12 C 14 10.895431 13.104569 10 12 10 z M 12 17 C 10.895431 17 10 17.895431 10 19 C 10 20.104569 10.895431 21 12 21 C 13.104569 21 14 20.104569 14 19 C 14 17.895431 13.104569 17 12 17 z"
        />
    </svg>
);

export default MenuIcon;
