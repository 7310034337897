import React, { useEffect, useReducer, useState } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import azure from 'azure-storage';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import TreeListItem from './TreeListItem';
import {
    storageBrowserActionTypes,
    storageBrowserReducer,
    initialStorageBrowserState
} from './storageBrowserReducer';
import { FILE, FOLDER } from '../../common/constants';
import Button from '../button/Button';
import Selector from '../selector/Selector';
import {
    triggerSoftwareUpdateAction,
    clearTriggerSoftwareUpdateAction
} from '../../controllers/update/actions';
import CopyToClipboardIcon from '../image/CopyToClipboardIcon';
import StatusMessage from '../statusMessage/StatusMessage';
import Loading from '../loading/Loading';

import styles from './StorageBrowser.module.scss';

let fileService = null;

const EMPTY = 'EMPTY';

const UPDATE_TYPES = {
    [EMPTY]: '-',
    UPDATE_SOFTWARE: 'Update Software',
    UPDATE_CONFIGURATION: 'Update Configuration'
};

const {
    SET_SHARES,
    OPEN_FOLDER,
    CLOSE_FOLDER,
    SELECT_FILE
} = storageBrowserActionTypes;

const StorageBrowser = props => {
    const {
        selectedInstruments,
        triggerSoftwareUpdateAction,
        clearTriggerSoftwareUpdateAction,
        loading,
        message,
        statusClass,
        fileServiceUrl,
        fileServiceToken
    } = props;

    const [selectedUpdateType, setSelectedUpdateType] = useState(EMPTY);
    const [state, dispatch] = useReducer(
        storageBrowserReducer,
        initialStorageBrowserState
    );
    const {
        fileDownloadUrl,
        foldersOpenStatus,
        selectedListItem,
        shares
    } = state;

    //fetch the list with shares
    useEffect(() => {
        if (!fileService) {
            fileService = azure.createFileServiceWithSas(
                fileServiceUrl,
                fileServiceToken
            );
        }

        fileService.listSharesSegmented(null, (error, result) => {
            if (error) {
                console.error(error);
            } else {
                const shares = result.entries.map(({ name }) => ({
                    name,
                    directoryName: '',
                    fullPath: name,
                    type: FOLDER,
                    shareName: name
                }));

                dispatch({
                    type: SET_SHARES,
                    shares
                });
            }
        });

        clearTriggerSoftwareUpdateAction();
    }, []);

    const getFileUrl = (fullPath, shareName, directoryName, fileName) => {
        const fileDownloadUrl = fileService.getUrl(
            shareName,
            directoryName,
            fileName,
            fileServiceToken
        );

        dispatch({
            type: SELECT_FILE,
            fullPath,
            fileDownloadUrl
        });
    };

    const fetchFilesAndDirectories = (shareName, directoryName, callback) => {
        fileService.listFilesAndDirectoriesSegmented(
            shareName,
            directoryName,
            null,
            (error, result) => {
                if (error) {
                    console.error(error);
                } else {
                    callback(result.entries);
                }
            }
        );
    };

    const handleClick = (listItem, callback) => {
        resetSelection();

        const { fullPath, type, shareName, directoryName, name } = listItem;

        if (type === FOLDER) {
            if (!!foldersOpenStatus[fullPath]) {
                dispatch({
                    type: CLOSE_FOLDER,
                    fullPath
                });
            } else {
                if (typeof foldersOpenStatus[fullPath] === 'undefined') {
                    fetchFilesAndDirectories(
                        shareName,
                        directoryName,
                        result => {
                            dispatch({
                                type: OPEN_FOLDER,
                                fullPath
                            });
                            callback(result);
                        }
                    );
                } else {
                    dispatch({
                        type: OPEN_FOLDER,
                        fullPath
                    });
                }
            }
        }

        if (type === FILE) {
            getFileUrl(fullPath, shareName, directoryName, name);
        }
    };

    const handleOptionChange = event =>
        setSelectedUpdateType(event.target.value);

    const handleSubmit = () => {
        resetSelection();

        triggerSoftwareUpdateAction(
            selectedListItem,
            selectedInstruments,
            selectedUpdateType
        );
    };

    const resetSelection = () => {
        message && clearTriggerSoftwareUpdateAction();
    };

    const getSelectorOptions = () =>
        Object.keys(UPDATE_TYPES).map(type => ({
            name: UPDATE_TYPES[type],
            value: type
        }));

    return (
        <div className={styles.SoftwareUpdateContainer}>
            <div className={classnames(styles.UpdateTypeContainer)}>
                <div className={classnames(styles.UpdateTypeLabel)}>
                    Update type
                </div>
                <Selector
                    className={styles.SelectUpdateType}
                    options={getSelectorOptions()}
                    selectedOption={selectedUpdateType}
                    onSelectionChange={handleOptionChange}
                />
            </div>
            <div className={styles.BrowserContainer}>
                <div className={styles.BrowserLabel}>Browse</div>
                <div className={styles.Browser}>
                    <ul className={styles.List}>
                        {shares.map(share => (
                            <TreeListItem
                                key={share.fullPath}
                                listItem={share}
                                selectedListItem={selectedListItem}
                                foldersOpenStatus={foldersOpenStatus}
                                clickHandler={handleClick}
                            />
                        ))}
                    </ul>
                </div>
            </div>
            <div className={styles.UrlContainer}>
                <div className={styles.UrlLabel}>Package Url</div>
                <input
                    className={styles.UrlText}
                    type="text"
                    value={fileDownloadUrl}
                    disabled
                />
                <CopyToClipboard
                    className={styles.CopyLink}
                    text={fileDownloadUrl}
                >
                    <div>
                        <CopyToClipboardIcon
                            className={styles.CopyLinkImage}
                            title="copy link to clipboard"
                        />
                    </div>
                </CopyToClipboard>
            </div>
            <div className={styles.Message}>
                <Loading loading={loading} mode="text">
                    <StatusMessage
                        message={message}
                        statusClass={statusClass}
                    />
                </Loading>
            </div>
            <Button
                data-test="sendButton"
                look="Primary"
                onClick={handleSubmit}
                width="FullWidth"
                disabled={
                    !fileDownloadUrl || loading || selectedUpdateType === EMPTY
                }
            >
                Send Update ({selectedInstruments.length} instrument
                {selectedInstruments.length > 1 ? 's' : ''})
            </Button>
        </div>
    );
};

const mapStateToProps = ({
    user: { fileStorageAccess },
    selectedInstruments,
    triggerSoftwareUpdate: { loading, message, statusClass }
}) => ({
    selectedInstruments,
    loading: !!loading,
    message,
    statusClass,
    fileServiceUrl: fileStorageAccess.serviceUrl,
    fileServiceToken: fileStorageAccess.token
});

const mapDispatchToProps = {
    triggerSoftwareUpdateAction,
    clearTriggerSoftwareUpdateAction
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(StorageBrowser);
