import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import SupportForm from './SupportForm';
import {
    saveSupportListAction,
    deleteSupportAction
} from '../../controllers/support';
import { bindActionCreators } from 'redux';

function SupportDetails({
    saveSupportListAction,
    deleteSupportAction,
    dispatch,
    supports,
    users,
    ...props
}) {
    const [support, setSupport] = useState({ ...props.support });
    const [errors, setErrors] = useState({});
    const [timezone, setTimezone] = useState({});
    const { actions } = props;

    useEffect(
        () => {
            setSupport({ ...props.support });

            timezone.browserTimeZoneOffset = getTimezoneOffset();
            timezone.browserTimeZoneName = Intl.DateTimeFormat().resolvedOptions().timeZone;
            setTimezone(timezone);

            if (
                support.sessionTimeZone == null ||
                support.sessionTimeZone == undefined
            ) {
                setSupport({
                    ...support,
                    sessionTimeZone: timezone.browserTimeZoneOffset
                });
            }
        },
        [props.supports]
    );

    function getTimezoneOffset() {
        function z(n) {
            return (n < 10 ? '0' : '') + n;
        }
        var offset = new Date().getTimezoneOffset();
        var sign = offset < 0 ? '+' : '-';
        offset = Math.abs(offset);
        return sign + z((offset / 60) | 0) + ':' + z(offset % 60);
    }

    function handleChange({ target }) {
        setSupport({
            ...support,
            [target.name]: target.value
        });
    }

    function formIsValid() {
        const _errors = {};
        if (
            (support.sessionDate != null || support.sessionTime != null) &&
            (support.sessionTimeZone == undefined ||
                support.sessionTimeZone == '')
        ) {
            _errors.title = (
                <div className="alert alert-danger" style={{ marginTop: 5 }}>
                    Please select time zone!
                </div>
            );
            setErrors(_errors);
            return false;
        }
        return true;
    }

    function isSessionTimeValid() {
        const _errors = {};
        if (
            (support.sessionDate != null && support.sessionTime == null) ||
            (support.sessionDate == null && support.sessionTime != null)
        ) {
            _errors.title = (
                <div className="alert alert-danger" style={{ marginTop: 5 }}>
                    Please select date and time!
                </div>
            );
            setErrors(_errors);
            return false;
        }
        return true;
    }

    function supporterIsValid() {
        const _errors = {};
        if (
            (support.sessionDate != null || support.sessionTime != null) &&
            (support.supporterName == null ||
                support.supporterName == 'Select' ||
                support.supporterName == undefined)
        ) {
            _errors.title = (
                <div className="alert alert-danger" style={{ marginTop: 5 }}>
                    Please select supporter!
                </div>
            );
            setErrors(_errors);
            return false;
        }
        _errors.title = (
            <div className="alert alert-success" style={{ marginTop: 10 }}>
                Please wait...
            </div>
        );
        setErrors(_errors);
        return true;
    }

    function handleSubmit(event) {
        event.preventDefault();
        if (formIsValid() && supporterIsValid() && isSessionTimeValid()) {
            actions.save(support);
        }
    }

    function handleDelete(event) {
        event.preventDefault();
        if (!window.confirm('Are you sure to delete this record?')) return;

        const _errors = {};
        _errors.loading = (
            <div className="alert alert-warning" style={{ marginTop: 10 }}>
                Please wait...
            </div>
        );

        setErrors(_errors);
        actions.deleteSupport(support);
    }

    return (
        <>
            <div className="SimpleQuery" style={{ width: '90%' }}>
                <section />

                <section className="Content">
                    <div className="table_TableWrapper__1_LpY">
                        <div className="table_Caption__RCL85" />
                        <SupportForm
                            errors={errors}
                            support={support}
                            timeZone={timezone}
                            users={users || []}
                            delete={handleDelete}
                            onChange={handleChange}
                            onSubmit={handleSubmit}
                        />
                    </div>
                </section>
            </div>
        </>
    );
}

export function getsupportBySlug(supports, slug) {
    return supports.items.find(support => support.id === slug) || null;
}

function mapStateToProps(state, ownProps) {
    const slug = ownProps.match.params.slug;

    if (state.supports.items == null) {
        // ownProps.history.push('/supports');
        return {};
    }

    const support = getsupportBySlug(state.supports, slug);

    return {
        support,
        users: state.users.items,
        supports: state.supports
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            save: bindActionCreators(saveSupportListAction, dispatch),
            deleteSupport: bindActionCreators(deleteSupportAction, dispatch)
        }
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SupportDetails);
