import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Router } from 'react-router-dom';
import Routes from '../routes/Routes';
import history from '../../history';
import { fetchUserDetailsAction } from '../../controllers/auth';
import Loading from '../loading/Loading';

const App = props => {
    const { loading, userRoles, fetchUserDetailsAction } = props;

    useEffect(() => {
        if (!userRoles) {
            fetchUserDetailsAction();
        }
    }, []);

    return (
        <Loading loading={loading}>
            <Router history={history}>
                <Routes />
            </Router>
        </Loading>
    );
};

export default connect(
    ({ user: { roles, loading } }) => ({
        userRoles: roles,
        loading: !!loading
    }),
    {
        fetchUserDetailsAction
    }
)(App);
