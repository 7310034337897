import React from 'react';
import classnames from 'classnames';
import LastModified from './LastModified';

const Title = ({ className, title, lastModified }) => (
    <div className={classnames('title-wrapper', className)}>
        <span className="title">{title}</span>
        {lastModified && (
            <LastModified
                lastModifiedAt={lastModified.at}
                lastModifiedBy={lastModified.by}
            />
        )}
    </div>
);

export default Title;
