import React, { useRef, useEffect } from 'react';
import classnames from 'classnames';
import MenuIcon from '../../components/image/MenuIcon';

import styles from './actionmenu.module.scss';

const renderListItems = items => {
    return items.map(({ title, icon, onClick }) => (
        <li className={styles.ListItem} onClick={onClick} key={title}>
            {icon && <div className={styles.ListItemIcon}>{icon}</div>}
            <div className={styles.ListItemTitle}>{title}</div>
        </li>
    ));
};

const ActionMenu = ({ items, title, className }) => {
    const headerRef = useRef();
    const listRef = useRef();
    //we use React.useState in order to be able to perform our tests
    const [isOpen, setIsOpen] = React.useState(false);

    const handleClick = event => {
        if (headerRef.current.contains(event.target)) {
            setIsOpen(!isOpen);
        } else if (!listRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    useEffect(
        () => {
            document.addEventListener('click', handleClick, true);

            return () =>
                document.removeEventListener('click', handleClick, true);
        },
        [isOpen]
    );

    return (
        <div
            className={classnames([styles.ActionMenuWrapper, className])}
            title="Actions"
        >
            <div className={styles.Header} ref={headerRef}>
                <MenuIcon className={styles.HeaderIcon} />
                {title}
            </div>
            <ul className={styles.List} ref={listRef}>
                {isOpen && renderListItems(items)}
            </ul>
        </div>
    );
};

export default ActionMenu;
