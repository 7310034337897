export default () => {
    let timer = null;

    return (callback, timeout, value) => {
        if (timer) {
            clearTimeout(timer);
        }

        timer = setTimeout(() => callback(value), timeout);
    };
};
