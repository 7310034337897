export const FETCH_HOTFIX_LIST = 'hotfixes.fetchHotFixList';
export const REFRESH_HOTFIX_LIST = 'hotfixes.refreshHotFixList';
export const HOTFIX_LIST_IS_UPDATING = 'hotfixes.hotFixListUpdating';
export const CREATE_HOTFIX = 'hotfixes.createHotFix';
export const CLEAR_CREATE_HOTFIX = 'hotfixes.clearCreateHotFixStatus';
export const APPLY_HOTFIX = 'hotfixes.applyHotFix';
export const UNDO_HOTFIX = 'hotfixes.undoHotFix';
export const CLEAR_CHANGE_HOTFIX_STATUS = 'hotfixes.clearChangeHotFixStatus';
export const DELETE_HOTFIX = 'hotfixes.deleteHotFix';
export const RETIRE_HOTFIX = 'hotfixes.retireHotFix';
export const HOTFIX_STATUS = 'hotfix.displayStatus';
export const FETCH_TYPES = 'hotfix.fetchTypes';
export const ADD_HOTFIX_TO_STORE = 'hotfixes.addHotfixToStore';
export const REMOVE_HOTFIX_FROM_STORE = 'hotfixes.removeHotFixFromStore';
export const CHANGE_HOTFIX_STATUS = 'hotfixes.changeHotfixStatus';
export const FETCH_INSTRUMENTS_BY_HOTFIX = 'hotfixes.fetchInstrumentsByHotFix';
export const CHANGE_HOTFIX_STATUS_FOR_INSTRUMENT =
    'hotfixes.changeHotFixStatusForInstrument';
export const EXPORT_HOTFIXS_MATRIX = 'hotfixes.exportMatrix';
export const PROMOTE_HOTFIX = 'hotfixes.promoteHotFix';
