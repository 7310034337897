import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './button.module.scss';
import { Link } from 'react-router-dom';

const hasString = look => str => look.indexOf(str) > -1;

const Button = ({
    className,
    children,
    to,
    look,
    type,
    width,
    disabled,
    readOnly,
    selected,
    onClick,
    ...other
}) => {
    const hasLook = hasString(look);
    const classes = classnames(className, styles.Default, {
        [styles.Primary]: hasLook('Primary'),
        [styles.Secondary]: hasLook('Secondary'),
        [styles.Neutral]: hasLook('Neutral'),
        [styles.Warning]: hasLook('Warning'),
        [styles.Transparent]: hasLook('Transparent'),
        [styles.FullWidth]: width === 'FullWidth',
        [styles.Disabled]: disabled,
        [styles.ReadOnly]: readOnly,
        [styles.Selected]: selected,
        [styles.Clear]: look === 'Clear'
    });

    const setElement = to =>
        to ? (
            <Link to={encodeURI(to)} className={classes}>
                {children}
            </Link>
        ) : (
            <button
                className={classes}
                type={type}
                onClick={disabled ? () => false : onClick}
                {...other}
            >
                {children}
            </button>
        );

    return setElement(to);
};

Button.propTypes = {
    href: PropTypes.string,
    look: PropTypes.string,
    type: PropTypes.string,
    width: PropTypes.string,
    disabled: PropTypes.bool,
    onClick: PropTypes.func
};

Button.defaultProps = {
    look: 'Default',
    type: 'button',
    width: '',
    disabled: false
};

export default Button;
