export const START_PERIOD = 'startPeriod';
export const END_PERIOD = 'endPeriod';
export const RESET = 'reset';
export const REPLACE = 'claendar.replaceValues';
export const FORMAT = 'D/M/YYYY';
export const INVALID_DATE = 'Invalid Date';
export const UNIT_OF_TIME = 'day';

export const AFTER = 'GTE'; // Greater than or equal
export const BEFORE = 'LTE'; // Less than or equal
export const BETWEEN = 'BETWEEN';
