import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Form } from 'react-final-form';
import FormContent from './FormContent';
import {
    createModKitAction,
    clearCreateModKitAction
} from '../../controllers/modkit';

const CreateModKit = ({
    clearCreateModKitAction,
    createModKitAction,
    platformTypes
}) => {
    useEffect(() => clearCreateModKitAction, []);

    const handleSubmit = async values => createModKitAction(values);

    const renderFormContent = ({ handleSubmit, pristine, invalid, form }) => (
        <FormContent
            handleSubmit={handleSubmit}
            pristine={pristine}
            invalid={invalid}
            form={form}
        />
    );

    return (
        <Form
            onSubmit={handleSubmit}
            subscription={{
                invalid: true,
                pristine: true
            }}
            initialValues={{
                documentNumber: '',
                platformType: platformTypes.items[0],
                name: ''
            }}
        >
            {renderFormContent}
        </Form>
    );
};

export default connect(
    ({ platformTypes }) => ({ platformTypes }),
    { createModKitAction, clearCreateModKitAction }
)(CreateModKit);
