import {
    FETCH_SUPPORT_LIST,
    SAVE_SUPPORT_LIST,
    DELETE_SUPPORT,
    GET_USERS
} from './constants';

export const fetchSupportListAction = () => ({
    type: FETCH_SUPPORT_LIST
});

export const deleteSupportAction = suppport => ({
    type: DELETE_SUPPORT,
    payload: suppport
});

export const saveSupportListAction = support => ({
    type: SAVE_SUPPORT_LIST,
    payload: support
});

export const getUsersAction = () => ({
    type: GET_USERS
});
