import ROLE_ACCESS from './roleAccess.json';

const withDefault = role =>
    ROLE_ACCESS[role] ? ROLE_ACCESS[role] : ROLE_ACCESS[ROLE_ACCESS.default];

const getMenuItemsForRole = role => withDefault(role).menu;

export const getMenuItems = (...roles) => {
    const arrayOfRolesMenuItems = roles.map(getMenuItemsForRole);

    arrayOfRolesMenuItems.reverse();

    const flatMenuItems = arrayOfRolesMenuItems.reduce(
        (acc, current) => acc.concat(current),
        []
    );

    const uniqueMenuItems = [];
    flatMenuItems.forEach(menuItem => {
        if (!uniqueMenuItems.find(item => menuItem.to === item.to)) {
            uniqueMenuItems.push(menuItem);
        }
    });

    uniqueMenuItems.sort(function(a, b) {
        return a.order - b.order;
    });

    return uniqueMenuItems;
};

export const getProfileRoleName = role => withDefault(role).name;

export const getStartPage = role => {
    if (
        ROLE_ACCESS[role] &&
        Array.isArray(ROLE_ACCESS[role].menu) &&
        ROLE_ACCESS[role].menu.length
    ) {
        return ROLE_ACCESS[role].menu[0].to;
    }

    return null;
};

export const hasAccessToRoute = (roles, route) => {
    if (!Array.isArray(roles)) return false;

    if (ROLE_ACCESS === undefined || ROLE_ACCESS === null) {
        return false;
    }

    for (let i = 0; i < roles.length; i++) {
        const role = roles[i];

        if (ROLE_ACCESS[role] === undefined || ROLE_ACCESS[role] === null) {
            continue;
        }
        try {
            if (route.match(new RegExp(ROLE_ACCESS[role].valid))) {
                return true;
            }
        } catch {
            return false;
        }
    }

    return false;
};

export const getActionsThatUserCanExecute = (actions, roles) => {
    if (!Array.isArray(actions)) {
        return [];
    }

    return actions.reduce((accumulator, action) => {
        if (doesUserHavePermission(roles, action.permission)) {
            return [...accumulator, action];
        }

        return accumulator;
    }, []);
};

export const doesUserHavePermission = (roles, permission) => {
    if (
        !Array.isArray(roles) ||
        roles.length === 0 ||
        typeof permission !== 'string'
    ) {
        return false;
    }

    for (let i = 0; i < roles.length; i++) {
        const roleRules = ROLE_ACCESS[roles[i]];

        if (
            roleRules instanceof Object &&
            Array.isArray(roleRules.permissions) &&
            roleRules.permissions.includes(permission)
        ) {
            return true;
        }
    }

    return false;
};
