import React from 'react';
import Selector from '../../selector/Selector';
import Radio from '../../radio/Radio';
import CalendarInput from '../../calendar/CalendarInput';
import { PropTypes } from 'prop-types';

import './uservalue.scss';

const UserValue = ({
    count,
    property,
    selectedValues,
    onUserValueChanged,
    operator
}) => {
    const userValues = [];
    if (typeof count === 'number' && count > 0)
        for (let inputIndex = 0; inputIndex < count; inputIndex++) {
            switch (property.type) {
                case 'BOOLEAN':
                    userValues.push(
                        <div key={inputIndex} className="UserInput">
                            <Radio
                                name={property.name}
                                legend="Boolean"
                                options={[
                                    { name: 'True', value: true },
                                    { name: 'False', value: false }
                                ]}
                                onChange={onUserValueChanged(inputIndex)}
                                selectedValue={selectedValues[0]}
                            />
                        </div>
                    );
                    break;
                case 'DATE':
                    userValues.push(
                        <div key={inputIndex} className="UserInput">
                            <label>Value</label>
                            <CalendarInput
                                operator={operator.name}
                                onValueChange={value =>
                                    onUserValueChanged(inputIndex)({
                                        target: { value: value }
                                    })
                                }
                            />
                        </div>
                    );
                    break;
                default:
                    if (Array.isArray(property.values)) {
                        const options =
                            property.values[0] instanceof Object
                                ? property.values.map(v => ({
                                      name: v.name,
                                      value: v.id
                                  }))
                                : property.values.map(v => ({
                                      name: v,
                                      value: v
                                  }));

                        userValues.push(
                            <div key={inputIndex} className="UserInput">
                                {' '}
                                <Selector
                                    label="Value"
                                    className="Value"
                                    options={[{ name: '-', value: '' }].concat(
                                        options
                                    )}
                                    selectedOption={selectedValues[inputIndex]}
                                    onSelectionChange={onUserValueChanged(
                                        inputIndex
                                    )}
                                />
                            </div>
                        );
                    } else {
                        userValues.push(
                            <div key={inputIndex} className="UserInput">
                                <label>Value</label>
                                <input
                                    type="text"
                                    onChange={onUserValueChanged(inputIndex)}
                                    value={selectedValues[inputIndex] || ''}
                                />
                            </div>
                        );
                    }
                    break;
            }
        }
    return userValues;
};

UserValue.propTypes = {
    count: PropTypes.number,
    property: PropTypes.object,
    selectedValues: PropTypes.array,
    onUserValueChanged: PropTypes.func,
    operator: PropTypes.object
};

export default UserValue;
