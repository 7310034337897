import {
    LOAD_INSTRUMENT_VERSIONS,
    CLEAR_INSTRUMENT_VERSIONS
} from '../../instrument/constants';
import { FETCH_SUCCESS, FETCH_ERROR } from '../../fetch/constants';
import { selectedInstrumentVersionsInitialState } from '../../../store/initialState';

export const instrumentVersionsReducer = (state, { type, payload, meta }) => {
    if (meta && meta.namespace === LOAD_INSTRUMENT_VERSIONS) {
        if (type === FETCH_SUCCESS) {
            return {
                loading: false,
                items: [...payload.revisions, payload.currentRevision]
            };
        }

        if (type === FETCH_ERROR) {
            return {
                ...selectedInstrumentVersionsInitialState,
                loading: false
            };
        }
    }

    if (type === CLEAR_INSTRUMENT_VERSIONS) {
        return selectedInstrumentVersionsInitialState;
    }

    return state;
};
