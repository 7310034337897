import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './tabs.scss';

const Tab = props => {
    const { label, activeTab } = props;

    const onClick = () => {
        props.onClick(label);
    };

    const classes = classnames('TabListItem', {
        Active: activeTab.toLowerCase() === label.toLowerCase()
    });

    return (
        <li className={classes} onClick={onClick}>
            {props.label}
        </li>
    );
};

Tab.propTypes = {
    activeTab: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired
};

export default Tab;
