import { FETCH_ERROR, FETCH_SUCCESS, fetchToStatusClass } from '../../fetch';
import {
    REFRESH_INSTRUMENT_UPDATE_STATUS,
    CHANGE_TRIGGERED_UPDATE_STATUS_FOR_INSTRUMENTS,
    REMOVE_UPDATE_FROM_STORE
} from '../constants';

const STATUS_MESSAGES = {
    [FETCH_SUCCESS]: 'The operation was successful',
    [FETCH_ERROR]: 'There was a problem executing the operation'
};

const changeInstrumentUpdateStatus = (
    instruments,
    instrumentId,
    notificationStatus,
    actionStatus
) =>
    instruments.map(instrument => {
        if (instrument.instrumentId !== instrumentId) {
            return instrument;
        } else {
            const newData =
                actionStatus === FETCH_ERROR
                    ? {
                          statusMessage: STATUS_MESSAGES[actionStatus],
                          statusClass: fetchToStatusClass(actionStatus),
                          loading: false
                      }
                    : {
                          notificationStatus,
                          statusMessage: undefined,
                          statusClass: undefined,
                          loading: false
                      };
            return { ...instrument, ...newData };
        }
    });

const startChangeInstrumentUpdateStatus = (instruments, instrumentId) => {
    return instruments.map(instrument => {
        return instrument.instrumentId !== instrumentId
            ? instrument
            : { ...instrument, loading: true };
    });
};

export const changeTriggeredUpdateStatusReducer = (
    state,
    { type, ...payload }
) => {
    switch (type) {
        case CHANGE_TRIGGERED_UPDATE_STATUS_FOR_INSTRUMENTS: {
            return {
                ...state,
                items: startChangeInstrumentUpdateStatus(
                    state.items,
                    payload.instrumentId
                )
            };
        }
        case REFRESH_INSTRUMENT_UPDATE_STATUS: {
            return {
                ...state,
                items: changeInstrumentUpdateStatus(
                    state.items,
                    payload.instrumentId,
                    payload.notificationStatus,
                    payload.actionStatus
                )
            };
        }
        case REMOVE_UPDATE_FROM_STORE: {
            const { updateId } = payload;
            if (
                state.items[0] &&
                state.items[0].notificationStatus.id === updateId
            ) {
                return {};
            } else {
                return state;
            }
        }
        default:
            return state;
    }
};
