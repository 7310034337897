import { FETCH_SUCCESS } from '../../fetch';
import { INSTRUMENT_HOTFIX_STATUS } from '../../../common/utils/hotfixdetails';
import { FETCH_INSTRUMENTS_BY_HOTFIX } from '../constants';

const setHotFixData = (instrument, id) => {
    const hotfix = instrument.metadata.hotfixes.find(m => m.id === id);

    return {
        modified: hotfix ? hotfix.modified : null,
        status: hotfix
            ? hotfix.status
            : INSTRUMENT_HOTFIX_STATUS.NOT_APPLIED.toUpperCase()
    };
};

const setStatus = (instruments, id) => {
    if (!instruments) return [];
    return instruments.map(instrument => ({
        ...instrument,
        ...setHotFixData(instrument, id)
    }));
};

export const formatInstrumentsByHotfixResponse = (
    state,
    { type, meta, passToResponse }
) => {
    if (
        meta &&
        meta.namespace === FETCH_INSTRUMENTS_BY_HOTFIX &&
        type === FETCH_SUCCESS
    ) {
        return {
            ...state,
            instruments: setStatus(state.instruments, passToResponse.id)
        };
    } else {
        return state;
    }
};
