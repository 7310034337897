const exportToFile = (data, fileName, fileExtension, mimeType) => {
    const type = `${mimeType};charset=utf-8;`;
    const fileBlob = new Blob([data], { type: type });
    const validFileName = `${replaceInvalidCharactersInFileName(
        fileName
    )}.${fileExtension}`;

    createSaveAsDialog(fileBlob, validFileName);
};

export const exportToHtml = (html, fileName) =>
    exportToFile(html, fileName, 'html', 'text/html');

// The uFEFF flag is used for properly visualising special characters like Ä/ä, Ö/ö, Ü/ü in German
export const exportToCsv = (dataArray, fileName, uFEFF = true) => {
    const separator = ';';

    let csvData = 'sep=' + separator + '\r\n';

    dataArray.forEach(row => {
        csvData += row.join(separator);
        csvData += '\r\n';
    });

    exportToFile(
        (uFEFF ? '\uFEFF' : '') + csvData,
        fileName,
        'csv',
        getBrowserSpecificCsvMimeType()
    );
};

export const exportToExcel = (data, fileName) =>
    exportToFile(data, fileName, 'xlsx', 'application/vnd.ms-excel');

export const exportToXmlFromBlob = (fileBlob, fileName) => {
    createSaveAsDialog(fileBlob, fileName);
};

const createSaveAsDialog = (fileBlob, fileName) => {
    if (
        window.navigator &&
        window.navigator.msSaveOrOpenBlob &&
        ieVersion() < 12
    ) {
        window.navigator.msSaveOrOpenBlob(fileBlob, fileName);
    } else {
        const link = document.createElement('a');

        link.setAttribute('download', fileName);
        link.setAttribute('href', URL.createObjectURL(fileBlob));

        link.click();
    }
};

const isSafari = () =>
    /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
const ieVersion = () => {
    const match = /\b(MSIE |Trident.*?rv:|Edge\/)(\d+)/.exec(
        navigator.userAgent
    );
    if (!match) {
        return null;
    }
    return parseInt(match[2]);
};

const getBrowserSpecificCsvMimeType = () =>
    isSafari() ? 'application/csv' : 'text/csv';

const replaceInvalidCharactersInFileName = filename =>
    filename.replace(/[/\\?%*:|"<>]/g, '');
