import React from 'react';
import { convertDate } from '../../common/utils/details';
import { promoteHotFixAction } from '../../controllers/hotfix/actions';
import { connect } from 'react-redux';
import styles from './hotfix.module.scss';

const HotFixInfo = props => {
    const {
        modified: { at, firstName, lastName, username },
        documentNumber,
        name,
        platformType,
        status,
        promoteHotFixAction,
        id
    } = props;

    return (
        <div className="InformationBlock">
            <button
                className={styles.PromoteHotFix}
                onClick={e => {
                    if (
                        !window.confirm(
                            'Are you sure to promote this HotFix to a ModKit?'
                        )
                    )
                        return;
                    return promoteHotFixAction(id);
                }}
                look="Primary"
            >
                Promote to ModKit
            </button>

            <div className="Row">
                <span className="Label">Document Number:</span>
                <span className="Value">{documentNumber}</span>
            </div>
            <div className="Row">
                <span className="Label">Description:</span>
                <span className="Value">{name}</span>
            </div>
            <div className="Row">
                <span className="Label">Platform Type:</span>
                <span className="Value">{platformType}</span>
            </div>
            <div className="Row">
                <span className="Label">Status:</span>
                <span className="Value">{status}</span>
            </div>
            <div className="Row">
                <span className="Label">Modified By:</span>
                <span className="Value">
                    {firstName} {lastName} {username}
                </span>
            </div>
            <div className="Row">
                <span className="Label">Modified At:</span>
                <span className="Value">{convertDate(at)}</span>
            </div>
        </div>
    );
};

export default connect(
    null,
    {
        promoteHotFixAction
    }
)(HotFixInfo);
