import React from 'react';

import Modules from '../../shared/Modules';
import { convertDate, getValue, hasElements } from '../../../../common/utils';
import { customSort } from '../../../../common/utils/table';
import { SORT_ORDER } from '../../../../common/hooks/useSort';

const getFormattedModuleData = moduleData => ({
    name: getValue(moduleData.name),
    version: getValue(moduleData.version),
    packageName: getValue(moduleData.packageName),
    installationPath: getValue(moduleData.installationPath),
    installationDate: convertDate(moduleData.installationDate)
});

const getSoftwareProperties = (softwarePackage, modified) => ({
    name: getValue(softwarePackage.name),
    version: getValue(softwarePackage.version),
    packageName: getValue(softwarePackage.packageName),
    installationPath: getValue(softwarePackage.installationPath),
    installationDate: convertDate(softwarePackage.installationDate),
    lastUpdated: modified ? convertDate(modified.at) : ''
});

export default props => {
    const {
        packages,
        modified,
        parentPage,
        moduleIdentifier,
        subModuleIdentifier
    } = props;

    let modulesData = packages.map(p => ({
        ...p,
        properties: getSoftwareProperties(p, modified)
    }));

    modulesData = customSort(modulesData, 'name', SORT_ORDER.ASCENDING);

    const content = [
        <div label="Software Packages" key="software_packages">
            <Modules
                modules={modulesData}
                selectedModuleId={moduleIdentifier}
                linkTo={parentPage}
                header="Software List"
                sortProps={false}
                render={p => p.name}
            />
        </div>
    ];

    const selectedPackage = modulesData[moduleIdentifier];

    if (selectedPackage && hasElements(selectedPackage.modules)) {
        const modules = selectedPackage.modules.map(m => ({
            ...m,
            properties: getFormattedModuleData(m)
        }));

        const categoryTitle = `Modules for ${selectedPackage.name}`;

        content.push(
            <div label="Modules" key="modules">
                <Modules
                    modules={modules}
                    selectedModuleId={subModuleIdentifier}
                    linkTo={`${parentPage}/${moduleIdentifier}`}
                    header={categoryTitle}
                    sortProps={false}
                    render={p => p.name}
                />
            </div>
        );
    }

    return content;
};
