import { put, takeEvery, all, take, call, takeMaybe } from 'redux-saga/effects';

import { ADD_LOGBOOK_ENTRY } from './constants';
import { openSnackbarAction } from '../snackbar/actions';
import { fetchDataAction, FETCH_SUCCESS } from '../fetch';

let messageId = 1;

function* addLogbookEntry({ formData, instrumentId }) {
    yield put(
        fetchDataAction(ADD_LOGBOOK_ENTRY)(
            `/api/v1/instruments/${instrumentId}/logbook`,
            {
                method: 'post',
                data: formData
            },
            { instrumentId: instrumentId }
        )
    );
    yield call(refreshInstrumentDetails);
}

function* refreshInstrumentDetails() {
    const addLogbookEntryAction = yield takeMaybe(
        action =>
            action.meta &&
            action.meta.namespace === ADD_LOGBOOK_ENTRY &&
            action.type === FETCH_SUCCESS
    );

    if (addLogbookEntryAction) {
        yield put(
            openSnackbarAction({
                messageId: messageId++,
                message: `Logbook entry was created successfully`,
                mode: 'success'
            })
        );
    }
}

function* watchAddLogbookEntry() {
    yield takeEvery(ADD_LOGBOOK_ENTRY, addLogbookEntry);
}

export function* logbookSagas() {
    yield all([watchAddLogbookEntry()]);
}
