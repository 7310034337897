import React, { Component } from 'react';
import Selector from '../../selector/Selector';
import Button from '../../button/Button';
import classnames from 'classnames';
import './criteria.scss';
import { PropTypes } from 'prop-types';
import StatusMessage from '../../statusMessage/StatusMessage';
import ModuleCriteria from './ModuleCriteria';
import {
    CRITERIA,
    isMetadataCriteria
} from '../../../common/utils/CriteriaData';
import MetadataCriteria from './MetadataCriteria';
import BaseCriteria from './BaseCriteria';
import {
    getPropertyOptions,
    mapPropsSorted
} from '../../../common/utils/query';

class Criteria extends Component {
    onCriteriaSelected = ({ target: { value: selectedCriteria } }) => {
        const selectionSerializableData = {
            selected: {
                criteria: selectedCriteria
            }
        };

        if (isMetadataCriteria(selectedCriteria)) {
            const metadataCriteria = this.props.meta.metadata.find(
                m => m.name === selectedCriteria
            );

            selectionSerializableData.operators = this.props.meta.operators[
                metadataCriteria.type
            ];
            selectionSerializableData.selected.property = {
                ...metadataCriteria,
                name: selectedCriteria
            };
        }

        this.props.serialize(selectionSerializableData);
    };

    onOperatorSelected = ({ target: { value: selectedOperatorName } }) => {
        const selectedOperator = this.props.findOperatorByName(
            selectedOperatorName
        );

        const {
            selected: { criteria, property, values, module },
            operators
        } = this.props.data;

        this.props.serialize({
            selected: {
                criteria,
                property,
                operator: selectedOperator,
                values,
                module
            },
            operators
        });
    };

    onUserValueChanged = index => ({ target: { value: newValue } }) => {
        const {
            selected: { criteria, property, operator, values, module },
            operators
        } = this.props.data;

        const newCriteriaData = {
            selected: {
                criteria,
                property,
                module,
                operator,
                values: Array.isArray(values) ? Array.from(values) : []
            },
            operators
        };

        newCriteriaData.selected.values[index] = newValue;

        this.props.serialize(newCriteriaData);
    };

    getOperatorOptions = () => {
        const operators = this.props.data.operators || [];

        return (
            operators.length > 0 &&
            [{ name: '-', value: '-' }].concat(
                operators.map(o => ({
                    name: o.displayName,
                    value: o.name
                }))
            )
        );
    };

    getCriteriaOptions = () => {
        const hardwareCriterias = [
            {
                name: 'Hardware Details',
                value: CRITERIA.HARDWARE
            },
            {
                name: 'Hardware Module',
                value: CRITERIA.HARDWARE_MODULE
            }
        ];
        hardwareCriterias.label = 'Hardware';

        const softwareCriterias = [
            {
                name: 'Software Details',
                value: CRITERIA.SOFTWARE
            }
        ];
        softwareCriterias.label = 'Software';

        const metadataCriterias = this.props.meta.metadata
            ? this.props.meta.metadata.map(m => ({
                  name: m.name,
                  value: m.value
              }))
            : [];

        metadataCriterias.push({
            name: 'ModKit',
            value: CRITERIA.MODKIT
        });

        metadataCriterias.push({
            name: 'HotFix',
            value: CRITERIA.HOTFIX
        });

        metadataCriterias.label = 'Metadata';

        return [hardwareCriterias, softwareCriterias, metadataCriterias];
    };

    getModkitProps = () => {
        return [{ name: '-', value: null }].concat(
            mapPropsSorted(this.props.meta.modKits.properties)
        );
    };

    getHotfixesProps = () => {
        return [{ name: '-', value: null }].concat(
            mapPropsSorted(this.props.meta.hotfixes.properties)
        );
    };

    renderCriteria = criteriaType => {
        const onChangeHandlers = {
            operatorOptions: this.getOperatorOptions(),
            onOperatorSelected: this.onOperatorSelected,
            onUserValueChanged: this.onUserValueChanged
        };

        const {
            data: { selected },
            serialize,
            meta,
            findProperty
        } = this.props;

        switch (criteriaType) {
            case CRITERIA.HARDWARE:
                return (
                    <BaseCriteria
                        {...onChangeHandlers}
                        propertyLabel="Property"
                        selected={selected}
                        operators={meta.operators}
                        serialize={serialize}
                        getPropertyOptions={getPropertyOptions(meta.hardware)}
                        findProperty={findProperty(meta.hardware)}
                    />
                );
            case CRITERIA.HARDWARE_MODULE:
                return (
                    <ModuleCriteria
                        {...onChangeHandlers}
                        modules={meta.hardwareModules}
                        selected={selected}
                        operators={meta.operators}
                        serialize={serialize}
                        findProperty={findProperty}
                    />
                );
            case CRITERIA.MODKIT:
                return (
                    <BaseCriteria
                        {...onChangeHandlers}
                        propertyLabel="Status"
                        selected={selected}
                        operators={meta.operators}
                        serialize={serialize}
                        getPropertyOptions={this.getModkitProps()}
                        findProperty={findProperty(meta.modKits)}
                    />
                );

            case CRITERIA.HOTFIX:
                return (
                    <BaseCriteria
                        {...onChangeHandlers}
                        propertyLabel="Status"
                        selected={selected}
                        operators={meta.operators}
                        serialize={serialize}
                        getPropertyOptions={this.getHotfixesProps()}
                        findProperty={findProperty(meta.hotfixes)}
                    />
                );
            case CRITERIA.SOFTWARE:
                return (
                    <BaseCriteria
                        {...onChangeHandlers}
                        propertyLabel="Property"
                        selected={selected}
                        operators={meta.operators}
                        serialize={serialize}
                        getPropertyOptions={getPropertyOptions(meta.software)}
                        findProperty={findProperty(meta.software)}
                    />
                );
            default:
                return (
                    <MetadataCriteria
                        selected={selected}
                        {...onChangeHandlers}
                    />
                );
        }
    };

    shouldComponentUpdate = newProps => {
        return (
            this.props.meta !== newProps.meta ||
            this.props.data.selected.criteria !==
                newProps.data.selected.criteria ||
            this.props.data.selected.module !== newProps.data.selected.module ||
            this.props.data.selected.property !==
                newProps.data.selected.property ||
            this.props.data.selected.operator !==
                newProps.data.selected.operator ||
            this.props.data.selected.values !== newProps.data.selected.values
        );
    };

    render() {
        const {
            data: { selected }
        } = this.props;
        const criteriaClasses = classnames('Criteria', {
            Hardware: selected.criteria === CRITERIA.HARDWARE,
            Module: selected.criteria === CRITERIA.HARDWARE_MODULE,
            Software: selected.criteria === CRITERIA.SOFTWARE,
            Metadata:
                isMetadataCriteria(selected.criteria) ||
                selected.criteria === CRITERIA.MODKIT ||
                selected.criteria === CRITERIA.HOTFIX
        });

        return (
            <fieldset className={criteriaClasses}>
                <Button className="Close" onClick={this.props.removeCriteria}>
                    &#10005;
                </Button>
                <ol>
                    <li>
                        <Selector
                            label="Criteria"
                            className="Criteria"
                            options={this.getCriteriaOptions()}
                            selectedOption={selected.criteria}
                            onSelectionChange={this.onCriteriaSelected}
                        />
                    </li>
                    {this.renderCriteria(selected.criteria)}
                </ol>
                <StatusMessage statusClass="error" message={this.props.error} />
            </fieldset>
        );
    }
}

Criteria.propTypes = {
    removeCriteria: PropTypes.func.isRequired,
    error: PropTypes.string,
    meta: PropTypes.shape({
        hardware: PropTypes.object,
        hardwareModules: PropTypes.array,
        software: PropTypes.object,
        metadata: PropTypes.array,
        operators: PropTypes.object,
        modKits: PropTypes.object
    }).isRequired,
    serialize: PropTypes.func.isRequired,
    data: PropTypes.shape({
        selected: PropTypes.shape({
            criteria: PropTypes.string
        }),
        operators: PropTypes.array
    }).isRequired
};

export default Criteria;
