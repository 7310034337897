import React from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import Downshift from 'downshift';

import Loading from '../../loading/Loading';
import { selectInstrumentAction } from '../../../controllers/query';
import styles from './versionSelector.module.scss';
import { convertDate, sortVersionsDescending } from '../../../common/utils';
import ExpandIcon from '../../image/ExpandIcon';

const VersionSelector = props => {
    const {
        instrumentId,
        versions,
        loading,
        defaultVersion,
        selectInstrumentAction
    } = props;

    const getItemsList = supplyItems => {
        return (
            <table className={styles.ItemsList}>
                <colgroup>
                    <col className={styles.VersionInfo} />
                    <col />
                </colgroup>
                <tbody>{supplyItems()}</tbody>
            </table>
        );
    };

    const getItem = (item, index, defaultVersion, getItemProps) => {
        let selectionStyle =
            defaultVersion.number === item.number && styles.Selected;

        return (
            <tr
                className={classnames([styles.Item, selectionStyle])}
                key={item.number}
                {...getItemProps({ item, index, key: item.number })}
            >
                <td>{formatRevisionNumber(item)}</td>
                <td>{convertDate(item.modificationTime)}</td>
            </tr>
        );
    };

    const getLabel = (selectedItem, defaultVersion) => {
        let item = selectedItem ? selectedItem : defaultVersion;

        return (
            <div className={styles.Label}>
                <span
                    className={classnames([
                        styles.VersionInfo,
                        styles.ItemInfo
                    ])}
                >
                    {formatRevisionNumber(item)}
                </span>
                <span className={styles.ItemInfo}>
                    {convertDate(item.modificationTime)}
                </span>
            </div>
        );
    };

    const formatRevisionNumber = item => `v ${item.number}:`;

    return (
        <Downshift
            onChange={selectedItem =>
                selectInstrumentAction(instrumentId, selectedItem.number)
            }
            itemToString={item => (item && item.number ? item.number : '')}
        >
            {({ getItemProps, isOpen, toggleMenu, selectedItem }) => (
                <div className={styles.VersionSelectorWrapper}>
                    <div
                        className={styles.VersionSelector}
                        onClick={toggleMenu}
                        title="Select a version"
                    >
                        {getLabel(selectedItem, defaultVersion)}

                        <div className={styles.Action}>
                            <ExpandIcon
                                className={classnames([
                                    styles.DropdownArrow,
                                    isOpen && styles.DropdownArrowOpen
                                ])}
                            />
                        </div>
                    </div>
                    {isOpen ? (
                        <div className={styles.ItemsContainer}>
                            <Loading loading={loading} mode="text">
                                {getItemsList(() =>
                                    sortVersionsDescending(versions).map(
                                        (item, index) =>
                                            getItem(
                                                item,
                                                index,
                                                defaultVersion,
                                                getItemProps
                                            )
                                    )
                                )}
                            </Loading>
                        </div>
                    ) : null}
                </div>
            )}
        </Downshift>
    );
};

VersionSelector.propTypes = {
    instrumentId: PropTypes.string.isRequired,
    defaultVersion: PropTypes.object.isRequired,
    versions: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired
};

export default connect(
    ({ selectedInstrumentVersions }) => ({
        versions: selectedInstrumentVersions.items,
        loading: selectedInstrumentVersions.loading
    }),
    {
        selectInstrumentAction
    }
)(VersionSelector);
