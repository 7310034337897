import { MODKIT_STATUS, MODKIT_LIST_IS_UPDATING } from '../constants';
const STATUSES = {
    retireModKit: {
        notification: 'Modkit has been retired successfully!',
        error: 'Modkit could not be retired, please try again!'
    },
    deleteModKit: {
        notification: 'Modkit has been deleted successfully!',
        error: 'Modkit could not be deleted, please try again!'
    }
};
export const modkitStatusReducer = (
    state = {},
    { type, namespace, id, status, message }
) => {
    switch (type) {
        case MODKIT_STATUS:
            const messages = STATUSES[namespace];
            if (messages) {
                return {
                    ...state,
                    [id]: {
                        message: message || messages[status],
                        error: status === 'error',
                        statusClass: status
                    }
                };
            }

            return state;
        case MODKIT_LIST_IS_UPDATING:
            return {};
        default:
            return state;
    }
};
