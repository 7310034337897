import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Form } from 'react-final-form';
import FormContent from './FormContent';
import {
    createHotFixAction,
    clearCreateHotFixAction
} from '../../controllers/hotfix';

const CreateHotFix = ({
    clearCreateHotFixAction,
    createHotFixAction,
    platformTypes
}) => {
    useEffect(() => clearCreateHotFixAction, []);

    const handleSubmit = async values => createHotFixAction(values);

    const renderFormContent = ({ handleSubmit, pristine, invalid, form }) => (
        <FormContent
            handleSubmit={handleSubmit}
            pristine={pristine}
            invalid={invalid}
            form={form}
        />
    );

    return (
        <Form
            onSubmit={handleSubmit}
            subscription={{
                invalid: true,
                pristine: true
            }}
            initialValues={{
                documentNumber: '',
                platformType: platformTypes.items[0],
                name: ''
            }}
        >
            {renderFormContent}
        </Form>
    );
};

export default connect(
    ({ platformTypes }) => ({ platformTypes }),
    { createHotFixAction, clearCreateHotFixAction }
)(CreateHotFix);
