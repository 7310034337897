import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Table from '../table/Table';
import { InfoIcon } from '../image';

import { COLUMN_TYPE, LAYOUT } from '../table/constants';
import { UPDATE_STATUS } from '../../common/utils/updates';

const renderExtraInfo = row => {
    const { message, errorDetails, errorCode } = row.notificationStatus;

    const history = !row.notificationStatus.history ? null : (
        <div className="history">
            <h4>Status History:</h4>
            {row.notificationStatus.history
                .sort(
                    (a, b) => new Date(b.modified.at) - new Date(a.modified.at)
                )
                .map(element => (
                    <div key={element.modified.at}>
                        {element.status};{element.modified.at};
                        {element.modified.username};{element.errorDetails}
                    </div>
                ))}
        </div>
    );

    return (
        <div className="SideInformationDetails">
            <InfoIcon className="InfoIcon" />
            <span>{message || 'N/A'}</span>
            {errorDetails && (
                <p className="error">
                    {errorCode}: {errorDetails}
                </p>
            )}
            {history}
        </div>
    );
};

const InstrumentsByUpdateList = props => {
    const { instruments, onResendUpdate, onCancelUpdate } = props;

    const columns = [
        {
            type: COLUMN_TYPE.TEXT,
            displayName: 'Type',
            key: 'type',
            hasSorting: true,
            hasFiltering: true,
            width: 180
        },
        {
            type: COLUMN_TYPE.TEXT,
            displayName: 'Size',
            key: 'size',
            hasSorting: true,
            hasFiltering: true,
            width: 120
        },
        {
            type: COLUMN_TYPE.TEXT,
            displayName: 'Serial Number',
            key: 'serialNumber',
            hasSorting: true,
            hasFiltering: true
        },
        {
            type: COLUMN_TYPE.TEXT,
            displayName: 'Modified by',
            key: 'notificationStatus.modified.username',
            hasSorting: true,
            hasFiltering: true,
            width: 220
        },
        {
            type: COLUMN_TYPE.DATE,
            displayName: 'Modified at',
            key: 'notificationStatus.updateTime',
            hasSorting: true,
            hasFiltering: true,
            width: 220
        },
        {
            type: COLUMN_TYPE.TEXT,
            displayName: 'Status',
            key: 'notificationStatus.status',
            hasSorting: true,
            hasFiltering: true,
            width: 160,
            render: status => (
                <span className={classnames(['Status', status.toUpperCase()])}>
                    {status}
                </span>
            )
        },
        {
            type: COLUMN_TYPE.CONFIRMATION,
            displayName: 'Action',
            key: 'instrumentId',
            getButtonLook: data =>
                UPDATE_STATUS[data.notificationStatus.status] ===
                UPDATE_STATUS.NEW
                    ? 'Secondary'
                    : 'Primary',
            getText: data =>
                UPDATE_STATUS[data.notificationStatus.status] ===
                UPDATE_STATUS.NEW
                    ? 'Cancel'
                    : 'Resend',
            width: 90,
            confirmation: {
                key: 'notificationStatus.status',
                onAction: data => {
                    if (
                        UPDATE_STATUS[data.notificationStatus.status] ===
                        UPDATE_STATUS.NEW
                    ) {
                        return onCancelUpdate(data.instrumentId);
                    } else {
                        return onResendUpdate(data.instrumentId);
                    }
                },
                getMessage: data =>
                    UPDATE_STATUS[data.notificationStatus.status] ===
                    UPDATE_STATUS.NEW
                        ? 'Do you really want to cancel update for this instrument?'
                        : 'Do you really want to resend the update for this instrument?'
            }
        }
    ];

    return (
        <div className="ResultContainerWrapper">
            <div className="ResultContainer">
                <Table
                    id="instrumentId"
                    layout={LAYOUT.AUTO}
                    data={instruments}
                    columns={columns}
                    initialSortColumn="instrumentId"
                    renderExtraInfo={renderExtraInfo}
                />
            </div>
        </div>
    );
};

InstrumentsByUpdateList.propTypes = {
    onResendUpdate: PropTypes.func.isRequired,
    onCancelUpdate: PropTypes.func.isRequired,
    instruments: PropTypes.array
};

export default InstrumentsByUpdateList;
