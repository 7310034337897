import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';

import {
    deleteModKitAction,
    fetchModKitListAction,
    retireModKitAction,
    exportModkitsMatrixAction
} from '../../controllers/modkit/actions';
import { getOpenModalWindowAction } from '../../controllers/modal';
import { FETCH_ERROR } from '../../controllers/fetch';
import CheckErrors from '../statusMessage/CheckErrors';
import PageWithLeftSidebar from '../../container/PageWithLeftSidebar';
import FilteredList from '../../container/FilteredList';
import { DeleteIcon, RetireIcon, AddIcon, SaveIcon } from '../image';
import Button from '../button/Button';
import { MODKIT_STATUS, formatModKitStatus } from '../../common/utils/details';
import DiscardModKitForm from './DiscardModKitForm';
import CreateModKit from './CreateModKit';
import Autocomplete from '../../components/autocomplete/Autocomplete';
import ActionMenu from '../../components/actionMenu/ActionMenu';
import { fetchTypesAction } from '../../controllers/modkit/actions';

import styles from './modkit.module.scss';

const getSelectedModkitActionObject = (modkits, selectedId, actions) => {
    const selectedItem =
        modkits.find(modkit => modkit.id === selectedId) || null;
    const status = selectedItem ? selectedItem.status : '';

    let actionIcon;
    let actionText;
    let modalWindowOptions = {};

    switch (status.toLowerCase()) {
        case MODKIT_STATUS.NEW.toLowerCase():
            actionIcon = DeleteIcon;
            actionText = 'Delete';
            modalWindowOptions = {
                confirmAction: actions.deleteModKitAction,
                title: 'Delete ModKit',
                operation: 'delete'
            };
            break;
        case MODKIT_STATUS.USED.toLowerCase():
            actionIcon = RetireIcon;
            actionText = 'Retire';
            modalWindowOptions = {
                confirmAction: actions.retireModKitAction,
                title: 'Retire ModKit',
                operation: 'retire'
            };
            break;
        default:
            actionIcon = null;
            actionText = null;
            modalWindowOptions = null;
            break;
    }

    let onItemAction = null;
    if (modalWindowOptions) {
        onItemAction = modkit => {
            actions.getOpenModalWindowAction(
                DiscardModKitForm,
                modalWindowOptions.title,
                {
                    name: modkit.documentNumber,
                    id: modkit.id,
                    confirmAction: modalWindowOptions.confirmAction,
                    operation: modalWindowOptions.operation
                }
            );
        };
    }

    return {
        actionIcon,
        actionText,
        onItemAction
    };
};

const ModKitsWithData = props => {
    const {
        children,
        error,
        errorMessage,
        loading,
        modkitList,
        selectedId,
        platformTypes,
        deleteModKitAction,
        fetchModKitListAction,
        retireModKitAction,
        getOpenModalWindowAction,
        fetchTypesAction,
        loadingPlatformTypes,
        exportModkitsMatrixAction
    } = props;

    const [platformType, setPlatformType] = useState();
    const [modkits, setModkits] = useState([]);
    const [filteredModkits, setFilteredModkits] = useState([]);
    const [disableMenuActions, setDisableMenuActions] = useState(true);

    useEffect(() => {
        fetchModKitListAction();
    }, []);

    useEffect(() => {
        fetchTypesAction();
    }, []);

    useEffect(
        () => {
            const modifiedModKits = modkitList.map(m => ({
                ...m,
                description: m.name,
                name: `${m.documentNumber} ${
                    m.status === 'RETIRED'
                        ? `(${formatModKitStatus(m.status)})`
                        : ''
                }`,
                className:
                    MODKIT_STATUS[m.status] === MODKIT_STATUS.RETIRED
                        ? 'FilteredListItemInactive'
                        : null
            }));
            setModkits(modifiedModKits);
        },
        [modkitList]
    );

    useEffect(
        () => {
            setDisableMenuActions(!platformType);

            if (platformType) {
                setFilteredModkits(
                    modkits.filter(m => m.platformType === platformType)
                );
            } else {
                setFilteredModkits(modkits);
            }
        },
        [modkits, platformType]
    );

    const menuActionItems = [
        {
            title: 'Export ModKits Matrix',
            icon: <SaveIcon />,
            isDisabled: false,
            onClick: () =>
                exportModkitsMatrixAction({ platformType, modkits: modkitList })
        }
    ];

    const renderAdditionalFilters = () => (
        <div className="AdditionalFilterContainer">
            <div className="AdditionalFilterLabel">PLATFORM</div>
            <div className="AdditionalFilterInput">
                <Autocomplete
                    controlledValue={platformType}
                    onValueChange={setPlatformType}
                    placeholder="Select..."
                    items={platformTypes
                        .sort()
                        .map(value => ({ value, id: value }))}
                    showAllSuggestionsOnFocus={true}
                    loading={loading}
                />
            </div>
        </div>
    );

    const renderActionMenu = () =>
        disableMenuActions ? null : (
            <ActionMenu
                items={menuActionItems}
                className={classnames([styles.PlatformActions])}
            />
        );

    const {
        actionIcon,
        actionText,
        onItemAction
    } = getSelectedModkitActionObject(filteredModkits, selectedId, {
        deleteModKitAction,
        retireModKitAction,
        getOpenModalWindowAction
    });

    return (
        <CheckErrors error={error} errorMessage={errorMessage}>
            <PageWithLeftSidebar
                loading={loading}
                renderSidebarContent={() => (
                    <>
                        <FilteredList
                            label="modkits"
                            items={filteredModkits}
                            selectedId={selectedId}
                            linkTo="/modkits"
                            actionIcon={actionIcon}
                            actionText={actionText}
                            onItemAction={onItemAction}
                            loading={!!loadingPlatformTypes}
                            renderAdditionalFilters={renderAdditionalFilters}
                            renderActionMenu={renderActionMenu}
                        />
                        <Button
                            look="Transparent"
                            onClick={() =>
                                getOpenModalWindowAction(
                                    CreateModKit,
                                    'Create ModKit'
                                )
                            }
                            className={styles.CreateModKitButton}
                        >
                            <AddIcon className={styles.AddIcon} />
                        </Button>
                    </>
                )}
                renderMainContent={() => children}
            />
        </CheckErrors>
    );
};

export default connect(
    ({ modkits, platformTypes }) => ({
        modkitList: modkits.items,
        loading: !!modkits.loading,
        error: modkits.fetchStatus === FETCH_ERROR,
        errorMessage: modkits.message,
        platformTypes: platformTypes.items,
        loadingPlatformTypes: platformTypes.loading
    }),
    {
        fetchModKitListAction,
        deleteModKitAction,
        retireModKitAction,
        getOpenModalWindowAction,
        fetchTypesAction,
        exportModkitsMatrixAction
    }
)(ModKitsWithData);
