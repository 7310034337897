export const ERROR_CANCELED = 'REQUEST_CANCELED';
export const ERROR_UNAUTHORIZED = 'UNAUTHORIZED';
export const ERROR_FORBIDDEN = 'FORBIDDEN';

const HTTP_STATUSES = {
    '401': ERROR_UNAUTHORIZED,
    '403': ERROR_FORBIDDEN
};

class ErrorRedirect extends Error {
    constructor(message, payload) {
        super(message);
        this.response = payload;
    }
}

ErrorRedirect.factory = (statusCode, payload) => {
    const status = HTTP_STATUSES[String(statusCode)];
    if (status) {
        return new ErrorRedirect(status, payload);
    } else {
        return null;
    }
};

export { ErrorRedirect };
