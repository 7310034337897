import React from 'react';
import PropTypes from 'prop-types';

import ProfileMenu from './ProfileMenu';
import ProfileIcon from '../../components/image/ProfileIcon';
import './profile.scss';
import { useAuth0 } from '@auth0/auth0-react';
import {
    getRedirectUri,
    getSamlLogoutRedirectUri,
    isSamlUser
} from '../../common/utils/auth-helper';

const Profile = ({ username, userRoles }) => {
    const { logout, user } = useAuth0();

    const onLogout = () => {
        sessionStorage.clear();
        const returnTo = isSamlUser(user.email)
            ? getSamlLogoutRedirectUri()
            : getRedirectUri();
        logout({ returnTo });
    };

    return (
        <div className="Profile">
            <div className="ProfileContainer">
                <ProfileIcon className="ProfileIcon" />
                <ProfileMenu
                    className="ProfileMenu"
                    username={username}
                    userRoles={userRoles}
                    initiateLogoutAction={onLogout}
                />
            </div>
        </div>
    );
};

Profile.propTypes = {
    username: PropTypes.string.isRequired,
    userRoles: PropTypes.array.isRequired
};

export default Profile;
