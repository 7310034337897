import { FETCH_SUCCESS } from '../../fetch/constants';
import { alphabeticSort } from '../../../common/utils/details';

export const orderHotFixes = hotFixes => {
    const retiredHotfixes = hotFixes.filter(
        hotfix => hotfix.status === 'RETIRED'
    );

    const otherHotfixes = hotFixes.filter(
        hotfix => hotfix.status !== 'RETIRED'
    );

    retiredHotfixes.sort(alphabeticSort);
    otherHotfixes.sort(alphabeticSort);

    return [...otherHotfixes, ...retiredHotfixes];
};

export const hotfixOrderingReducer = (state, { type, payload, meta }) => {
    if (meta && meta.namespace === 'hotfixes' && type === FETCH_SUCCESS) {
        return { ...state, items: orderHotFixes(payload.items) };
    } else {
        return state;
    }
};
