import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import classnames from 'classnames';

import PropertyList from '../../shared/PropertyList';
import CopyToClipboardIcon from '../../../image/CopyToClipboardIcon';
import { Table, COLUMN_TYPE } from '../../../table';
import { SORT_ORDER } from '../../../../common/hooks/useSort';
import { getUpdateNameFromPath, hasElements } from '../../../../common/utils';

export default props => {
    const { notifications, styles } = props;

    const notificationsData = notifications
        ? notifications.map(n => ({ ...n, notificationId: n.notification.id }))
        : [];

    const columns = [
        {
            type: COLUMN_TYPE.DATE,
            displayName: 'Last Updated',
            key: 'notificationStatus.updateTime',
            hasSorting: true,
            hasFiltering: true,
            width: 240
        },
        {
            type: COLUMN_TYPE.TEXT,
            key: 'notification.payload.PATH',
            displayName: 'Update',
            hasSorting: true,
            hasFiltering: true,
            width: '60%',
            render: filePath => getUpdateNameFromPath(filePath)
        },
        {
            type: COLUMN_TYPE.TEXT,
            key: 'notification.payload.URL',
            displayName: 'Get link',
            hasSorting: false,
            hasFiltering: false,
            width: 70,
            className: styles.CENTER,
            render: downloadLink => (
                <div onClick={event => event.stopPropagation()}>
                    <CopyToClipboard
                        className={styles.Icon}
                        text={downloadLink}
                    >
                        <span>
                            <CopyToClipboardIcon title="copy link to clipboard" />
                        </span>
                    </CopyToClipboard>
                </div>
            )
        },
        {
            type: COLUMN_TYPE.TEXT,
            key: 'notificationStatus.status',
            displayName: 'Status',
            hasSorting: true,
            width: '40%',
            render: status => renderNotificationStatusColumn(status)
        }
    ];

    const renderNotificationStatusColumn = status => {
        let currentStatusStyle = status.toUpperCase();

        if (styles[currentStatusStyle]) {
            currentStatusStyle = styles[currentStatusStyle];
        }

        return (
            <span className={classnames([styles.Status, currentStatusStyle])}>
                {status}
            </span>
        );
    };

    const renderNotificationsExtraInfo = ({
        message,
        errorDetails,
        errorCode,
        history
    }) => {
        let extraInfo = {};
        if (message) {
            extraInfo['Info'] = message;
        }

        if (errorCode) {
            extraInfo['Error code'] = errorCode;
        }

        if (errorDetails) {
            extraInfo['Error details'] = errorDetails;
        }

        if (hasElements(history)) {
            extraInfo['Status History'] = history
                .sort(
                    (a, b) => new Date(b.modified.at) - new Date(a.modified.at)
                )
                .map(historyUpdate => (
                    <span
                        key={historyUpdate.modified.at}
                        className={styles.History}
                    >
                        {historyUpdate.status};{historyUpdate.modified.at};
                        {historyUpdate.modified.username};
                        {historyUpdate.errorDetails}
                    </span>
                ));
        }

        let hasExtraInfo = Object.entries(extraInfo).length !== 0;

        return (
            hasExtraInfo && (
                <div className={styles.ExtraInfo}>
                    <PropertyList data={extraInfo} sorting={false} />
                </div>
            )
        );
    };

    return (
        <Table
            id="notificationId"
            initialSortColumn="notificationStatus.updateTime"
            initialSortOrder={SORT_ORDER.DESCENDING}
            data={notificationsData}
            columns={columns}
            renderExtraInfo={row =>
                renderNotificationsExtraInfo(row.notificationStatus)
            }
        />
    );
};
