import { OPEN_MODAL, CLOSE_MODAL } from './constants';

export const getOpenModalWindowAction = (component, title, props) => ({
    type: OPEN_MODAL,
    component,
    title,
    props
});

export const getCloseModalWindowAction = () => ({
    type: CLOSE_MODAL
});
