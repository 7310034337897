import React, { useEffect, useState } from 'react';
import { Form, Field } from 'react-final-form';
import { connect } from 'react-redux';
import Button from '../../button/Button';
import Autocomplete from '../../autocomplete/Autocomplete';
import StatusMessage from '../../statusMessage/StatusMessage';

import './addtagform.scss';

import {
    fetchTagListAction,
    createTagAction,
    clearCreateTagAction,
    assignTagToInstruments,
    clearAssignTagAction
} from '../../../controllers/tags';
import Loading from '../../loading/Loading';

const fieldSelectTag = 'selectTagInput';
const MAX_TAG_LENGTH = 128;
const LENGTH_BEFORE_REACHING_LIMIT = 10;

let AddTagForm = props => {
    const {
        tagList,
        selectedInstruments,
        createTagMessage,
        createTagStatusClass,
        clearCreateTagAction,
        clearAssignTagAction,
        assignTagMessage,
        assignTagStatusClass,
        fetchTagListAction,
        createTagAction,
        createTagActionDisabled,
        assignTagToInstruments,
        lastCreatedTagId,
        assignTagIsLoading
    } = props;

    const [controlledValue, setControlledValue] = useState('');

    useEffect(() => {
        fetchTagListAction();
        clearStatusAction();
    }, []);

    const onHandleSubmit = async values => {
        const tagText = getTextFromValue(values[fieldSelectTag]);

        const tag = tagList.filter(t => t.name.trim() === tagText)[0];
        assignTagToInstruments(tag.id, selectedInstruments);
        setControlledValue('');
    };

    const onAddTag = value => {
        createTagAction(value.trim());
        setControlledValue('');
        clearStatusAction();
    };

    const onValueChange = value => {
        setControlledValue(value.slice(0, MAX_TAG_LENGTH));
        value.length > 0 && clearStatusAction();
    };

    const clearStatusAction = () => {
        clearCreateTagAction();
        clearAssignTagAction();
    };

    const getTextFromValue = value => {
        const text = value.hasOwnProperty('value') ? value.value : value;
        return text.trim();
    };

    const isInvalid = value => {
        if (!value) return true;

        return tagList.filter(t => getTextFromValue(value) === t.name.trim())
            .length === 0
            ? true
            : undefined;
    };

    const generateLengthLimitMessage = () => {
        return MAX_TAG_LENGTH - controlledValue.length === 0
            ? `You have reached the maximum length limit of ${MAX_TAG_LENGTH} symbols.`
            : `You will reach the maximum length limit of ${MAX_TAG_LENGTH} symbols after ${MAX_TAG_LENGTH -
                  controlledValue.length} symbols.`;
    };

    let items = []
        .concat(tagList.filter(t => t.id === lastCreatedTagId))
        .concat(tagList.filter(t => t.id !== lastCreatedTagId))
        .map(t => ({ id: t.id, value: t.name }));

    return (
        <Form
            onSubmit={onHandleSubmit}
            subscription={{ touched: true, error: true, invalid: true }}
        >
            {({ handleSubmit, pristine, invalid, form }) => (
                <form className="AddTagForm">
                    <div className="FormContent">
                        <div className="AutoCompleteContainer">
                            <Field
                                id={fieldSelectTag}
                                name={fieldSelectTag}
                                actionTitle="Create Tag"
                                placeholder="Select a tag"
                                component={Autocomplete}
                                items={items}
                                onAction={onAddTag}
                                showAllSuggestionsOnFocus={true}
                                validate={isInvalid}
                                actionDisabled={createTagActionDisabled}
                                controlledValue={controlledValue}
                                onValueChange={onValueChange}
                            />
                        </div>
                        {controlledValue.length >
                        MAX_TAG_LENGTH - LENGTH_BEFORE_REACHING_LIMIT ? (
                            <StatusMessage
                                statusClass="warning"
                                message={generateLengthLimitMessage()}
                            />
                        ) : null}
                        <StatusMessage
                            statusClass={createTagStatusClass}
                            message={createTagMessage}
                        />
                        <StatusMessage
                            statusClass={assignTagStatusClass}
                            message={assignTagMessage}
                        />
                        <Loading loading={assignTagIsLoading} mode="text" />
                    </div>
                    <Button
                        id="buttonAssign"
                        look="Primary"
                        onClick={handleSubmit}
                        width="FullWidth"
                        disabled={pristine || invalid}
                    >
                        Assign
                    </Button>
                </form>
            )}
        </Form>
    );
};

export default connect(
    ({
        tags,
        selectedInstruments,
        createTag,
        assignTagToInstruments: { message, statusClass, loading }
    }) => ({
        tagList: tags.items || [],
        selectedInstruments,
        createTagStatusClass: createTag.statusClass,
        createTagMessage: createTag.message,
        createTagActionDisabled: createTag.loading,
        lastCreatedTagId: createTag.id,
        assignTagMessage: message,
        assignTagStatusClass: statusClass,
        assignTagIsLoading: !!loading
    }),
    {
        fetchTagListAction,
        createTagAction,
        clearCreateTagAction,
        assignTagToInstruments,
        clearAssignTagAction
    }
)(AddTagForm);
