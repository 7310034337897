import {
    put,
    takeEvery,
    all,
    call,
    take,
    takeLatest,
    takeMaybe
} from 'redux-saga/effects';

import {
    CREATE_TAG,
    FETCH_TAGS_LIST,
    UNASSIGN_TAG_FROM_INSTRUMENTS,
    DELETE_TAG,
    FETCH_INSTRUMENTS_BY_TAG,
    ASSIGN_TAG_TO_INSTRUMENTS
} from './constants';
import { buildQuery } from '../../common/utils/graphQL';
import history from '../../history';
import { fetchDataAction, onSuccessful } from '../fetch';
import { openSnackbarAction } from '../snackbar/actions';
import { toggleMultipleInstrumentsSelectionAction } from '../instrument/actions';

let messageId = 1;

function* fetchTagList() {
    yield put(
        fetchDataAction('tags')('/api/v1/tags', {
            method: 'get',
            params: {}
        })
    );
}

function* fetchInstrumentsByTag({ tagId }) {
    const param = {
        field: 'tags.id',
        type: 'ID',
        operator: 'EQ',
        resource: 'METADATA',
        values: [tagId]
    };
    const queryBody = buildQuery(
        [param],
        'payload{modules{moduleCategory, moduleId}} metadata{maintenance{required, endDate} armMaintenance{moduleId, endDate} currentRevision{modificationTime}}'
    );

    yield put(
        fetchDataAction(FETCH_INSTRUMENTS_BY_TAG)(
            '/api/v1/query/search',
            {
                method: 'post',
                headers: { 'Content-Type': 'application/json' },
                data: queryBody
            },
            { tagId }
        )
    );
}

function* createTag({ tagText }) {
    yield put(
        fetchDataAction(CREATE_TAG)('/api/v1/tags', {
            method: 'post',
            data: { name: tagText }
        })
    );
    yield call(refreshTagListOnSuccessfulCreate);
}

function* deleteTag({ tagId }) {
    yield put(
        fetchDataAction(DELETE_TAG)(`/api/v1/tags/${tagId}`, {
            method: 'delete'
        })
    );
    yield call(navigateOnSuccessfullDelete);
}

function* assignTagToInstruments({ tagId, instrumentIds }) {
    yield put(
        fetchDataAction(ASSIGN_TAG_TO_INSTRUMENTS)('/api/v1/instruments/tags', {
            method: 'post',
            data: { assignId: tagId, instrumentIds: instrumentIds }
        })
    );
}

function* unassignTagFromInstruments({ tagId, instrumentIds }) {
    yield put(
        fetchDataAction(UNASSIGN_TAG_FROM_INSTRUMENTS)(
            '/api/v1/instruments/tags',
            {
                method: 'delete',
                data: { assignId: tagId, instrumentIds: instrumentIds }
            }
        )
    );

    yield call(refreshInstrumentsByTagAfterUnassign, {
        tagId,
        numberOfUnassignedInstruments: instrumentIds.length
    });
}

function* refreshTagListOnSuccessfulCreate() {
    const action = yield takeMaybe(onSuccessful(CREATE_TAG));

    if (action) {
        yield call(fetchTagList);
    }
}

function* navigateOnSuccessfullDelete() {
    const action = yield takeMaybe(onSuccessful(DELETE_TAG));

    if (action) {
        yield call(fetchTagList);
        yield history.push('/tags');
        yield put(
            openSnackbarAction({
                messageId: messageId++,
                message: 'Tag was deleted successfully.',
                mode: 'success'
            })
        );
    }
}

function* refreshInstrumentsByTagAfterUnassign({
    tagId,
    numberOfUnassignedInstruments
}) {
    const message = `${
        numberOfUnassignedInstruments !== 1
            ? 'Instruments were'
            : 'Instrument was'
    } unassigned successfully.`;
    const action = yield takeMaybe(onSuccessful(UNASSIGN_TAG_FROM_INSTRUMENTS));

    if (action) {
        yield call(fetchInstrumentsByTag, { tagId: tagId });
        yield put(toggleMultipleInstrumentsSelectionAction([]));
        yield put(
            openSnackbarAction({
                messageId: messageId++,
                message,
                mode: 'success'
            })
        );
    }
}

function* watchFetchingTags() {
    yield takeEvery(FETCH_TAGS_LIST, fetchTagList);
}

function* watchCreateTag() {
    yield takeEvery(CREATE_TAG, createTag);
}

function* watchDeleteTag() {
    yield takeEvery(DELETE_TAG, deleteTag);
}

function* watchAssignTag() {
    yield takeEvery(ASSIGN_TAG_TO_INSTRUMENTS, assignTagToInstruments);
}

function* watchFetchingInstrumentsByTag() {
    yield takeLatest(FETCH_INSTRUMENTS_BY_TAG, fetchInstrumentsByTag);
}

function* watchUnassignTag() {
    yield takeEvery(UNASSIGN_TAG_FROM_INSTRUMENTS, unassignTagFromInstruments);
}

export function* tagsSagas() {
    yield all([
        watchFetchingTags(),
        watchCreateTag(),
        watchDeleteTag(),
        watchAssignTag(),
        watchUnassignTag(),
        watchFetchingInstrumentsByTag()
    ]);
}
