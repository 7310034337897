import axios, { CancelToken } from 'axios';
import { ErrorRedirect, ERROR_CANCELED } from './ErrorRedirect';

if (process.env.NODE_ENV === 'development') {
    // Make sure that mocks are included in the main bundle only in the dev build.
    const { mockBackend } = require('./backend.mock');
    mockBackend(axios);
}

const handleError = error => {
    if (axios.isCancel(error)) {
        throw new Error(ERROR_CANCELED);
    }
    if (error.response) {
        const errorException = ErrorRedirect.factory(
            error.response.status,
            error.response
        );
        if (errorException) {
            throw errorException;
        }
    }
    if (error.response && error.response.data) {
        throw error.response.data;
    }
    throw error;
};

const handleResponse = res => res.data;

export const fetch = (url, params = {}) => {
    const cancelToken =
        params && params.abort ? new CancelToken(params.abort) : null;
    const headersFromParams = params && params.headers;
    const headers = Object.assign({}, headersFromParams || {});

    const requestParams = {
        ...params,
        cancelToken,
        url:
            url.indexOf('http') !== 0 ? `${process.env.PUBLIC_URL}${url}` : url,
        headers,
        withCredentials: true
    };

    return axios(requestParams)
        .catch(handleError)
        .then(handleResponse);
};
