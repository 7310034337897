import { put, takeEvery, all, take, takeMaybe } from 'redux-saga/effects';

import {
    FETCH_SUCCESS,
    fetchDataAction,
    onError,
    onSuccessful
} from '../fetch';

import {
    FETCH_SUPPORT_LIST,
    SAVE_SUPPORT_LIST,
    DELETE_SUPPORT,
    GET_USERS
} from './constants';
import { navigateTo } from '../../common/utils/routes';
import { openSnackbarAction } from '../snackbar/actions';
let messageId = 1;

function* fetchSupportList() {
    const timezone = '?timeZone=' + getTimezoneOffset();

    yield put(
        fetchDataAction('supports')('/api/v1/remote-support/list' + timezone, {
            method: 'get',
            params: {}
        })
    );
}

function* saveSupportList(support) {
    yield put(
        fetchDataAction(SAVE_SUPPORT_LIST)('/api/v1/remote-support/update', {
            method: 'post',
            data: { supportRequest: support.payload }
        })
    );

    const action = yield takeMaybe([
        onSuccessful(SAVE_SUPPORT_LIST),
        onError(SAVE_SUPPORT_LIST)
    ]);

    if (action && action.type === FETCH_SUCCESS) {
        yield put(
            openSnackbarAction({
                messageId: messageId++,
                message: 'The ticket has been updated successfully',
                mode: 'success'
            })
        );
        navigateTo('/supports');
    }
}

function* deleteSupport(support) {
    yield put(
        fetchDataAction(DELETE_SUPPORT)(
            '/api/v1/remote-support/delete/' + support.payload.id,
            {
                method: 'delete'
            }
        )
    );

    const action = yield takeMaybe([
        onSuccessful(DELETE_SUPPORT),
        onError(DELETE_SUPPORT)
    ]);

    if (action && action.type === FETCH_SUCCESS) {
        yield put(
            openSnackbarAction({
                messageId: messageId++,
                message: 'The ticket has been deleted successfully',
                mode: 'success'
            })
        );
        navigateTo('/supports');
    }
}

function* fetchUsers() {
    yield put(
        fetchDataAction('users')('/api/v1/remote-support/users', {
            method: 'get',
            params: {}
        })
    );
}

function* watchFetchingUsers() {
    yield takeEvery(GET_USERS, fetchUsers);
}

function* watchFetchingSupports() {
    yield takeEvery(FETCH_SUPPORT_LIST, fetchSupportList);
}

function* watchDeleteSupport() {
    yield takeEvery(DELETE_SUPPORT, deleteSupport);
}

function* watchSavingSupports() {
    yield takeEvery(SAVE_SUPPORT_LIST, saveSupportList);
}

export function* supportSagas() {
    yield all([
        watchFetchingSupports(),
        watchSavingSupports(),
        watchDeleteSupport(),
        watchFetchingUsers()
    ]);
}

function getTimezoneOffset() {
    function z(n) {
        return (n < 10 ? '0' : '') + n;
    }
    var offset = new Date().getTimezoneOffset();
    var sign = offset < 0 ? '%2B' : '-';
    offset = Math.abs(offset);
    return sign + z((offset / 60) | 0) + ':' + z(offset % 60);
}
