import {
    put,
    takeEvery,
    takeLatest,
    all,
    select,
    take,
    call,
    throttle,
    delay,
    takeMaybe
} from 'redux-saga/effects';

import {
    fetchDataAction,
    FETCH_SUCCESS,
    FETCH_ERROR,
    fetchToStatusClass
} from '../fetch';
import {
    ADD_HOTFIX_TO_STORE,
    APPLY_HOTFIX,
    CHANGE_HOTFIX_STATUS,
    CHANGE_HOTFIX_STATUS_FOR_INSTRUMENT,
    CREATE_HOTFIX,
    DELETE_HOTFIX,
    FETCH_INSTRUMENTS_BY_HOTFIX,
    PROMOTE_HOTFIX,
    FETCH_HOTFIX_LIST,
    FETCH_TYPES,
    REFRESH_HOTFIX_LIST,
    REMOVE_HOTFIX_FROM_STORE,
    RETIRE_HOTFIX,
    UNDO_HOTFIX,
    EXPORT_HOTFIXS_MATRIX
} from './constants';
import {
    hotfixListIsUpdatingAction,
    displayHotFixStatusAction,
    refreshHotFixListAction
} from './actions';
import { onError, onSuccessful } from '../fetch/actions';
import history from '../../history';
import { MESSAGE_DISPLAY_TIMEOUT } from '../../common/constants';
import { openSnackbarAction } from '../snackbar/actions';
import { INSTRUMENT_HOTFIX_STATUS } from '../../common/utils/hotfixdetails';
import { getCloseModalWindowAction } from '../modal';
import { defaultStatusMessage } from './reducers/createHotfix';
import { selectInstrumentAction } from '../query';

import { buildQuery } from '../../common/utils/graphQL';
import { exportToCsv } from '../../common/utils/exportToFile';
import { buildInstrumentsHotfixesMatrix } from './utils';

let messageId = 1;
const hotfixListSelector = state => state.hotfixes;
const CHANGE_HOTFIX_STATUS_NAMESPACE = 'changeHotFix';

export function* fetchHotFixList() {
    yield put(hotfixListIsUpdatingAction());
    yield put(
        fetchDataAction('hotfixes')('/api/v1/hotfixes', {
            method: 'get',
            params: {}
        })
    );
}

function* createHotFix({ formData }) {
    yield put(
        fetchDataAction('createHotFix')('/api/v1/hotfixes', {
            method: 'post',
            data: formData
        })
    );

    const action = yield takeMaybe([
        onSuccessful('createHotFix'),
        onError('createHotFix')
    ]);

    if (action instanceof Object && action.payload instanceof Object) {
        const message = defaultStatusMessage(action.payload, action.type);

        if (action.type === FETCH_SUCCESS) {
            yield put(
                openSnackbarAction({
                    messageId: messageId++,
                    message,
                    mode: 'success'
                })
            );
            yield put({
                type: ADD_HOTFIX_TO_STORE,
                hotfix: action.payload
            });
            yield put(getCloseModalWindowAction());
        } else {
            yield put(
                openSnackbarAction({
                    messageId: messageId++,
                    message,
                    mode: 'error'
                })
            );
        }
    }
}

function* deleteHotFix({ id }) {
    yield put(
        fetchDataAction(DELETE_HOTFIX)(`/api/v1/hotfixes/${id}`, {
            method: 'delete',
            params: {
                id: id
            }
        })
    );

    const action = yield takeMaybe([
        onSuccessful(DELETE_HOTFIX),
        onError(DELETE_HOTFIX)
    ]);

    if (action) {
        if (action.type === FETCH_SUCCESS) {
            yield history.push('/hotfixes');
            yield put({
                type: REMOVE_HOTFIX_FROM_STORE,
                id: id
            });
            yield put(
                openSnackbarAction({
                    messageId: messageId++,
                    message: `HotFix ${id} was deleted successfully.`,
                    mode: 'success'
                })
            );
        } else {
            yield put(
                openSnackbarAction({
                    messageId: messageId++,
                    message: action.payload.message,
                    mode: 'error'
                })
            );
        }
    }
}

function* retireHotFix({ id }) {
    yield put(
        fetchDataAction(RETIRE_HOTFIX)(`/api/v1/hotfixes/${id}/retire`, {
            method: 'post',
            params: {}
        })
    );

    const action = yield takeMaybe([
        onSuccessful(RETIRE_HOTFIX),
        onError(RETIRE_HOTFIX)
    ]);

    if (action) {
        if (action.type === FETCH_SUCCESS) {
            yield put({
                type: CHANGE_HOTFIX_STATUS,
                id: id,
                payload: action.payload
            });

            yield put(
                openSnackbarAction({
                    messageId: messageId++,
                    message: `HotFix ${id} was retired successfully.`,
                    mode: 'success'
                })
            );
        } else {
            yield put(
                openSnackbarAction({
                    messageId: messageId++,
                    message: action.payload.message,
                    mode: 'error'
                })
            );
        }
    }
}

export function* displayHotFixListStatus(namespace, id) {
    const action = yield takeMaybe(
        action =>
            action.meta &&
            action.meta.namespace === namespace &&
            (action.type === FETCH_SUCCESS || action.type === FETCH_ERROR)
    );

    if (action) {
        if (id) {
            yield put(
                displayHotFixStatusAction(
                    id,
                    namespace,
                    action.payload && action.payload.message,
                    fetchToStatusClass(action.type)
                )
            );

            yield delay(MESSAGE_DISPLAY_TIMEOUT);
        }

        yield put(refreshHotFixListAction());
    }
}

export function* fetchTypes() {
    yield put(
        fetchDataAction('platformTypes')('/api/v1/instruments/types', {
            method: 'get',
            params: {}
        })
    );
}

function* refreshHotFixList() {
    const hotfixList = yield select(hotfixListSelector);

    if (hotfixList.items && hotfixList.items.length > 0) {
        yield call(fetchHotFixList);
    }
}

function* fetchInstrumentsByHotFix({ platformType, id }) {
    yield put(
        fetchDataAction(FETCH_INSTRUMENTS_BY_HOTFIX)(
            `/api/v1/query/search`,
            {
                method: 'post',
                headers: { 'Content-Type': 'application/json' },
                data: buildQueryByPlatformType(platformType)
            },
            { id }
        )
    );
}

function* promoteHotFix({ platformType, id }) {
    yield put(
        fetchDataAction(PROMOTE_HOTFIX)(
            `/api/v1/instruments/promote-hotfix/${id}`,
            {
                method: 'get',
                params: {}
            }
        )
    );
    yield delay(2000);
    yield call(refreshHotFixList);
}

function* applyHotFix({ id, comment, instrumentId, reloadInstrument }) {
    yield put(
        fetchDataAction(CHANGE_HOTFIX_STATUS_NAMESPACE)(
            `/api/v1/instruments/${instrumentId}/change-hotfix`,
            {
                method: 'put',
                data: {
                    id,
                    comment,
                    status: INSTRUMENT_HOTFIX_STATUS.APPLIED.toUpperCase()
                }
            }
        )
    );

    if (reloadInstrument) {
        yield call(refreshInstrumentDetails, instrumentId, id, 'apply');
    } else {
        yield call(refreshHotFixStatusForInstrument, instrumentId, id, 'apply');
    }
}

function* undoHotFix({ id, comment, instrumentId, reloadInstrument }) {
    yield put(
        fetchDataAction(CHANGE_HOTFIX_STATUS_NAMESPACE)(
            `/api/v1/instruments/${instrumentId}/change-hotfix`,
            {
                method: 'put',
                data: {
                    id,
                    comment,
                    status: INSTRUMENT_HOTFIX_STATUS.REVOKED.toUpperCase()
                }
            }
        )
    );

    if (reloadInstrument) {
        yield call(refreshInstrumentDetails, instrumentId, id, 'revoke');
    } else {
        yield call(
            refreshHotFixStatusForInstrument,
            instrumentId,
            id,
            'revoke'
        );
    }
}

function* refreshHotFixStatusForInstrument(instrumentId, id, operation) {
    const action = yield takeMaybe([
        onSuccessful(CHANGE_HOTFIX_STATUS_NAMESPACE),
        onError(CHANGE_HOTFIX_STATUS_NAMESPACE)
    ]);

    if (action) {
        yield put({
            type: CHANGE_HOTFIX_STATUS_FOR_INSTRUMENT,
            instrumentId,
            id,
            operation,
            payload: action.payload,
            actionStatus: action.type
        });
    }
}

function* refreshInstrumentDetails(instrumentId, modKitId, operationType) {
    const action = yield takeMaybe([
        onSuccessful(CHANGE_HOTFIX_STATUS_NAMESPACE),
        onError(CHANGE_HOTFIX_STATUS_NAMESPACE)
    ]);

    if (action) {
        if (action.type === FETCH_SUCCESS) {
            const message =
                operationType === 'apply'
                    ? `Hotfix ${modKitId} was successfully applied.`
                    : `Hotfix ${modKitId} was successfully revoked.`;

            yield put(
                openSnackbarAction({
                    messageId: messageId++,
                    message: message,
                    mode: 'success'
                })
            );
        }

        yield put(selectInstrumentAction(instrumentId));
    }
}

function* exportInstrumentsHotfixesMatrixForPlatformType({
    platformType,
    hotfixes
}) {
    yield put(
        fetchDataAction(EXPORT_HOTFIXS_MATRIX)(`/api/v1/query/search`, {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            data: buildQueryByPlatformType(platformType)
        })
    );

    yield call(createInstrumentHotfixesMatrixForCsv, platformType, hotfixes);
}

function* createInstrumentHotfixesMatrixForCsv(platformType, hotfixes) {
    const action = yield takeMaybe([
        onSuccessful(EXPORT_HOTFIXS_MATRIX),
        onError(EXPORT_HOTFIXS_MATRIX)
    ]);

    if (action) {
        if (action.type === FETCH_SUCCESS) {
            const data = buildInstrumentsHotfixesMatrix(
                platformType,
                action.payload.instruments,
                hotfixes
            );

            const fileName = `${platformType}_InstrumentsHotfixes_${Date.now()}`;

            exportToCsv(data, fileName);
        }
    }
}

function* watchRefreshHotFixList() {
    yield throttle(
        MESSAGE_DISPLAY_TIMEOUT * 2,
        REFRESH_HOTFIX_LIST,
        refreshHotFixList
    );
}

function* watchFetchTypes() {
    yield takeEvery(FETCH_TYPES, fetchTypes);
}

function* watchHotFixList() {
    yield takeEvery(FETCH_HOTFIX_LIST, fetchHotFixList);
}

function* watchCreateHotFix() {
    yield takeEvery(CREATE_HOTFIX, createHotFix);
}

function* watchDeleteHotfix() {
    yield takeLatest(DELETE_HOTFIX, deleteHotFix);
}

function* watchRetireHotfix() {
    yield takeLatest(RETIRE_HOTFIX, retireHotFix);
}

function* watchFetchInstrumentsByHotfix() {
    yield takeLatest(FETCH_INSTRUMENTS_BY_HOTFIX, fetchInstrumentsByHotFix);
}

function* watchPromoteHotFixAction() {
    yield takeLatest(PROMOTE_HOTFIX, promoteHotFix);
}

function* watchApplyHotfix() {
    yield takeEvery(APPLY_HOTFIX, applyHotFix);
}

function* watchUndoHotfix() {
    yield takeEvery(UNDO_HOTFIX, undoHotFix);
}

function* watchExportHotfixesMatrix() {
    yield takeLatest(
        EXPORT_HOTFIXS_MATRIX,
        exportInstrumentsHotfixesMatrixForPlatformType
    );
}

const buildQueryByPlatformType = platformType => {
    const queryParams = [
        {
            type: 'STRING',
            field: 'PlatformType',
            operator: 'EQ',
            resource: 'HARDWARE',
            values: [platformType]
        }
    ];

    return buildQuery(
        queryParams,
        'metadata{hotfixes{id status modified{username at}}}',
        true
    );
};

export function* hotfixSagas() {
    yield all([
        watchHotFixList(),
        watchCreateHotFix(),
        watchDeleteHotfix(),
        watchRetireHotfix(),
        watchFetchTypes(),
        watchRefreshHotFixList(),
        watchFetchInstrumentsByHotfix(),
        watchPromoteHotFixAction(),
        watchApplyHotfix(),
        watchUndoHotfix(),
        watchExportHotfixesMatrix()
    ]);
}
