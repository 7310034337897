import moment from 'moment';

import { INVALID_DATE, UNIT_OF_TIME, FORMAT } from './constants';

export const transformStringToDate = (str, format) => {
    const dateObject = moment(str, format, true).toDate();

    if (dateObject.toString() === INVALID_DATE) {
        return null;
    }

    return dateObject;
};

export const isDateInTheAcceptableRange = (startDate, endDate) =>
    !startDate || !endDate || startDate.getTime() <= endDate.getTime();

export const formatDateToDatetimeIsoString = (date, formatToLastHour) => {
    if (!date) return '';

    let dateWithoutTimeZone = date
        .toString()
        .split('GMT')[0]
        .trim();

    if (formatToLastHour) {
        return moment
            .utc(dateWithoutTimeZone, 'ddd MMM DD YYYY HH:mm:ss')
            .endOf(UNIT_OF_TIME)
            .toISOString();
    }

    return moment
        .utc(dateWithoutTimeZone, 'ddd MMM DD YYYY HH:mm:ss')
        .startOf(UNIT_OF_TIME)
        .toISOString();
};

export const getShortDateTimeFromIsoString = dateIsoString => {
    if (!moment.utc(dateIsoString).isValid()) {
        return '';
    }

    return moment.utc(dateIsoString).format(FORMAT);
};

export const getDateTimeFromIsoString = dateIsoString => {
    if (!moment.utc(dateIsoString).isValid()) {
        return null;
    }

    const momentDateTime = moment.utc(dateIsoString);

    return new Date(
        momentDateTime.year(),
        momentDateTime.month(),
        momentDateTime.date(),
        momentDateTime.hours(),
        momentDateTime.minutes(),
        momentDateTime.seconds(),
        momentDateTime.milliseconds()
    );
};
