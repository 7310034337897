import React from 'react';
import { customSort } from '../../../../common/utils/table';
import { SORT_ORDER } from '../../../../common/hooks/useSort';

export default (tags, styles) => (
    <ul className={styles.TagsList}>
        {customSort(tags, 'name', SORT_ORDER.ASCENDING).map(tag => (
            <li className={styles.TagsListItem} key={tag.id}>
                {tag.name}
            </li>
        ))}
    </ul>
);
