import { FETCH_SUCCESS } from '../../fetch/constants';
import { CHANGE_HOTFIX_STATUS_FOR_INSTRUMENT } from '../../hotfix/constants';
import { isApplied, isRevoked, isNotApplied } from '../../hotfix/utils';
import { alphabeticSort } from '../../../common/utils/hotfixdetails';
export const instrumenthotfixesOrderingReducer = (
    state,
    { type, payload, meta }
) => {
    switch (type) {
        case FETCH_SUCCESS:
            if (meta && meta.namespace === 'selectedInstrument') {
                const newState = {
                    ...state
                };

                const { hotfixes } = payload;

                const appliedMotFixes = hotfixes.filter(hotfix =>
                    isApplied(hotfix.status)
                );
                const revokedMotFixes = hotfixes.filter(hotfix =>
                    isRevoked(hotfix.status)
                );
                const notApplied = hotfixes.filter(hotfix =>
                    isNotApplied(hotfix.status)
                );

                notApplied.sort(alphabeticSort);
                revokedMotFixes.sort(alphabeticSort);
                appliedMotFixes.sort(alphabeticSort);

                newState.hotfixes = [
                    ...appliedMotFixes,
                    ...revokedMotFixes,
                    ...notApplied
                ];

                return newState;
            } else {
                return state;
            }
        case CHANGE_HOTFIX_STATUS_FOR_INSTRUMENT: {
            const hotfixes = state.hotfixes
                ? state.hotfixes.filter(m => m.id !== payload.id)
                : [];
            return {
                ...state,
                hotfixes: [...hotfixes, payload]
            };
        }
        default:
            return state;
    }
};
