import React from 'react';
import Button from '../button/Button';
import { getProfileRoleName } from '../../common/utils';
import './profileMenu.scss';

const ProfileMenu = ({ username, userRoles, initiateLogoutAction }) => {
    const getRolesItems = () => {
        return userRoles.map(role => (
            <div className="ListItem" key={role}>
                {getProfileRoleName(role)}
            </div>
        ));
    };

    return (
        <div className="ProfileMenu">
            <div>
                <div className="UserName">{username}</div>
                <div className="List">{getRolesItems()}</div>
            </div>

            <Button look="Primary" onClick={initiateLogoutAction}>
                Log out
            </Button>
        </div>
    );
};

export default ProfileMenu;
