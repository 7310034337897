import { OPEN_SNACKBAR, CLOSE_SNACKBAR } from './constants';

export const snackbarReducer = (
    state = {},
    { type, mode, message, messageId, onHide }
) => {
    switch (type) {
        case OPEN_SNACKBAR:
            return { mode, message, messageId, onHide };
        case CLOSE_SNACKBAR:
            return {};
        default:
            return state;
    }
};
