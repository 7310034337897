import { ADD_LOGBOOK_ENTRY } from './constants';

export const addLogbookEntryAction = (data, instrumentId) => {
    const formData = new FormData();

    const logbook = { ...data };
    delete logbook['attachment'];

    const logbookBlob = new Blob([JSON.stringify(logbook)], {
        type: 'application/json'
    });
    formData.append('logbook', logbookBlob);

    if (data.attachment) {
        formData.append('attachment', data.attachment);
    }

    return {
        type: ADD_LOGBOOK_ENTRY,
        formData,
        instrumentId
    };
};
