import React from 'react';
import { array } from 'prop-types';
import './menu.scss';
import MenuLink from './Link';
import { getMenuItems } from '../../common/utils';

class Menu extends React.Component {
    renderUserMenuItems = () => {
        const menuItems = getMenuItems(...this.props.userRoles);

        return menuItems.map(menuItem => (
            <MenuLink key={menuItem.to} menuData={menuItem} />
        ));
    };

    render() {
        return <nav className="Menu">{this.renderUserMenuItems()}</nav>;
    }
}

Menu.propTypes = {
    userRoles: array.isRequired
};

export default Menu;
