import React from 'react';
import classnames from 'classnames';
import StatusMessage from '../statusMessage/StatusMessage';
import { PropTypes } from 'prop-types';

const ChildrenRendererDefaultComponent = ({ children }) => <>{children}</>;

const ComponentWithLabelAndErrorMessage = ({
    input = {},
    error = '',
    Component = ChildrenRendererDefaultComponent,
    children = null,
    labelClasses,
    className,
    label,
    rest
}) => (
    <div className={classnames('FormField', input.name)}>
        <label htmlFor={input.name} className={labelClasses}>
            {label}
        </label>
        <Component
            id={input.name}
            className={classnames(className, !!error ? 'Error' : '')}
            input={input}
            {...input}
            {...rest}
        >
            {typeof children === 'function'
                ? children({ input, label })
                : children}
        </Component>
        <StatusMessage message={error} statusClass="error" />
    </div>
);

ComponentWithLabelAndErrorMessage.propTypes = {
    input: PropTypes.shape({
        name: PropTypes.string,
        value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.instanceOf(File)
        ])
    }).isRequired,
    label: PropTypes.string,
    error: PropTypes.string,
    labelClasses: PropTypes.string,
    className: PropTypes.string
};

export { ComponentWithLabelAndErrorMessage };
