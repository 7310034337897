import { REMOVE_INSTRUMENT_FROM_STORE } from '../constants';

export const deleteInstrumentReducer = (state, { type, instrumentId }) => {
    switch (type) {
        case REMOVE_INSTRUMENT_FROM_STORE:
            return {
                ...state,
                instruments: state.instruments.filter(
                    item => item.id !== instrumentId
                )
            };
        default:
            return state;
    }
};
