import {
    put,
    takeEvery,
    takeLatest,
    all,
    select,
    take,
    call,
    throttle,
    delay,
    takeMaybe
} from 'redux-saga/effects';

import {
    fetchDataAction,
    FETCH_SUCCESS,
    FETCH_ERROR,
    fetchToStatusClass
} from '../fetch';
import {
    ADD_MODKIT_TO_STORE,
    APPLY_MODKIT,
    CHANGE_MODKIT_STATUS,
    CHANGE_MODKIT_STATUS_FOR_INSTRUMENT,
    CREATE_MODKIT,
    DELETE_MODKIT,
    FETCH_INSTRUMENTS_BY_MODKIT,
    FETCH_MODKIT_LIST,
    FETCH_TYPES,
    REFRESH_MODKIT_LIST,
    REMOVE_MODKIT_FROM_STORE,
    RETIRE_MODKIT,
    UNDO_MODKIT,
    EXPORT_MODKITS_MATRIX
} from './constants';
import {
    modkitListIsUpdatingAction,
    displayModKitStatusAction,
    refreshModKitListAction
} from './actions';
import { onError, onSuccessful } from '../fetch/actions';
import history from '../../history';
import { MESSAGE_DISPLAY_TIMEOUT } from '../../common/constants';
import { openSnackbarAction } from '../snackbar/actions';
import { INSTRUMENT_MODKIT_STATUS } from '../../common/utils/details';
import { getCloseModalWindowAction } from '../modal';
import { defaultStatusMessage } from './reducers/createModkit';
import { selectInstrumentAction } from '../query';

import { buildQuery } from '../../common/utils/graphQL';
import { exportToCsv } from '../../common/utils/exportToFile';
import { buildInstrumentsModkitsMatrix } from './utils';

let messageId = 1;
const modkitListSelector = state => state.modkits;
const CHANGE_MODKIT_STATUS_NAMESPACE = 'changeModKit';

export function* fetchModKitList() {
    yield put(modkitListIsUpdatingAction());
    yield put(
        fetchDataAction('modkits')('/api/v1/modkits', {
            method: 'get',
            params: {}
        })
    );
}

function* createModKit({ formData }) {
    yield put(
        fetchDataAction('createModKit')('/api/v1/modkits', {
            method: 'post',
            data: formData
        })
    );

    const action = yield takeMaybe([
        onSuccessful('createModKit'),
        onError('createModKit')
    ]);

    if (action instanceof Object && action.payload instanceof Object) {
        const message = defaultStatusMessage(action.payload, action.type);

        if (action.type === FETCH_SUCCESS) {
            yield put(
                openSnackbarAction({
                    messageId: messageId++,
                    message,
                    mode: 'success'
                })
            );
            yield put({
                type: ADD_MODKIT_TO_STORE,
                modkit: action.payload
            });
            yield put(getCloseModalWindowAction());
        } else {
            yield put(
                openSnackbarAction({
                    messageId: messageId++,
                    message,
                    mode: 'error'
                })
            );
        }
    }
}

function* deleteModKit({ id }) {
    yield put(
        fetchDataAction(DELETE_MODKIT)(`/api/v1/modkits/${id}`, {
            method: 'delete',
            params: {
                id: id
            }
        })
    );

    const action = yield takeMaybe([
        onSuccessful(DELETE_MODKIT),
        onError(DELETE_MODKIT)
    ]);

    if (action) {
        if (action.type === FETCH_SUCCESS) {
            yield history.push('/modkits');
            yield put({
                type: REMOVE_MODKIT_FROM_STORE,
                id: id
            });
            yield put(
                openSnackbarAction({
                    messageId: messageId++,
                    message: `ModKit ${id} was deleted successfully.`,
                    mode: 'success'
                })
            );
        } else {
            yield put(
                openSnackbarAction({
                    messageId: messageId++,
                    message: action.payload.message,
                    mode: 'error'
                })
            );
        }
    }
}

function* retireModKit({ id }) {
    yield put(
        fetchDataAction(RETIRE_MODKIT)(`/api/v1/modkits/${id}/retire`, {
            method: 'post',
            params: {}
        })
    );

    const action = yield takeMaybe([
        onSuccessful(RETIRE_MODKIT),
        onError(RETIRE_MODKIT)
    ]);

    if (action) {
        if (action.type === FETCH_SUCCESS) {
            yield put({
                type: CHANGE_MODKIT_STATUS,
                id: id,
                payload: action.payload
            });

            yield put(
                openSnackbarAction({
                    messageId: messageId++,
                    message: `ModKit ${id} was retired successfully.`,
                    mode: 'success'
                })
            );
        } else {
            yield put(
                openSnackbarAction({
                    messageId: messageId++,
                    message: action.payload.message,
                    mode: 'error'
                })
            );
        }
    }
}

export function* displayModKitListStatus(namespace, id) {
    const action = yield takeMaybe(
        action =>
            action.meta &&
            action.meta.namespace === namespace &&
            (action.type === FETCH_SUCCESS || action.type === FETCH_ERROR)
    );

    if (action) {
        if (id) {
            yield put(
                displayModKitStatusAction(
                    id,
                    namespace,
                    action.payload && action.payload.message,
                    fetchToStatusClass(action.type)
                )
            );

            yield delay(MESSAGE_DISPLAY_TIMEOUT);
        }

        yield put(refreshModKitListAction());
    }
}

export function* fetchTypes() {
    yield put(
        fetchDataAction('platformTypes')('/api/v1/instruments/types', {
            method: 'get',
            params: {}
        })
    );
}

function* refreshModKitList() {
    const modkitList = yield select(modkitListSelector);

    if (modkitList.items && modkitList.items.length > 0) {
        yield call(fetchModKitList);
    }
}

function* fetchInstrumentsByModKit({ platformType, id }) {
    yield put(
        fetchDataAction(FETCH_INSTRUMENTS_BY_MODKIT)(
            `/api/v1/query/search`,
            {
                method: 'post',
                headers: { 'Content-Type': 'application/json' },
                data: buildQueryByPlatformType(platformType)
            },
            { id }
        )
    );
}

function* applyModKit({ id, comment, instrumentId, reloadInstrument }) {
    yield put(
        fetchDataAction(CHANGE_MODKIT_STATUS_NAMESPACE)(
            `/api/v1/instruments/${instrumentId}/change-modkit`,
            {
                method: 'put',
                data: {
                    id,
                    comment,
                    status: INSTRUMENT_MODKIT_STATUS.APPLIED.toUpperCase()
                }
            }
        )
    );

    if (reloadInstrument) {
        yield call(refreshInstrumentDetails, instrumentId, id, 'apply');
    } else {
        yield call(refreshModKitStatusForInstrument, instrumentId, id, 'apply');
    }
}

function* undoModKit({ id, comment, instrumentId, reloadInstrument }) {
    yield put(
        fetchDataAction(CHANGE_MODKIT_STATUS_NAMESPACE)(
            `/api/v1/instruments/${instrumentId}/change-modkit`,
            {
                method: 'put',
                data: {
                    id,
                    comment,
                    status: INSTRUMENT_MODKIT_STATUS.REVOKED.toUpperCase()
                }
            }
        )
    );

    if (reloadInstrument) {
        yield call(refreshInstrumentDetails, instrumentId, id, 'revoke');
    } else {
        yield call(
            refreshModKitStatusForInstrument,
            instrumentId,
            id,
            'revoke'
        );
    }
}

function* refreshModKitStatusForInstrument(instrumentId, id, operation) {
    const action = yield takeMaybe([
        onSuccessful(CHANGE_MODKIT_STATUS_NAMESPACE),
        onError(CHANGE_MODKIT_STATUS_NAMESPACE)
    ]);

    if (action) {
        yield put({
            type: CHANGE_MODKIT_STATUS_FOR_INSTRUMENT,
            instrumentId,
            id,
            operation,
            payload: action.payload,
            actionStatus: action.type
        });
    }
}

function* refreshInstrumentDetails(instrumentId, modKitId, operationType) {
    const action = yield takeMaybe([
        onSuccessful(CHANGE_MODKIT_STATUS_NAMESPACE),
        onError(CHANGE_MODKIT_STATUS_NAMESPACE)
    ]);

    if (action) {
        if (action.type === FETCH_SUCCESS) {
            const message =
                operationType === 'apply'
                    ? `Modkit ${modKitId} was successfully applied.`
                    : `Modkit ${modKitId} was successfully revoked.`;

            yield put(
                openSnackbarAction({
                    messageId: messageId++,
                    message: message,
                    mode: 'success'
                })
            );
        }

        yield put(selectInstrumentAction(instrumentId));
    }
}

function* exportInstrumentsModkitsMatrixForPlatformType({
    platformType,
    modkits
}) {
    yield put(
        fetchDataAction(EXPORT_MODKITS_MATRIX)(`/api/v1/query/search`, {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            data: buildQueryByPlatformType(platformType)
        })
    );

    yield call(createInstrumentModkitsMatrixForCsv, platformType, modkits);
}

function* createInstrumentModkitsMatrixForCsv(platformType, modkits) {
    const action = yield takeMaybe([
        onSuccessful(EXPORT_MODKITS_MATRIX),
        onError(EXPORT_MODKITS_MATRIX)
    ]);

    if (action) {
        if (action.type === FETCH_SUCCESS) {
            const data = buildInstrumentsModkitsMatrix(
                platformType,
                action.payload.instruments,
                modkits
            );

            const fileName = `${platformType}_InstrumentsModkits_${Date.now()}`;

            exportToCsv(data, fileName);
        }
    }
}

function* watchRefreshModKitList() {
    yield throttle(
        MESSAGE_DISPLAY_TIMEOUT * 2,
        REFRESH_MODKIT_LIST,
        refreshModKitList
    );
}

function* watchFetchTypes() {
    yield takeEvery(FETCH_TYPES, fetchTypes);
}

function* watchModKitList() {
    yield takeEvery(FETCH_MODKIT_LIST, fetchModKitList);
}

function* watchCreateModKit() {
    yield takeEvery(CREATE_MODKIT, createModKit);
}

function* watchDeleteModkit() {
    yield takeLatest(DELETE_MODKIT, deleteModKit);
}

function* watchRetireModkit() {
    yield takeLatest(RETIRE_MODKIT, retireModKit);
}

function* watchFetchInstrumentsByModkit() {
    yield takeLatest(FETCH_INSTRUMENTS_BY_MODKIT, fetchInstrumentsByModKit);
}

function* watchApplyModkit() {
    yield takeEvery(APPLY_MODKIT, applyModKit);
}

function* watchUndoModkit() {
    yield takeEvery(UNDO_MODKIT, undoModKit);
}

function* watchExportModkitsMatrix() {
    yield takeLatest(
        EXPORT_MODKITS_MATRIX,
        exportInstrumentsModkitsMatrixForPlatformType
    );
}

const buildQueryByPlatformType = platformType => {
    const queryParams = [
        {
            type: 'STRING',
            field: 'PlatformType',
            operator: 'EQ',
            resource: 'HARDWARE',
            values: [platformType]
        }
    ];

    return buildQuery(
        queryParams,
        'metadata{modKits{id status modified{username at}}}',
        true
    );
};

export function* modkitSagas() {
    yield all([
        watchModKitList(),
        watchCreateModKit(),
        watchDeleteModkit(),
        watchRetireModkit(),
        watchFetchTypes(),
        watchRefreshModKitList(),
        watchFetchInstrumentsByModkit(),
        watchApplyModkit(),
        watchUndoModkit(),
        watchExportModkitsMatrix()
    ]);
}
