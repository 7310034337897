import React, { useState, useEffect, useReducer, useRef } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import Calendar from './Calendar';
import { initialDates, datesReducer, toCalendarValues } from './datesReducer';
import {
    transformStringToDate,
    formatDateToDatetimeIsoString
} from './helpers';
import {
    AFTER,
    BEFORE,
    BETWEEN,
    START_PERIOD,
    END_PERIOD,
    FORMAT,
    RESET,
    REPLACE
} from './constants';
import './calendarInput.scss';

const CalendarInput = ({ operator, onValueChange, disabled, values }) => {
    const defaultDates = values
        ? toCalendarValues(values, operator)
        : initialDates;

    const [showCalendar, setShowCalendar] = useState(false);
    const [periodType, setPeriodType] = useState(START_PERIOD);
    const [dates, dispatch] = useReducer(datesReducer, defaultDates);

    const node = useRef();

    // The order of UseEffect functions is IMPORTANT!
    useEffect(() => dispatch({ type: RESET }), [operator]);

    useEffect(
        () => {
            if (values) {
                dispatch({ type: REPLACE, payload: { values, operator } });
            }
        },
        [values ? values.join('') : '']
    ); // This hack is needed because the array comparison always causes the effect to re-invoke no matter if the array is the same or not

    useEffect(
        () => {
            const startDate = dates[START_PERIOD].value.dateObject;
            const endDate = dates[END_PERIOD].value.dateObject;

            const values = [];
            startDate &&
                values.push(formatDateToDatetimeIsoString(startDate, false));
            endDate &&
                values.push(formatDateToDatetimeIsoString(endDate, true));

            onValueChange(values);
        },
        [
            dates[START_PERIOD].value.dateObject,
            dates[END_PERIOD].value.dateObject
        ]
    );

    useEffect(() => {
        document.addEventListener('click', onClick);

        return () => document.removeEventListener('click', onClick);
    }, []);

    const onClick = e => {
        if (!node.current.contains(e.target)) {
            setShowCalendar(false);
        }
    };

    const onChange = periodType => param => {
        const dateString =
            typeof param.target === 'object' ? param.target.value || '' : param;

        const dateObject = transformStringToDate(dateString, FORMAT);

        dispatch({
            type: periodType,
            payload: {
                dateString,
                dateObject
            }
        });

        // This code may be uncommented in case we want the calendar to immediately hide after we select a date.
        // There is a strange behavior when we manually enter value in the input field. So I am commenting this for now.
        // if (dateObject) { setShowCalendar(false); }
    };

    const onInputFocus = periodType => {
        setPeriodType(periodType);
        setShowCalendar(true);
    };

    const getCssClasses = period => {
        return classnames(
            'CalendarInput',
            periodType === period && 'CalendarInput--selected',
            dates[period].value.dateObject
                ? 'CalendarInput--success'
                : 'CalendarInput--invalid'
        );
    };

    return (
        <div ref={node}>
            <div className="CalendarInputsWrapper">
                {operator === BEFORE ? null : (
                    <input
                        className={getCssClasses(START_PERIOD)}
                        type="text"
                        value={dates[START_PERIOD].value.dateString}
                        onChange={onChange(START_PERIOD)}
                        placeholder={operator === BETWEEN ? 'From' : FORMAT}
                        onFocus={() => onInputFocus(START_PERIOD)}
                        disabled={disabled}
                    />
                )}
                {operator === AFTER ? null : (
                    <input
                        className={getCssClasses(END_PERIOD)}
                        type="text"
                        value={dates[END_PERIOD].value.dateString}
                        onChange={onChange(END_PERIOD)}
                        placeholder={operator === BETWEEN ? 'To' : FORMAT}
                        onFocus={() => onInputFocus(END_PERIOD)}
                        disabled={disabled}
                    />
                )}
            </div>
            {showCalendar ? (
                <Calendar
                    startDate={dates[START_PERIOD].value.dateObject}
                    endDate={dates[END_PERIOD].value.dateObject}
                    selectedPeriodDate={dates[periodType].value.dateObject}
                    additionalModifiers={dates[periodType].modifiers}
                    periodType={periodType}
                    onDateChange={onChange(periodType)}
                />
            ) : null}
        </div>
    );
};

CalendarInput.propTypes = {
    operator: PropTypes.oneOf([BEFORE, AFTER, BETWEEN]).isRequired,
    onValueChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    values: PropTypes.array
};

CalendarInput.defaultProps = {
    disabled: false
};

export default CalendarInput;
