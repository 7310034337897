import React from 'react';
import { withAuth0 } from '@auth0/auth0-react';
import App from './App';
import Loading from '../loading/Loading';
import AuthRequiredWrapper from './AuthRequiredWrapper';
import axios from 'axios';
import {
    getSamlLogoutRedirectUri,
    isSamlUser
} from '../../common/utils/auth-helper';

class AuthBouncer extends React.Component {
    storageKey = 'onLoginCompleteUrl';
    samlStorageKey = 'isUserSamlAuth';
    skipLoginKey = 'skipLogin';

    constructor(props) {
        super(props);
        this.setAxiosTokenInterceptor();
    }

    render() {
        const {
            isLoading,
            error,
            user,
            loginWithRedirect,
            isAuthenticated,
            logout
        } = this.props.auth0;

        if (error) {
            return <div />;
        }

        if (isLoading) {
            return <Loading loading={true} />;
        }

        const urlToSet = sessionStorage.getItem(this.storageKey);
        const isUserSamlAuth = sessionStorage.getItem(this.samlStorageKey);
        const skipLogin = sessionStorage.getItem(this.skipLoginKey);

        if (!isAuthenticated) {
            const redirectUri = window.location.href;

            if (!urlToSet) {
                sessionStorage.setItem(this.storageKey, redirectUri);
            }

            if (isUserSamlAuth) {
                // we enter here if the token has expired and the user was previously logged in with SAML
                // we have to log the user out using SAML, so redirect to respective url.
                sessionStorage.clear();
                sessionStorage.setItem(this.skipLoginKey, 'true');
                window.location.href = getSamlLogoutRedirectUri();
            } else {
                if (skipLogin) {
                    // skip the login once, if the flag is set
                    sessionStorage.removeItem(this.skipLoginKey);
                } else {
                    loginWithRedirect({ redirectUri });
                }
            }

            return null;
        }

        if (skipLogin) {
            sessionStorage.removeItem(this.skipLoginKey);
        }

        // when auth0 refreshes the token, navigation to base url will occur
        // to keep the user on the current page, replace the url with the one saved before token refresh
        if (urlToSet) {
            window.history.replaceState(null, '', urlToSet);
            sessionStorage.removeItem(this.storageKey);
        }

        // set a flag if the logged in user is using SAML
        if (isSamlUser(user.email) && !isUserSamlAuth) {
            sessionStorage.setItem(this.samlStorageKey, 'true');
        }

        return (
            <div className="App">
                <div className="Main">
                    <AuthRequiredWrapper>
                        <App />
                    </AuthRequiredWrapper>
                </div>
            </div>
        );
    }

    setAxiosTokenInterceptor() {
        axios.interceptors.request.use(async config => {
            const accessToken = await this.props.auth0.getAccessTokenSilently();

            if (accessToken) {
                config.headers.common.Authorization = `Bearer ${accessToken}`;
            }

            return config;
        });
    }
}

export default withAuth0(AuthBouncer);
