import React from 'react';
import { Route } from 'react-router-dom';
import { connect } from 'react-redux';

import history from '../../history';
import { hasAccessToRoute, getStartPage } from '../../common/utils/roles';
import InvalidCredentials from '../statusMessage/InvalidCredentials';

const AuthorizedRoute = props => {
    const { userRoles, location } = props;

    if (location.pathname === '/') {
        const defaultPage =
            Array.isArray(userRoles) && userRoles.length > 0
                ? getStartPage(userRoles[0])
                : null;

        if (defaultPage) {
            history.push(defaultPage);
        } else {
            return <InvalidCredentials />;
        }
    }

    if (hasAccessToRoute(userRoles, location.pathname)) {
        return <Route {...props} />;
    } else {
        return <InvalidCredentials />;
    }
};

export default connect(({ user: { roles } }) => ({
    userRoles: roles
}))(AuthorizedRoute);
