export const initialStorageBrowserState = {
    shares: [],
    selectedListItem: '',
    foldersOpenStatus: {},
    fileDownloadUrl: ''
};

export const storageBrowserActionTypes = {
    SET_SHARES: 'setShares',
    OPEN_FOLDER: 'openFolder',
    CLOSE_FOLDER: 'closeFolder',
    SELECT_FILE: 'selectFile',
    REFRESH: 'refresh'
};

export const storageBrowserReducer = (state, action) => {
    const {
        SET_SHARES,
        OPEN_FOLDER,
        CLOSE_FOLDER,
        SELECT_FILE,
        REFRESH
    } = storageBrowserActionTypes;

    switch (action.type) {
        case SET_SHARES:
            return {
                ...state,
                shares: action.shares
            };
        case OPEN_FOLDER:
            return {
                ...state,
                selectedListItem: action.fullPath,
                foldersOpenStatus: {
                    ...state.foldersOpenStatus,
                    [action.fullPath]: true
                },
                fileDownloadUrl: ''
            };
        case CLOSE_FOLDER:
            return {
                ...state,
                selectedListItem: action.fullPath,
                foldersOpenStatus: {
                    ...state.foldersOpenStatus,
                    [action.fullPath]: false
                },
                fileDownloadUrl: ''
            };
        case SELECT_FILE:
            return {
                ...state,
                selectedListItem: action.fullPath,
                fileDownloadUrl: action.fileDownloadUrl
            };
        case REFRESH:
            return {
                ...initialStorageBrowserState,
                shares: state.shares
            };
        default:
            return state;
    }
};
