import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
    fetchSupportListAction,
    getUsersAction
} from '../../controllers/support';
import Table from '../table/Table';
import styles from './Supports.module.scss';
import { COLUMN_TYPE } from '../table/constants';
import { Link } from 'react-router-dom';
import { getUpdateNameFromPath } from '../../common/utils';

const SupportList = props => {
    const { fetchSupportListAction, getUsersAction } = props;

    const columns = [
        {
            type: COLUMN_TYPE.TEXT,
            key: 'instrumentId',
            displayName: 'Id',
            width: 210,
            hasSorting: true,
            hasFiltering: true,
            render: instrumentId => (
                <div onClick={event => event.stopPropagation()}>
                    <Link to={'/query/' + instrumentId}>
                        <span className="text-primary">{instrumentId}</span>
                    </Link>
                </div>
            )
        },
        {
            type: COLUMN_TYPE.TEXT,
            key: 'alias',
            displayName: 'Alias',
            hasSorting: true,
            hasFiltering: true,
            render: alias => <div className="fix-nowrap">{alias}</div>
        },
        {
            type: COLUMN_TYPE.TEXT,
            key: 'name',
            displayName: 'Client Name',
            hasSorting: true,
            hasFiltering: true,
            render: name => <div className="fix-nowrap">{name}</div>
        },
        {
            type: COLUMN_TYPE.TEXT,
            key: 'supporterName',
            displayName: 'Supporter',
            hasSorting: true,
            hasFiltering: true,
            render: supporterName => (
                <div className="fix-nowrap">
                    {getSupporterName(supporterName)}
                </div>
            )
        },
        {
            type: COLUMN_TYPE.TEXT,
            key: 'status',
            displayName: 'Status',
            hasSorting: true,
            hasFiltering: true
        },
        {
            type: COLUMN_TYPE.LOCAL_DATE_NOSECONDS,
            key: 'supporterSessionTime',
            displayName: 'Session Time',
            hasSorting: true,
            width: 200,
            hasFiltering: true
        },
        {
            type: COLUMN_TYPE.TEXT,
            key: 'serviceNotificationNumber',
            displayName: 'Noti',
            hasSorting: true,
            hasFiltering: true,
            render: serviceNotificationNumber => (
                <div className="fix-nowrap">{serviceNotificationNumber}</div>
            )
        },
        {
            type: COLUMN_TYPE.TEXT,
            key: 'id',
            displayName: ' ',
            hasSorting: false,
            hasFiltering: false,
            width: 75,
            className: styles.TextCenter,
            render: id => (
                <div
                    className="text-right"
                    onClick={event => event.stopPropagation()}
                >
                    <Link
                        className="btn btn-sm btn-light text-dark"
                        to={'/support/' + id}
                    >
                        <b>Details</b>
                    </Link>
                </div>
            )
        }
    ];

    useEffect(() => {
        getUsersAction();
        fetchSupportListAction();
    }, []);

    return (
        <div className={styles.ContentWrapper}>
            <section />
            <br /> <br />
            <section className={styles.Scroll}>
                <div className="table_TableWrapper__1_LpY">
                    <div />
                    <Table columns={columns} data={props.supportList} />
                </div>
            </section>
        </div>
    );
};

export default connect(
    ({ supports, users }) => ({
        supportList: supports.items || [],
        loading: !!supports.loading,
        usersList: users.items || []
    }),
    {
        fetchSupportListAction,
        getUsersAction
    }
)(SupportList);

function getSupporterName(supporterName) {
    if (
        supporterName != null &&
        supporterName != undefined &&
        supporterName.indexOf(',') > -1
    ) {
        return supporterName.split(',')[0];
    }
    return supporterName;
}
