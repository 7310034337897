import { findProp } from './query';
import { BETWEEN } from '../../components/calendar/constants';

export const CRITERIA = {
    HARDWARE: 'Hardware',
    HARDWARE_MODULE: 'Hardware Module',
    MODKIT: 'ModKit',
    SOFTWARE: 'Software',
    HOTFIX: 'HotFix'
};

export const isMetadataCriteria = criteria =>
    criteria !== CRITERIA.HARDWARE &&
    criteria !== CRITERIA.HARDWARE_MODULE &&
    criteria !== CRITERIA.SOFTWARE &&
    criteria !== CRITERIA.MODKIT &&
    criteria !== CRITERIA.HOTFIX;

let criteriaId = 1;

const isValidPropertySelection = selection =>
    selection &&
    selection.property &&
    selection.property.field &&
    selection.operator &&
    selection.operator.name &&
    Array.isArray(selection.values);

const flatArray = arr => {
    if (!Array.isArray(arr)) return arr;
    return Array.isArray(arr[0]) ? arr[0] : arr;
};

const serializeSelectedProperties = selection => {
    const hasResource = selection.property.field.indexOf(':') > -1;
    const field = hasResource
        ? selection.property.field
              .split(':')
              .slice(-1)
              .join('')
        : selection.property.field;
    const resource = hasResource
        ? selection.property.field
              .split(':')
              .slice(0, -1)
              .join('')
        : null;

    return {
        field,
        type: selection.property.type,
        operator: selection.operator.name,
        values: flatArray(selection.values),
        ...(resource && { resource })
    };
};

function CriteriaData(queryParamsMetaData) {
    this.key = criteriaId++;
    this.meta = { ...queryParamsMetaData };
    this.data = { selected: { criteria: CRITERIA.HARDWARE } };
    this.error = '';

    this.serialize = ({ selected, operators }) => {
        this.data = { selected, operators };

        if (isValidPropertySelection(selected)) {
            this.queryParams = serializeSelectedProperties(selected);
        }
    };

    this.findProperty = object => value => findProp(object)(value);

    this.findOperatorByName = name =>
        this.data.operators.find(o => o.name === name) || {};

    this.setErrorMessage = message => {
        this.error = message;
    };

    this.hasError = () => !!this.error;

    this.isPropertySelected = () =>
        this.data.selected && this.data.selected.property;

    this.isOperatorSelected = () =>
        this.data.selected &&
        this.data.selected.operator &&
        typeof this.data.selected.operator.numberOfArguments === 'number';

    this.isDataFilledOut = () => {
        if (!this.data.selected || !this.data.selected.values) return false;

        const values = flatArray(this.data.selected.values);

        if (this.data.selected.operator.name === BETWEEN) {
            return values.length === 2;
        }

        return (
            values.length === this.data.selected.operator.numberOfArguments - 1
        );
    };

    this.isDataFilledOutEmpty = () => {
        if (Array.isArray(this.data.selected.values)) {
            for (let i = 0; i < this.data.selected.values.length; i++) {
                const value = this.data.selected.values[i];
                if (typeof value === 'string' && value.trim().length === 0)
                    return true;
            }
        }
        return false;
    };
}

const CRITERIA_NOT_FILLED_OUT_ERROR =
    'Criteria incomplete, please fill-out user values!';

export const validateCriterias = criterias => {
    let invalid = [];
    for (let i = 0; i < criterias.length; i++) {
        const criteria = criterias[i];
        if (!invalid.includes(criteria)) {
            criteria.setErrorMessage('');
            if (
                criteria.isOperatorSelected() &&
                criteria.isPropertySelected() &&
                (!criteria.isDataFilledOut() || criteria.isDataFilledOutEmpty())
            ) {
                criteria.setErrorMessage(CRITERIA_NOT_FILLED_OUT_ERROR);
            }

            if (
                !criteria.isDataFilledOut() ||
                criteria.isDataFilledOutEmpty() ||
                !criteria.isPropertySelected()
            ) {
                invalid.push(criteria);
            }
        }
    }
    return invalid.length === 0;
};

export default CriteriaData;
