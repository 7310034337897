import { OPEN_MODAL, CLOSE_MODAL } from './constants';

export const modalWindowReducer = (
    state = { title: '', component: null },
    { type, title, component, props }
) => {
    switch (type) {
        case OPEN_MODAL:
            return { title, component, props };
        case CLOSE_MODAL:
            return { title: undefined, component: null, props: undefined };
        default:
            return state;
    }
};
