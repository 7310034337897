export const FETCH_DATA = 'fetch.data';
export const FETCH_START = 'fetch.start';
export const FETCH_SUCCESS = 'fetch.success';
export const FETCH_ERROR = 'fetch.error';
export const INITIAL_STATE = [];
export const DEFAULT_OPTIONS = {
    initialState: INITIAL_STATE
};

const STATUSES = {
    [FETCH_SUCCESS]: 'notification',
    [FETCH_ERROR]: 'error'
};
export const fetchToStatusClass = fetchStatus => STATUSES[fetchStatus];
