import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './snackbar.module.scss';
import { closeSnackbarAction } from '../../controllers/snackbar/actions';

const getPositionClass = position => {
    return position
        .split('-')
        .map(p => styles[p.charAt(0).toUpperCase() + p.slice(1)]);
};

const getModeClass = mode => {
    switch (mode) {
        case 'error':
            return styles.Error;
        case 'success':
            return styles.Success;
        default:
            return styles.Info;
    }
};

const Snackbar = props => {
    const {
        mode,
        onHide,
        stayVisibleInterval,
        position,
        message,
        messageId,
        children,
        defaultOpen,
        closeSnackbarAction
    } = props;

    let timeoutId = null;

    const onClose = () => {
        onHide && onHide();
        closeSnackbarAction();
        clearTimeout(timeoutId);
    };

    useEffect(
        () => {
            if (defaultOpen && stayVisibleInterval) {
                timeoutId = setTimeout(onClose, stayVisibleInterval);
            }

            return () => {
                clearTimeout(timeoutId);
            };
        },
        [messageId]
    );

    return !defaultOpen ? null : (
        <div
            className={classnames(
                styles.Container,
                getPositionClass(position),
                getModeClass(mode)
            )}
        >
            <div className={styles.MessageContainer}>
                <div className={styles.Message}>
                    {message ? message : children}
                </div>
            </div>
            <div className={styles.Action} onClick={onClose}>
                &times;
            </div>
        </div>
    );
};

Snackbar.propTypes = {
    mode: PropTypes.oneOf(['info', 'error', 'success']),
    onHide: PropTypes.func,
    stayVisibleInterval: PropTypes.number,
    position: PropTypes.oneOf([
        'top',
        'bottom',
        'top-left',
        'top-right',
        'bottom-left',
        'bottom-right'
    ]),
    message: PropTypes.string,
    children: PropTypes.any,
    defaultOpen: PropTypes.bool,
    messageId: PropTypes.number
};

Snackbar.defaultProps = {
    position: 'bottom-right',
    mode: 'info'
};

export default connect(
    null,
    {
        closeSnackbarAction
    }
)(Snackbar);
