import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';

import Confirmation from '../confirmation/Confirmation';
import { deleteTagAction } from '../../controllers/tags';

const DeleteTagForm = props => {
    const { instruments, deleteTagAction, id, name } = props;

    const count = Array.isArray(instruments) ? instruments.length : 0;

    let message = `You are about to delete the tag <span class="ConfirmMessageHighlight">${name}</span> from the system.`;

    if (!!count) {
        message =
            message +
            `<br /> It has <span class="ConfirmMessageHighlightSpace"> ${count} </span> ${
                count === 1 ? 'instrument' : 'instruments'
            } attached to it.`;
    }

    return (
        <Confirmation
            onConfirm={() => deleteTagAction(id)}
            message={parse(message)}
        />
    );
};

DeleteTagForm.propTypes = {
    name: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    instruments: PropTypes.array.isRequired,
    deleteTagAction: PropTypes.func
};

export default connect(
    ({ instrumentsByTag: { instruments } }) => ({
        instruments
    }),
    {
        deleteTagAction
    }
)(DeleteTagForm);
