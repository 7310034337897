import React from 'react';
import Selector from '../../selector/Selector';
import OperatorAndValues from './OperatorAndValues';

const BaseCriteria = props => {
    const {
        selected,
        findProperty,
        getPropertyOptions,
        operatorOptions,
        onOperatorSelected,
        onUserValueChanged,
        serialize,
        operators,
        propertyLabel
    } = props;

    const onPropertySelected = ({ target: { value } }) => {
        const selectedProperty = findProperty(value);
        const operatorsByType = selectedProperty.field
            ? operators[selectedProperty.type]
            : [];

        serialize({
            selected: {
                criteria: selected.criteria,
                property: selectedProperty
            },
            operators: operatorsByType
        });
    };

    const selectedOption = selected.property ? selected.property.field : null;

    return (
        <>
            <li>
                <Selector
                    label={propertyLabel}
                    className="Property"
                    options={getPropertyOptions}
                    selectedOption={selectedOption}
                    onSelectionChange={onPropertySelected}
                />
            </li>
            <OperatorAndValues
                selected={selected}
                operatorOptions={operatorOptions}
                onOperatorSelected={onOperatorSelected}
                onUserValueChanged={onUserValueChanged}
            />
        </>
    );
};

export default BaseCriteria;
