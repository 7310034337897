import React from 'react';

const FolderIcon = ({ className, title }) => (
    <svg className={className} viewBox="0 0 24 24">
        {title && <title>{title}</title>}
        <path
            fill="currentColor"
            d="M10,4H4C2.89,4 2,4.89 2,6V18A2,2 0 0,0 4,20H20A2,2 0 0,0 22,18V8C22,6.89 21.1,6 20,6H12L10,4Z"
        />
    </svg>
);

export default FolderIcon;
