import React from 'react';
import List from './List';
import NoData from './NoData';
import Title from './Title';
import getModificationDetails from './getModificationDetails';
import { hasElements } from '../../../common/utils';

const renderUpdates = notifications =>
    notifications.map(({ notification, notificationStatus }) => (
        <div key={notification.id} className="update">
            <List
                list={[
                    notification.payload,
                    { status: notificationStatus.status },
                    getModificationDetails(notificationStatus.modified)
                ]}
            />
        </div>
    ));

const UpdatesSection = ({ notifications = [] }) => (
    <>
        <Title className="level_1" title="Updates" />
        {hasElements(notifications) ? renderUpdates(notifications) : <NoData />}
    </>
);

export default UpdatesSection;
