import React from 'react';
import parse from 'html-react-parser';
import Confirmation from '../confirmation/Confirmation';

const DiscardHotFixForm = ({ id, name, operation, confirmAction }) => {
    const message = parse(
        `Do you really want to ${operation} hotfix with Document Number <span class="ConfirmationMessageBreakWord">'${name}'</span>?`
    );

    return (
        <Confirmation onConfirm={() => confirmAction(id)} message={message} />
    );
};

export default DiscardHotFixForm;
