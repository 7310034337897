import React from 'react';
import { connect } from 'react-redux';
import { Field, Form } from 'react-final-form';

import Input from '../../../input/Input';
import Button from '../../../button/Button';
import Autocomplete from '../../../autocomplete/Autocomplete';
import Selector from '../../../selector/Selector';
import { addLogbookEntryAction } from '../../../../controllers/logbook/actions';
import { getCloseModalWindowAction } from '../../../../controllers/modal';
import './AddLogbookEntryForm.scss';
import operations from './logbookOperations.json';
import { customSort } from '../../../../common/utils/table';
import { SORT_ORDER } from '../../../../common/hooks/useSort';
import {
    getSerialNumberKey,
    hasElements
} from '../../../../common/utils/details';
import CalendarInput from '../../../calendar/CalendarInput';
import Checkbox from '../../../checkbox/Checkbox';
import { BEFORE } from '../../../calendar/constants';
import InputFile from '../../../inputFile/InputFile';

const SEPARATOR = '___';
const SYSTEM = 'SYSTEM';
const SYSTEM_MODULE_VALUE = `${SYSTEM}${SEPARATOR}${SYSTEM}`;

const SYSTEM_OPTIONS = [
    {
        name: 'PC',
        value: `${SYSTEM}${SEPARATOR}PC`
    },
    {
        name: SYSTEM,
        value: SYSTEM_MODULE_VALUE
    }
];

SYSTEM_OPTIONS.label = 'System';

const MEGABYTE_TO_BYTES = 1024 * 1024;
const MAX_FILE_SIZE_IN_MEGABYTES = 20;
const MAX_FILE_SIZE_IN_BYTES = MAX_FILE_SIZE_IN_MEGABYTES * MEGABYTE_TO_BYTES;
const validateFileSize = file => {
    if (file instanceof Object && file.size > MAX_FILE_SIZE_IN_BYTES) {
        return `The file can not exceed ${MAX_FILE_SIZE_IN_MEGABYTES}MB!`;
    }

    return undefined;
};

const getModuleRequestParameter = v => {
    const [type, name, serialNumber] = v.split(SEPARATOR);

    return {
        type,
        name,
        serialNumber
    };
};

const formatModules = (modules, type, getSerialNumberKey) => {
    let formattedModules = modules.map(module => {
        const serialNumberKey =
            typeof getSerialNumberKey === 'function'
                ? getSerialNumberKey(module)
                : null;

        const serialNumber = serialNumberKey
            ? type === 'Hardware'
                ? module.properties[serialNumberKey]
                : module[serialNumberKey]
            : null;

        const name = serialNumber
            ? `${module.name} (${serialNumber})`
            : module.name;

        const value = serialNumber
            ? `${type}${SEPARATOR}${module.name}${SEPARATOR}${serialNumber}`
            : `${type}${SEPARATOR}${module.name}`;

        return {
            name,
            value
        };
    });

    formattedModules = customSort(
        formattedModules,
        'name',
        SORT_ORDER.ASCENDING
    );

    formattedModules.label = type;

    return formattedModules;
};

const getOptions = ({ modules, software }) => {
    const options = [SYSTEM_OPTIONS];

    if (hasElements(modules)) {
        options.push(formatModules(modules, 'Hardware', getSerialNumberKey));
    }

    if (software instanceof Object && hasElements(software.softwarePackages)) {
        options.push(
            formatModules(
                software.softwarePackages,
                'Software',
                () => 'version'
            )
        );
    }

    return options;
};

const AddLogbookEntryForm = ({
    addLogbookEntryAction,
    getCloseModalWindowAction,
    instrumentId,
    selectedInstrument
}) => {
    const { maintenance } = selectedInstrument;

    const formInitialValues = {
        module: SYSTEM_MODULE_VALUE,
        needsMaintenance: maintenance.required,
        maintainedUntil: maintenance.endDate ? [maintenance.endDate] : [],
        operation: ''
    };

    const handleSubmit = async values => {
        const maintenance = {
            required: values.needsMaintenance,
            endDate: values.maintainedUntil.length
                ? values.maintainedUntil[0]
                : null
        };

        addLogbookEntryAction(
            {
                attachment: values.attachment,
                operation: values.operation,
                comment: values.comment,
                module: getModuleRequestParameter(values.module),
                maintenance
            },
            instrumentId
        );
        getCloseModalWindowAction();
    };

    const resetMaintenanceFields = form => {
        form.change('needsMaintenance', formInitialValues.needsMaintenance);
        form.change('maintainedUntil', formInitialValues.maintainedUntil);
    };

    const clearMaintenanceFields = form => {
        form.change('needsMaintenance', false);
        form.change('maintainedUntil', []);
    };

    return (
        <>
            <Form
                onSubmit={handleSubmit}
                subscription={{
                    touched: true,
                    error: true,
                    invalid: true,
                    pristine: true,
                    values: true
                }}
                initialValues={formInitialValues}
            >
                {({ handleSubmit, invalid, form, values }) => (
                    <form onSubmit={handleSubmit} className="CommentForm">
                        <Input
                            name="operation"
                            label="Action"
                            placeholder="Type or select"
                            type="text"
                            component={Autocomplete}
                            maxLength="100"
                            items={operations}
                            showAllSuggestionsOnFocus={true}
                            controlledValue={values.operation}
                            onValueChange={() => {}}
                            required
                        />
                        <Field
                            name="module"
                            options={getOptions(selectedInstrument)}
                        >
                            {({ input, options }) => (
                                <div className="FormField">
                                    <Selector
                                        name={input.name}
                                        label="Affected component"
                                        className="AffectedModule"
                                        options={options}
                                        selectedOption={input.value}
                                        onSelectionChange={({
                                            target: { value }
                                        }) => {
                                            input.onChange(value);
                                            value === SYSTEM_MODULE_VALUE
                                                ? resetMaintenanceFields(form)
                                                : clearMaintenanceFields(form);
                                        }}
                                    />
                                </div>
                            )}
                        </Field>
                        <Field name="needsMaintenance">
                            {({ input }) => {
                                return (
                                    <div className="FormField">
                                        <Checkbox
                                            value={input.name}
                                            checked={input.value}
                                            onChange={() => {
                                                input.onChange(!input.value);
                                                if (input.value) {
                                                    form.change(
                                                        'maintainedUntil',
                                                        []
                                                    );
                                                } else {
                                                    form.change(
                                                        'maintainedUntil',
                                                        formInitialValues.maintainedUntil
                                                    );
                                                }
                                            }}
                                            disabled={
                                                values.module !==
                                                    SYSTEM_MODULE_VALUE &&
                                                !values.module.startsWith(
                                                    'Hardware___FCA___'
                                                ) &&
                                                !values.module.startsWith(
                                                    'Hardware___RGA___'
                                                ) &&
                                                !values.module.startsWith(
                                                    'Hardware___MCA___'
                                                )
                                            }
                                        >
                                            <label>
                                                Instrument is under maintenance
                                            </label>
                                        </Checkbox>
                                    </div>
                                );
                            }}
                        </Field>
                        <Field name="maintainedUntil">
                            {({ input }) => {
                                return (
                                    <div className="FormField">
                                        <label>Valid until</label>
                                        <CalendarInput
                                            values={input.value}
                                            onValueChange={input.onChange}
                                            operator={BEFORE}
                                            disabled={
                                                (values.module !==
                                                    SYSTEM_MODULE_VALUE &&
                                                    !values.module.startsWith(
                                                        'Hardware___FCA___'
                                                    ) &&
                                                    !values.module.startsWith(
                                                        'Hardware___RGA___'
                                                    ) &&
                                                    !values.module.startsWith(
                                                        'Hardware___MCA___'
                                                    )) ||
                                                values.needsMaintenance ===
                                                    false
                                            }
                                        />
                                    </div>
                                );
                            }}
                        </Field>
                        <Input
                            name="comment"
                            label="Comment"
                            type="multiline"
                            component="textarea"
                        />
                        <Input
                            name="attachment"
                            label="Attachment"
                            component={InputFile}
                            validate={validateFileSize}
                        />
                        <Button
                            className="Submit"
                            look="Primary"
                            type="submit"
                            width="FullWidth"
                            disabled={invalid}
                        >
                            Submit
                        </Button>
                    </form>
                )}
            </Form>
        </>
    );
};

export default connect(
    ({ selectedInstrument }) => ({ selectedInstrument }),
    {
        addLogbookEntryAction,
        getCloseModalWindowAction
    }
)(AddLogbookEntryForm);
