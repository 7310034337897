import React from 'react';
import Selector from '../../selector/Selector';
import {
    getPropertyOptions,
    mapPropsSorted
} from '../../../common/utils/query';
import BaseCriteria from './BaseCriteria';

const ModuleCriteria = props => {
    const {
        selected,
        findProperty,
        modules,
        operators,
        serialize,
        ...handlers
    } = props;

    const getModulePropertyOptions = () => {
        return selected && selected.module
            ? getPropertyOptions(selected.module)
            : [];
    };

    const findModulePropertyByValue = () => value => {
        return findProperty(selected.module)(value);
    };

    const getModuleNameOptions = () => {
        const propList = [{ name: '-', value: null }].concat(
            mapPropsSorted(modules)
        );

        return propList;
    };

    const onModuleSelected = ({ target: { value } }) => {
        const selectedModule = modules.find(module => module.name === value);

        serialize({
            selected: {
                criteria: selected.criteria,
                module: selectedModule
            }
        });
    };

    const serializeModule = selectedProperty => {
        const newSelected = { ...selectedProperty };
        newSelected.selected.module = selected.module;

        serialize(newSelected);
    };

    const selectedModule = selected.module ? selected.module.name : null;

    return (
        <>
            <li>
                <Selector
                    label="Name"
                    className="Name"
                    options={getModuleNameOptions()}
                    selectedOption={selectedModule}
                    onSelectionChange={onModuleSelected}
                />
            </li>
            {selected &&
                selected.module && (
                    <BaseCriteria
                        propertyLabel="Property"
                        selected={selected}
                        serialize={serializeModule}
                        operators={operators}
                        getPropertyOptions={getModulePropertyOptions()}
                        findProperty={findModulePropertyByValue()}
                        {...handlers}
                    />
                )}
        </>
    );
};

export default ModuleCriteria;
