import {
    FETCH_DATA,
    FETCH_START,
    FETCH_ERROR,
    FETCH_SUCCESS
} from './constants';

export const onSuccessful = namespace => action =>
    action.type === FETCH_SUCCESS && action.meta.namespace === namespace;

export const onError = namespace => action =>
    action.type === FETCH_ERROR && action.meta.namespace === namespace;

export const fetchSuccessAction = (namespace, payload, passToResponse) => ({
    type: FETCH_SUCCESS,
    payload,
    meta: {
        namespace
    },
    passToResponse: passToResponse
});

export const fetchErrorAction = (namespace, payload, passToResponse) => ({
    type: FETCH_ERROR,
    payload,
    meta: {
        namespace
    },
    passToResponse: passToResponse
});

export const fetchStartAction = (namespace, payload) => ({
    type: FETCH_START,
    payload,
    meta: {
        namespace
    }
});

export const fetchDataAction = namespace => (url, options, passToResponse) => ({
    type: FETCH_DATA,
    payload: {
        url,
        options
    },
    meta: {
        namespace
    },
    passToResponse: passToResponse
});
