import { convertDate } from '../../../common/utils';

const getModificationDetails = modified =>
    modified instanceof Object
        ? {
              modifiedBy: `${modified.username} (${modified.firstName} ${
                  modified.lastName
              })`,
              modifiedAt: convertDate(modified.at)
          }
        : null;

export default getModificationDetails;
