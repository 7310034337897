import React from 'react';
import PropTypes from 'prop-types';
import styles from '../details.module.scss';
import KeysAndValues from './KeysAndValues';

const PropertyList = ({ data = {}, label, sorting }) => (
    <section className={styles.Properties}>
        {label && <h4>{label}</h4>}
        <KeysAndValues data={data} sorting={sorting} />
    </section>
);

PropertyList.propTypes = {
    data: PropTypes.object.isRequired
};

export default PropertyList;
