import React, { useEffect } from 'react';
import Loading from '../../loading/Loading';
import CheckErrors from '../../statusMessage/CheckErrors';

const withData = WrappedComponent => props => {
    const {
        fetchInstrumentListAction,
        instrumentList,
        loading,
        error,
        errorMessage
    } = props;

    useEffect(() => {
        if (!instrumentList) {
            fetchInstrumentListAction();
        }
    }, []);

    return (
        <Loading loading={loading}>
            <CheckErrors error={error} errorMessage={errorMessage}>
                <WrappedComponent {...props} instrumentList={instrumentList} />
            </CheckErrors>
        </Loading>
    );
};

export default withData;
