import { takeEvery, call, all, put } from 'redux-saga/effects';
import { fetch, ERROR_UNAUTHORIZED } from '../../common/utils';
import { FETCH_DATA, FETCH_ERROR } from './constants';
import {
    fetchSuccessAction,
    fetchStartAction,
    fetchErrorAction
} from './actions';

import { openSnackbarAction } from '../snackbar/actions';

function* fetchData({ payload, meta, passToResponse }) {
    const namespace = meta.namespace;
    try {
        yield put(fetchStartAction(namespace, payload));
        const response = yield call(fetch, payload.url, payload.options);
        yield put(fetchSuccessAction(namespace, response, passToResponse));
    } catch (err) {
        const errorAction = fetchErrorAction(namespace, err, passToResponse);
        yield put(errorAction);
    }
}

function* watchFetchData() {
    yield takeEvery(FETCH_DATA, fetchData);
}

function* handleError({ payload }) {
    const message = `errorId: ${payload.errorId}; message: ${payload.message}`;

    if (payload.message !== ERROR_UNAUTHORIZED) {
        yield put(
            openSnackbarAction({
                mode: 'error',
                message,
                messageId: Math.random()
            })
        );
    }
}

function* watchFetchError() {
    yield takeEvery(FETCH_ERROR, handleError);
}

export function* fetchSagas() {
    yield all([watchFetchData(), watchFetchError()]);
}
