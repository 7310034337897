import React from 'react';
import classnames from 'classnames';
import List from './List';
import Title from './Title';
import { hasElements } from '../../../common/utils';
import { customSort } from '../../../common/utils/table';
import { SORT_ORDER } from '../../../common/hooks/useSort';

const SubModule = ({ className, list }) => (
    <div className={className}>
        <List list={list} />
    </div>
);

const Module = ({
    module,
    moduleClassName,
    subModuleClassName,
    title,
    list,
    getSubModuleList,
    getSubModuleKey,
    subModuleSortCriteria
}) => (
    <>
        <Title className="level_2" title={title} />
        <div
            className={classnames(
                `${moduleClassName}-properties`,
                hasElements(module.modules) ? 'with-sub-module' : ''
            )}
        >
            <List list={list} />
        </div>
        {hasElements(module.modules) &&
            customSort(
                module.modules,
                subModuleSortCriteria,
                SORT_ORDER.ASCENDING
            ).map(subModule => (
                <SubModule
                    key={getSubModuleKey(subModule)}
                    className={subModuleClassName}
                    list={getSubModuleList(subModule)}
                />
            ))}
    </>
);

export default Module;
