import React from 'react';
import classnames from 'classnames';
import './selector.scss';

function renderOptions(options) {
    return options.map((prop, index) => {
        if (Array.isArray(prop)) {
            return (
                <optgroup key={index} label={prop.label}>
                    {renderOptions(prop)}
                </optgroup>
            );
        } else {
            return (
                <option key={prop.name} name={prop.name} value={prop.value}>
                    {prop.name}
                </option>
            );
        }
    });
}

const selectionValue = selectedOption => {
    if (selectedOption === null || selectedOption === undefined) {
        return '';
    }
    return selectedOption;
};

const renderOnlyWithOptions = props => render =>
    Array.isArray(props.options) ? render(props) : <></>;

export default props =>
    renderOnlyWithOptions(props)(
        ({
            label,
            className,
            options,
            selectedOption,
            onSelectionChange,
            readOnly
        }) => {
            const classes = classnames(className, readOnly ? 'Disabled' : null);

            return (
                <>
                    <label>{label}</label>
                    <select
                        className={classes}
                        onChange={onSelectionChange}
                        value={selectionValue(selectedOption)}
                        readOnly={readOnly}
                    >
                        {renderOptions(options)}
                    </select>
                </>
            );
        }
    );
