import React from 'react';
import { Form } from 'react-final-form';
import FormContent from './FormContent';
import { readFile } from '../../common/utils';
import { connect } from 'react-redux';
import Loading from '../loading/Loading';
import {
    uploadInstrumentAction,
    invalidFileSelectedAction,
    clearUploadInstrumentAction
} from '../../controllers/upload';
import { parseInputFile } from '../../common/utils/fileReader';
import MultiErrorMessage from './UploadStatusMessage';

import './upload.scss';

const CLEAR_STATE = {
    fileName: '',
    serialNumber: '',
    platformType: ''
};

class UploadForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = CLEAR_STATE;
    }

    onSubmit = () => {
        this.props.uploadInstrumentAction({
            fileName: this.state.fileName,
            fileContent: this.state.fileContent
        });

        this.setState(CLEAR_STATE);
    };

    onFileLoad = e => {
        const xmlContent = e.target.result;
        try {
            parseInputFile(xmlContent, ({ platformType, serialNumber }) => {
                this.setState({
                    platformType,
                    serialNumber,
                    fileContent: xmlContent,
                    message: '',
                    statusClass: ''
                });
            });
        } catch (ex) {
            this.setState({
                serialNumber: '',
                platformType: ''
            });
            this.props.invalidFileSelectedAction({
                message: 'Invalid file format selected.',
                statusClass: 'error'
            });
        }
    };

    fileChange = event => {
        const file = event.target.files[0];
        readFile(file, this.onFileLoad);
        this.setState({ fileName: file.name });
        this.props.clearUploadInstrumentAction();
    };

    renderFormContent = ({ handleSubmit, pristine, invalid }) => (
        <Loading loading={this.props.loading}>
            <FormContent
                handleSubmit={this.onSubmit}
                pristine={pristine}
                invalid={invalid}
                data={{
                    fileName: this.state.fileName,
                    serialNumber: this.state.serialNumber,
                    platformType: this.state.platformType
                }}
                fileChange={this.fileChange}
            />
            <MultiErrorMessage
                message={this.props.message}
                statusClass={this.props.statusClass}
                errors={this.props.errors}
            />
        </Loading>
    );

    render = () => (
        <Form onSubmit={this.onSubmit} subscription={{ submitted: true }}>
            {this.renderFormContent}
        </Form>
    );

    componentWillUnmount() {
        this.props.clearUploadInstrumentAction();
    }
}

export default connect(
    ({ upload: { loading = false, message, statusClass, errors } }) => ({
        loading,
        message,
        statusClass,
        errors
    }),
    {
        uploadInstrumentAction,
        invalidFileSelectedAction,
        clearUploadInstrumentAction
    }
)(UploadForm);
