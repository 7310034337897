import { takeEvery, takeLatest, all, put } from 'redux-saga/effects';
import { FETCH_USER_DETAILS } from './constants';
import { fetchDataAction, FETCH_ERROR } from '../fetch';
import { ERROR_UNAUTHORIZED, ERROR_FORBIDDEN } from '../../common/utils';
import history from '../../history';

function* fetchUserDetails() {
    yield put(
        fetchDataAction('user')('/api/v1/user-details', {
            method: 'get',
            params: {}
        })
    );
}

function* watchAuthStart() {
    yield takeEvery(FETCH_USER_DETAILS, fetchUserDetails);
}

function* redirectUser({ payload }) {
    switch (payload.message) {
        case ERROR_UNAUTHORIZED:
            history.replace('/invalid-credentials');
            break;
        case ERROR_FORBIDDEN:
            history.replace('/invalid-credentials');
            break;
        default:
    }
}

function* watchUnauthorized() {
    yield takeEvery(FETCH_ERROR, redirectUser);
}

export function* authSagas() {
    yield all([watchAuthStart(), watchUnauthorized()]);
}
