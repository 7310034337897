import React, { useState } from 'react';
import classnames from 'classnames';

import styles from './StorageBrowser.module.scss';
import { FILE, FOLDER } from '../../common/constants';
import FolderIconOpen from '../image/FolderIconOpen';
import FolderIcon from '../image/FolderIcon';
import FileIcon from '../image/FileIcon';

const TreeListItem = ({ listItem, ...rest }) => {
    const { directoryName, type, name, fullPath, shareName } = listItem;
    const { selectedListItem, clickHandler, foldersOpenStatus = {} } = rest;
    const [children, setChildren] = useState([]);

    const processChildItems = items => {
        let folderItems = [];
        let fileItems = [];

        if (items.directories) {
            folderItems = items.directories.map(({ name }) => ({
                name,
                directoryName: directoryName + '/' + name,
                fullPath: shareName + directoryName + '/' + name,
                type: FOLDER,
                shareName: shareName
            }));
        }

        if (items.files) {
            fileItems = items.files.map(({ name }) => ({
                name,
                directoryName: directoryName,
                fullPath: shareName + directoryName + '/' + name,
                type: FILE,
                shareName: shareName
            }));
        }

        setChildren([...folderItems, ...fileItems]);
    };

    const handleClick = () => {
        clickHandler(listItem, processChildItems);
    };

    const isFolderOpened = () => !!foldersOpenStatus[fullPath];
    const setItemStyles = () =>
        classnames(
            styles.ListItem,
            fullPath === selectedListItem ? styles.Selected : null,
            isFolderOpened() ? styles.Open : null
        );

    const renderIcon = () => {
        let IconComponent = null;
        if (type === FILE) {
            IconComponent = FileIcon;
        }

        if (type === FOLDER) {
            IconComponent = foldersOpenStatus[fullPath]
                ? FolderIconOpen
                : FolderIcon;
        }

        return (
            <IconComponent
                className={styles.ListItemImage}
                title={listItem.type}
            />
        );
    };

    return (
        <li data-test="treeListItem">
            <div
                data-test="treeListItemRow"
                id={fullPath}
                className={setItemStyles()}
                onClick={handleClick}
            >
                {renderIcon()}
                <span data-test="treeListItemName">{name}</span>
            </div>
            {type === FOLDER ? (
                <ul data-test="treeList" className={styles.List}>
                    {children.map(item => (
                        <TreeListItem
                            key={item.fullPath}
                            listItem={item}
                            {...rest}
                        />
                    ))}
                </ul>
            ) : null}
        </li>
    );
};

export default TreeListItem;
