import React from 'react';
import { string } from 'prop-types';

const Image = ({ source, altText, look }) => (
    <img src={source} alt={altText} className={look} />
);

Image.propTypes = {
    source: string.isRequired,
    look: string,
    alt: string
};

Image.defaultProps = {
    alt: ''
};

export default Image;
