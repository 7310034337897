import React from 'react';
import ReactDOMServer from 'react-dom/server';

import HardwareSection from './HardwareSection';
import LogbookSection from './LogbookSection';
import ModKitsSection from './ModKitsSection';
import HotFixSection from './HotFixSection';
import SoftwareSection from './SoftwareSection';
import UpdatesSection from './UpdatesSection';
import html from './revision.html';

const Instrument = ({ data, options }) => {
    debugger;
    return (
        <div className="wrapper">
            <header>{data.id}</header>
            {options.hardware && <HardwareSection data={data} />}
            {options.software && <SoftwareSection software={data.software} />}
            {options.modKits && <ModKitsSection modKits={data.modKits} />}
            {options.hotfixes && <HotFixSection hotfixes={data.hotfixes} />}
            {options.updates && (
                <UpdatesSection notifications={data.notifications} />
            )}
            {options.logbook && <LogbookSection logbook={data.logbook} />}
        </div>
    );
};

export const getRevisionInHtmlFormat = (data, options) => {
    const body = ReactDOMServer.renderToString(
        <Instrument data={data} options={options} />
    );

    return html
        .replace(
            '{{title}}',
            `${data.id}-${data.currentRevision.modificationTime}`
        )
        .replace('{{body}}', body);
};
