import React from 'react';
import Button from '../button/Button';
import { withRouter } from 'react-router';

const MenuLink = ({ menuData: { to, title, isFullPath }, location }) => {
    let selected = false;
    if (typeof location === 'object' && typeof location.pathname === 'string') {
        selected = isFullPath
            ? location.pathname === to
            : location.pathname.slice(0, to.length) === to;
    }
    return (
        <Button to={to} type="button" selected={selected}>
            {title}
        </Button>
    );
};

export default withRouter(MenuLink);
