import { connect } from 'react-redux';
import { compose } from 'redux';
import { fetchInstrumentListAction } from '../../../controllers/query/actions';
import {
    toggleMultipleInstrumentsSelectionAction,
    toggleSingleInstrumentSelectionAction
} from '../../../controllers/instrument/actions';
import { getOpenModalWindowAction } from '../../../controllers/modal';
import { FETCH_ERROR } from '../../../controllers/fetch';
import withData from './withData';
import ResultsWithActions from './ResultsWithActions';
import AddTagForm from '../operations/AddTagForm';
import AzureStorageVisualizer from '../../storageBrowser/StorageBrowser';
import { getActionsThatUserCanExecute } from '../../../common/utils/roles';

const getActions = state => {
    const actions = [
        {
            title: 'Assign Tag',
            component: AddTagForm,
            disabled: state.assignTagToInstruments.loading,
            permission: 'instrument:assignTag'
        },
        {
            title: 'Trigger Software Update',
            component: AzureStorageVisualizer,
            disabled: state.triggerSoftwareUpdate.loading,
            permission: 'instrument:triggerSoftwareUpdate'
        }
    ];

    return getActionsThatUserCanExecute(actions, state.roles);
};

export default compose(
    connect(
        ({
            instruments,
            selectedInstruments,
            assignTagToInstruments,
            triggerSoftwareUpdate,
            user: { roles }
        }) => ({
            instrumentList: instruments.instruments,
            loading: !!instruments.loading,
            error: instruments.fetchStatus === FETCH_ERROR,
            errorMessage: instruments.message,
            selectedInstruments,
            actions: getActions({
                assignTagToInstruments,
                triggerSoftwareUpdate,
                roles
            })
        }),
        {
            fetchInstrumentListAction,
            toggleSingleInstrumentSelectionAction,
            toggleMultipleInstrumentsSelectionAction,
            getOpenModalWindowAction
        }
    ),
    withData
)(ResultsWithActions);
