import React from 'react';
import Modules from '../../shared/Modules';

import {
    hasElements,
    convertDate,
    convertLocalDate
} from '../../../../common/utils';
import { customSort } from '../../../../common/utils/table';
import { SORT_ORDER } from '../../../../common/hooks/useSort';
import { getSerialNumberKey } from '../../../../common/utils/details';

import styles from '../../../details/details.module.scss';

const renderModuleRow = element => {
    const serialNumberKey = getSerialNumberKey(element);

    var endDate = '';

    var icon = '';

    if (element.category == 'Arm') {
        icon =
            '<span class="details_MaintenanceStatus__18vyi UNKNOWN" style="width:16px;border:none;margin-top: -4px" title="Status UNKNOWN."></span>';
    }

    if (element.armMaintenanceModule != undefined) {
        endDate = element.armMaintenanceModule.endDate;

        var convertedDate = new Date(endDate);
        var now = new Date();

        if (now > convertedDate) {
            icon =
                '<span class="MaintenanceStatus MAINTENANCE_EXPIRED" style="width:16px;border:none;margin-top: -4px" title="Status MAINTENANCE EXPIRED. Valid until ' +
                convertLocalDate(convertedDate) +
                '"></span>';
        }

        if (convertedDate > now) {
            icon =
                '<span class="MaintenanceStatus MAINTAINED" style="width:16px;border:none;margin-top: -4px" title="Status MAINTAINED. Valid until ' +
                convertLocalDate(convertedDate) +
                '"></span>';
        }
    }

    return (
        <>
            <span>{element.name}</span>
            <span
                Style="width: 90px"
                dangerouslySetInnerHTML={{ __html: icon }}
            />
            <span>Cat: {element.category}</span>
            <span>SN: {element.properties[serialNumberKey]}</span>
        </>
    );
};

const renderSubModuleRow = (category, element) => (
    <>
        <span>
            {category}: {element.properties.ChannelNumber}
        </span>
        <span>SN: {element.properties.SerialNumberDiluter}</span>
    </>
);

export default props => {
    const {
        modules,
        parentPage,
        moduleIdentifier,
        subModuleIdentifier
    } = props;

    const modulesData = modules
        ? customSort(modules, 'name', SORT_ORDER.ASCENDING)
        : [];

    if (modulesData.length === 0) return [];

    const content = [
        <div label="Modules" key="modules">
            <Modules
                modules={modulesData}
                selectedModuleId={moduleIdentifier}
                linkTo={parentPage}
                header="Modules"
                idProperty="moduleId"
                render={renderModuleRow}
            />
        </div>
    ];

    const selectedModule = modulesData.find(
        m => m.moduleId === moduleIdentifier
    );

    const subModules =
        selectedModule && hasElements(selectedModule.modules)
            ? customSort(
                  selectedModule.modules,
                  'properties.ChannelNumber',
                  SORT_ORDER.ASCENDING
              )
            : [];

    if (hasElements(subModules)) {
        const subModuleCategory = subModules[0].category;

        content.push(
            <div label="Channels" key="chennels">
                <Modules
                    modules={subModules}
                    selectedModuleId={subModuleIdentifier}
                    linkTo={`${parentPage}/${moduleIdentifier}`}
                    header={subModuleCategory}
                    idProperty="moduleId"
                    render={element =>
                        renderSubModuleRow(subModuleCategory, element)
                    }
                />
            </div>
        );
    }

    return content;
};
