import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ModKitInfo from './ModKitInfo';
import InstrumentsByModKitList from './InstrumentsByModKitList';
import { fetchInstrumentsByModKitAction } from '../../controllers/modkit/actions';
import { applyModKitAction, undoModKitAction } from '../../controllers/modkit';
import Loading from '../loading/Loading';

const InstrumentsByModKit = props => {
    const {
        fetchInstrumentsByModKitAction,
        applyModKitAction,
        undoModKitAction,
        instruments,
        modkits,
        selectedId,
        loading
    } = props;

    const getCurrentModKit = () =>
        selectedId ? modkits.find(modkit => modkit.id === selectedId) : null;

    useEffect(
        () => {
            const currentModKit = getCurrentModKit();
            if (currentModKit) {
                fetchInstrumentsByModKitAction(currentModKit);
            }
        },
        [selectedId]
    );

    const currentModKit = getCurrentModKit();

    return currentModKit ? (
        <Loading loading={loading}>
            <div className="RightSideVerticalContainer">
                <ModKitInfo {...currentModKit} />
                <InstrumentsByModKitList
                    instruments={instruments}
                    loading={loading}
                    applyModKitAction={applyModKitAction}
                    undoModKitAction={undoModKitAction}
                    modkit={currentModKit}
                />
            </div>
        </Loading>
    ) : (
        <p className="Empty">Please select a ModKit from the left menu.</p>
    );
};

InstrumentsByModKit.propTypes = {
    selectedId: PropTypes.string,
    modkits: PropTypes.array,
    instruments: PropTypes.array,
    loading: PropTypes.bool,
    fetchInstrumentsByModKitAction: PropTypes.func,
    applyModKitAction: PropTypes.func,
    undoModKitAction: PropTypes.func
};

export default connect(
    ({
        instrumentsByModKit: { instruments, loading },
        modkits: { items: modkits }
    }) => ({
        instruments: Array.isArray(instruments) ? instruments : [],
        loading: !!loading,
        modkits
    }),
    {
        fetchInstrumentsByModKitAction,
        applyModKitAction,
        undoModKitAction
    }
)(InstrumentsByModKit);
