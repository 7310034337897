import React from 'react';
import StatusMessage from './StatusMessage';
import { PropTypes } from 'prop-types';

const CheckErrors = ({ children, error, errorMessage }) =>
    error ? (
        <StatusMessage message={errorMessage} statusClass="error" />
    ) : (
        <React.Fragment>{children}</React.Fragment>
    );

CheckErrors.propTypes = {
    error: PropTypes.bool,
    errorMessage: PropTypes.string
};

export default CheckErrors;
