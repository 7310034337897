import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import { deleteInstrumentAction } from '../../../controllers/instrument/actions';
import Confirmation from '../../confirmation/Confirmation';

const DeleteInstrumentForm = props => {
    const { deleteInstrumentAction, name, instrumentId } = props;

    const message = `You are about to delete the instrument <span class="ConfirmMessageHighlight">${name}</span> from the system.`;

    return (
        <Confirmation
            onConfirm={() => deleteInstrumentAction(instrumentId)}
            message={parse(message)}
        />
    );
};

DeleteInstrumentForm.propTypes = {
    name: PropTypes.string.isRequired,
    instrumentId: PropTypes.string.isRequired,
    deleteTagAction: PropTypes.func
};

export default connect(
    null,
    {
        deleteInstrumentAction
    }
)(DeleteInstrumentForm);
