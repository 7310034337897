import React from 'react';
import { connect } from 'react-redux';

import PropertyList from '../../shared/PropertyList';
import { Table, COLUMN_TYPE } from '../../../table';
import {
    convertDate,
    doesUserHavePermission,
    formatInstrumentHotFixStatus
} from '../../../../common/utils';
import {
    isApplied,
    applyHotFixAction,
    undoHotFixAction
} from '../../../../controllers/hotfix';

const getTableColumns = styles => [
    {
        type: COLUMN_TYPE.TEXT,
        key: 'documentNumber',
        displayName: 'Doc Number',
        hasSorting: true,
        hasFiltering: true,
        width: '50%'
    },
    {
        type: COLUMN_TYPE.TEXT,
        key: 'name',
        displayName: 'Description',
        hasSorting: true,
        hasFiltering: true,
        width: '50%'
    },
    {
        type: COLUMN_TYPE.TEXT,
        key: 'status',
        displayName: 'Status',
        hasSorting: true,
        hasFiltering: true,
        width: 120,
        render: status => {
            return (
                <span
                    className={
                        styles[
                            formatInstrumentHotFixStatus(status).replace(
                                / /gi,
                                ''
                            )
                        ]
                    }
                >
                    {formatInstrumentHotFixStatus(status)}
                </span>
            );
        }
    },
    {
        type: COLUMN_TYPE.DATE,
        key: 'modified.at',
        displayName: 'Modified',
        hasSorting: true,
        hasFiltering: true,
        width: 220
    }
];

const getActionColumn = (
    isLatestVersion,
    instrumentId,
    undoHotFixAction,
    applyHotFixAction
) => ({
    type: COLUMN_TYPE.CONFIRMATION,
    key: 'id',
    displayName: 'Action',
    width: 90,
    getButtonLook: ({ status }) =>
        isApplied(status) ? 'Secondary' : 'Primary',
    getText: ({ status }) => (isApplied(status) ? 'Revoke' : 'Apply'),
    confirmation: {
        key: 'status',
        confirmInputName: 'comment',
        onAction: ({ id, status }) => formData => {
            const action = isApplied(status)
                ? undoHotFixAction
                : applyHotFixAction;

            action(
                {
                    instrumentId,
                    id,
                    comment: formData['comment']
                },
                true
            );
        },
        disabled: ({ status, active }) => {
            if (!isLatestVersion) return true;
            return !active && !isApplied(status);
        },
        getMessage: ({ status, documentNumber }) => {
            return isApplied(status)
                ? `Do you really want to revoke hotfix "${documentNumber}" from this instrument?`
                : `Do you really want to apply hotfix "${documentNumber}" to this instrument?`;
        }
    }
});

const HotFixsTab = props => {
    const {
        hotfixes,
        instrumentId,
        isLatestVersion,
        styles,
        roles,
        undoHotFixAction,
        applyHotFixAction
    } = props;

    const columns = getTableColumns(styles);

    if (doesUserHavePermission(roles, 'instrumentDetails:changeHotFixStatus')) {
        columns.push(
            getActionColumn(
                isLatestVersion,
                instrumentId,
                undoHotFixAction,
                applyHotFixAction
            )
        );
    }

    return (
        <Table
            id="id"
            initialSortColumn="status"
            data={hotfixes}
            columns={columns}
            renderExtraInfo={row => {
                let extraInfo = {};

                if (row.modified) {
                    extraInfo['Modified By'] = row.modified.username;
                    extraInfo['Modified At'] = convertDate(row.modified.at);
                }

                if (row.comment) {
                    extraInfo['Comment'] = row.comment;
                }

                let hasExtraInfo = Object.entries(extraInfo).length !== 0;

                return (
                    hasExtraInfo && (
                        <div className={styles.ExtraInfo}>
                            <PropertyList data={extraInfo} sorting={false} />
                        </div>
                    )
                );
            }}
        />
    );
};

const ConnectedHotFixesTab = connect(
    ({ user }) => ({ roles: user.roles }),
    {
        applyHotFixAction,
        undoHotFixAction
    }
)(HotFixsTab);

export default props => <ConnectedHotFixesTab {...props} />;
