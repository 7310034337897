import { HOTFIX_STATUS, HOTFIX_LIST_IS_UPDATING } from '../constants';
const STATUSES = {
    retireHotFix: {
        notification: 'Hotfix has been retired successfully!',
        error: 'Hotfix could not be retired, please try again!'
    },
    deleteHotFix: {
        notification: 'Hotfix has been deleted successfully!',
        error: 'Hotfix could not be deleted, please try again!'
    }
};
export const hotfixStatusReducer = (
    state = {},
    { type, namespace, id, status, message }
) => {
    switch (type) {
        case HOTFIX_STATUS:
            const messages = STATUSES[namespace];
            if (messages) {
                return {
                    ...state,
                    [id]: {
                        message: message || messages[status],
                        error: status === 'error',
                        statusClass: status
                    }
                };
            }

            return state;
        case HOTFIX_LIST_IS_UPDATING:
            return {};
        default:
            return state;
    }
};
