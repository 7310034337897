import React from 'react';
import PropTypes from 'prop-types';
import styles from './Map.module.scss';

const LocationsList = ({ locations, handleSelect, isOpen }) =>
    isOpen && locations.length > 0 ? (
        <ul className={styles.List}>
            {locations.map(location => (
                <li
                    className={styles.ListElement}
                    key={location.address.label}
                    onClick={() => handleSelect(location)}
                >
                    {location.address.label}
                </li>
            ))}
        </ul>
    ) : null;

LocationsList.propTypes = {
    locations: PropTypes.arrayOf(PropTypes.object).isRequired,
    handleSelect: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired
};

export default LocationsList;
