import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import FilteredList from '../../container/FilteredList';
import PageWithLeftSidebar from '../../container/PageWithLeftSidebar';
import { fetchUpdatesListAction } from '../../controllers/update/actions';
import { getOpenModalWindowAction } from '../../controllers/modal/actions';
import { getUpdateNameFromPath, UPDATE_TYPE } from '../../common/utils/updates';

import './update.scss';
import RetireUpdateForm from './RetireUpdateForm';
import RetireIcon from '../image/RetireIcon';

const UpdatesManagementPage = props => {
    const {
        selectedId,
        updates,
        loading,
        fetchUpdatesListAction,
        getOpenModalWindowAction,
        children
    } = props;

    useEffect(() => {
        fetchUpdatesListAction();
    }, []);

    const transformUpdatesList = updates => {
        if (!updates) return [];
        return updates.map(u => ({
            id: u.id,
            className:
                UPDATE_TYPE[u.type] === UPDATE_TYPE.UPDATE_CONFIGURATION &&
                'FilteredListItemSpecial',
            name: getUpdateNameFromPath(u.payload.PATH)
        }));
    };

    const onRetire = update => {
        getOpenModalWindowAction(RetireUpdateForm, 'Retire update', {
            name: update.name,
            id: update.id
        });
    };

    return (
        <PageWithLeftSidebar
            loading={loading}
            renderSidebarContent={() => (
                <FilteredList
                    label="updates"
                    items={transformUpdatesList(updates)}
                    selectedId={selectedId}
                    linkTo="/updates"
                    actionText="Retire"
                    actionIcon={RetireIcon}
                    onItemAction={onRetire}
                />
            )}
            renderMainContent={() => children}
        />
    );
};

UpdatesManagementPage.propTypes = {
    selectedId: PropTypes.string,
    updates: PropTypes.array,
    loading: PropTypes.bool,
    collapseSidebar: PropTypes.bool
};

export default connect(
    ({ updates: { items: updates, loading } }) => ({
        updates,
        loading: !!loading
    }),
    {
        fetchUpdatesListAction,
        getOpenModalWindowAction
    }
)(UpdatesManagementPage);
