import {
    FETCH_SUCCESS,
    FETCH_ERROR,
    fetchToStatusClass
} from '../fetch/constants';

import {
    CLEAR_CREATE_TAG,
    CLEAR_DELETE_TAG,
    CLEAR_ASSIGN_TAG,
    FETCH_INSTRUMENTS_BY_TAG,
    CLEAR_INSTRUMENTS_BY_TAG,
    ASSIGN_TAG_TO_INSTRUMENTS,
    UNASSIGN_TAG_FROM_INSTRUMENTS,
    CREATE_TAG,
    DELETE_TAG
} from './constants';

const createTagStatusMessage = (payload, fetchStatus) => {
    let message = '';
    if (fetchStatus === FETCH_ERROR) {
        if (payload && payload.message) message = payload.message;
        else message = 'Error creating tag.';
    } else if (fetchStatus === FETCH_SUCCESS) {
        message = `Created tag with name "${payload.name}"`;
    }
    return message;
};

const deleteTagStatusMessage = (payload, fetchStatus) => {
    let message = '';
    if (fetchStatus === FETCH_ERROR) {
        if (payload && payload.message) message = payload.message;
        else message = 'Error deleting tag.';
    } else if (fetchStatus === FETCH_SUCCESS) {
        message = `Tag was deleted successfully.`;
    }
    return message;
};

const assignTagStatusMessage = (payload, fetchStatus) => {
    let message = '';
    if (fetchStatus === FETCH_ERROR) {
        if (payload && payload.message) message = payload.message;
        else message = `Error assigning tag to instruments.`;
    } else if (fetchStatus === FETCH_SUCCESS) {
        message = `Tag was assigned successfully.`;
    }
    return message;
};

const unassignTagStatusMessage = (payload, fetchStatus) => {
    let message = '';
    if (fetchStatus === FETCH_ERROR) {
        if (payload && payload.message) message = payload.message;
        else message = `Error removing tag from instruments.`;
    } else if (fetchStatus === FETCH_SUCCESS) {
        message = `Tag was successfully removed from instrument(s).`;
    }
    return message;
};

const sortTags = tags =>
    tags.sort(
        (t1, t2) => (t1.name.toLowerCase() < t2.name.toLowerCase() ? -1 : 1)
    );

export const orderTagListReducer = (state, { type, payload, meta }) => {
    if (type === FETCH_SUCCESS && meta.namespace === 'tags' && payload.items) {
        return { items: sortTags(payload.items) };
    }
    return state;
};

export const createTagReducer = (state, { type, payload, meta }) => {
    switch (type) {
        case FETCH_SUCCESS:
        case FETCH_ERROR: {
            if (meta.namespace === CREATE_TAG) {
                return {
                    ...state,
                    statusClass: fetchToStatusClass(type),
                    message: createTagStatusMessage(payload, type),
                    errors: payload.errors
                };
            }
            return state;
        }
        case CLEAR_CREATE_TAG:
            return {};
        default:
            return state;
    }
};

export const deleteTagReducer = (state, { type, payload, meta }) => {
    switch (type) {
        case FETCH_SUCCESS:
        case FETCH_ERROR: {
            if (meta.namespace === DELETE_TAG) {
                return {
                    ...state,
                    statusClass: fetchToStatusClass(type),
                    message: deleteTagStatusMessage(payload, type),
                    errors: payload.errors
                };
            }
            return state;
        }
        case CLEAR_DELETE_TAG:
            return {};
        default:
            return state;
    }
};

export const assignTagReducer = (state, { type, payload, meta }) => {
    switch (type) {
        case FETCH_SUCCESS:
        case FETCH_ERROR: {
            if (meta.namespace !== ASSIGN_TAG_TO_INSTRUMENTS) {
                return state;
            }
            return {
                statusClass: fetchToStatusClass(type),
                ...state,
                message: assignTagStatusMessage(payload, type),
                errors: payload.errors
            };
        }
        case CLEAR_ASSIGN_TAG:
            return {};
        default:
            return state;
    }
};

export const unassignTagReducer = (state, { type, payload, meta }) => {
    switch (type) {
        case FETCH_SUCCESS:
        case FETCH_ERROR: {
            if (meta.namespace !== UNASSIGN_TAG_FROM_INSTRUMENTS) {
                return state;
            }
            return {
                statusClass: fetchToStatusClass(type),
                ...state,
                message: unassignTagStatusMessage(payload, type),
                errors: payload.errors
            };
        }
        default:
            return state;
    }
};

export const instrumentsByTagReducer = (state, { type, payload, meta }) => {
    switch (type) {
        case FETCH_SUCCESS:
        case FETCH_ERROR: {
            if (meta.namespace === FETCH_INSTRUMENTS_BY_TAG) {
                return payload;
            }
            return state;
        }
        case CLEAR_INSTRUMENTS_BY_TAG:
            return {};
        default:
            return state;
    }
};
