import React from 'react';
import Main from '../main/Main';
import { Route as PublicRoute, Switch, withRouter } from 'react-router-dom';
import Route from './AuthorizedRoute';
import Loading from '../loading/Loading';
import Upload from '../upload/Upload';
import NoSuchPage from '../routes/Invalid';
import SimpleQuery from '../query/SimpleQuery';
import QueryLayout from '../query/Layout';
import QueryResults from '../query/results/QueryResults';
import InstrumentDetail from '../instrumentDetail/InstrumentDetail';
import InstrumentsByTag from '../tags/InstrumentsByTag';
import ModKitsWithData from '../modkit/ModKitsWithData';
import CreateModKit from '../modkit/CreateModKit';

import HotFixesWithData from '../hotfixes/HotFixesWithData';
import CreateHotFix from '../hotfixes/CreateHotFix';

import TagsPage from '../tags/Layout';
import UpdatesManagementPage from '../update/UpdatesManagementPage';
import InstrumentsByUpdate from '../update/InstrumentsByUpdate';
import { navigateTo } from '../../common/utils';
import InstrumentsByModKit from '../modkit/InstrumentsByModKit';
import InstrumentsByHotFix from '../hotfixes/InstrumentsByHotFix';
import Map from '../map/Map';
import SupportDetails from '../remotesupports/SupportDetails';
import SupportList from '../remotesupports/SupportList';

const Routes = () => {
    const toModkitApply = () => navigateTo('/modkits/apply');
    const toQueryPage = () => navigateTo('/query');
    const toTagsPage = tagId => navigateTo(`/tags/${tagId}`);

    return (
        <Main>
            <Switch>
                <Route path="/loading" exact component={Loading} />
                <Route path="/upload" exact component={Upload} />
                <Route path="/supports" component={SupportList} />
                <Route path="/support/:slug" component={SupportDetails} />
                <Route path="/map" exact component={Map} />

                <Route
                    path="/query"
                    exact
                    render={() => (
                        <QueryLayout
                            navigateOnSearch={toQueryPage}
                            persistFiltersAndSortingCriteria
                        >
                            <QueryResults />
                        </QueryLayout>
                    )}
                />
                <Route
                    path="/query/:id"
                    exact
                    render={({
                        match: {
                            params: { id }
                        }
                    }) => (
                        <QueryLayout
                            navigateOnSearch={toQueryPage}
                            collapseSearch
                        >
                            <InstrumentDetail
                                selectedId={id}
                                parentPage="/query"
                                onNavigateBack={toQueryPage}
                            />
                        </QueryLayout>
                    )}
                />
                <Route
                    path="/query/:id/:currentTab?/:moduleIdentifier?/:subModuleIdentifier?"
                    exact
                    render={({
                        match: {
                            params: {
                                id,
                                currentTab,
                                moduleIdentifier,
                                subModuleIdentifier
                            }
                        }
                    }) => (
                        <QueryLayout
                            navigateOnSearch={toQueryPage}
                            collapseSearch
                        >
                            <InstrumentDetail
                                selectedId={id}
                                currentTab={currentTab}
                                parentPage="/query"
                                moduleIdentifier={moduleIdentifier}
                                subModuleIdentifier={subModuleIdentifier}
                                onNavigateBack={toQueryPage}
                            />
                        </QueryLayout>
                    )}
                />

                <Route
                    path="/modkits/apply/:instrumentId/:currentTab?/:moduleIdentifier?/:subModuleIdentifier?"
                    exact
                    render={({
                        match: {
                            params: {
                                instrumentId,
                                currentTab,
                                moduleIdentifier,
                                subModuleIdentifier
                            }
                        }
                    }) => (
                        <SimpleQuery navigateOnSearch={toModkitApply}>
                            <InstrumentDetail
                                selectedId={instrumentId}
                                parentPage="/modkits/apply"
                                currentTab={currentTab}
                                onNavigateBack={toModkitApply}
                                moduleIdentifier={moduleIdentifier}
                                subModuleIdentifier={subModuleIdentifier}
                            />
                        </SimpleQuery>
                    )}
                />
                <Route path="/modkits/create" exact component={CreateModKit} />
                <Route
                    path="/modkits/:id?"
                    render={({
                        match: {
                            params: { id }
                        }
                    }) => (
                        <ModKitsWithData selectedId={id}>
                            <InstrumentsByModKit selectedId={id} />
                        </ModKitsWithData>
                    )}
                />

                <Route
                    path="/hotfixes/apply/:instrumentId/:currentTab?/:moduleIdentifier?/:subModuleIdentifier?"
                    exact
                    render={({
                        match: {
                            params: {
                                instrumentId,
                                currentTab,
                                moduleIdentifier,
                                subModuleIdentifier
                            }
                        }
                    }) => (
                        <SimpleQuery navigateOnSearch={toModkitApply}>
                            <InstrumentDetail
                                selectedId={instrumentId}
                                parentPage="/hotfixes/apply"
                                currentTab={currentTab}
                                onNavigateBack={toModkitApply}
                                moduleIdentifier={moduleIdentifier}
                                subModuleIdentifier={subModuleIdentifier}
                            />
                        </SimpleQuery>
                    )}
                />
                <Route path="/hotfixes/create" exact component={CreateHotFix} />
                <Route
                    path="/hotfixes/:id?"
                    render={({
                        match: {
                            params: { id }
                        }
                    }) => (
                        <HotFixesWithData selectedId={id}>
                            <InstrumentsByHotFix selectedId={id} />
                        </HotFixesWithData>
                    )}
                />

                <Route
                    path="/tags"
                    exact
                    render={() => (
                        <TagsPage
                            collapseSidebar={false}
                            persistFiltersAndSortingCriteria
                        >
                            <InstrumentsByTag />
                        </TagsPage>
                    )}
                />
                <Route
                    path="/tags/:id"
                    exact
                    render={({
                        match: {
                            params: { id }
                        }
                    }) => (
                        <TagsPage
                            selectedId={id}
                            collapseSidebar={false}
                            persistFiltersAndSortingCriteria
                        >
                            <InstrumentsByTag selectedId={id} />
                        </TagsPage>
                    )}
                />
                <Route
                    path="/tags/:id/:instrumentId/:currentTab?/:moduleIdentifier?/:subModuleIdentifier?"
                    exact
                    render={({
                        match: {
                            params: {
                                id,
                                instrumentId,
                                currentTab,
                                moduleIdentifier,
                                subModuleIdentifier
                            }
                        }
                    }) => (
                        <TagsPage selectedId={id} collapseSidebar>
                            <InstrumentDetail
                                selectedId={instrumentId}
                                onNavigateBack={() => toTagsPage(id)}
                                currentTab={currentTab}
                                parentPage={`/tags/${id}`}
                                moduleIdentifier={moduleIdentifier}
                                subModuleIdentifier={subModuleIdentifier}
                            />
                        </TagsPage>
                    )}
                />
                <Route
                    path="/updates/:id?"
                    exact
                    render={({
                        match: {
                            params: { id }
                        }
                    }) => (
                        <UpdatesManagementPage selectedId={id}>
                            <InstrumentsByUpdate selectedId={id} />
                        </UpdatesManagementPage>
                    )}
                />
                <Route path="/" exact />
                <PublicRoute component={NoSuchPage} />
            </Switch>
        </Main>
    );
};

export default withRouter(Routes);
