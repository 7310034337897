import { FETCH_SUCCESS } from '../../fetch/constants';
import { CHANGE_MODKIT_STATUS_FOR_INSTRUMENT } from '../../modkit/constants';
import { isApplied, isRevoked, isNotApplied } from '../../modkit/utils';
import { alphabeticSort } from '../../../common/utils/details';
export const instrumentModkitsOrderingReducer = (
    state,
    { type, payload, meta }
) => {
    switch (type) {
        case FETCH_SUCCESS:
            if (meta && meta.namespace === 'selectedInstrument') {
                const newState = {
                    ...state
                };

                const { modKits } = payload;

                const appliedModKits = modKits.filter(modkit =>
                    isApplied(modkit.status)
                );
                const revokedModKits = modKits.filter(modkit =>
                    isRevoked(modkit.status)
                );
                const notApplied = modKits.filter(modkit =>
                    isNotApplied(modkit.status)
                );

                notApplied.sort(alphabeticSort);
                revokedModKits.sort(alphabeticSort);
                appliedModKits.sort(alphabeticSort);

                newState.modKits = [
                    ...appliedModKits,
                    ...revokedModKits,
                    ...notApplied
                ];

                return newState;
            } else {
                return state;
            }
        case CHANGE_MODKIT_STATUS_FOR_INSTRUMENT: {
            const modKits = state.modKits
                ? state.modKits.filter(m => m.id !== payload.id)
                : [];
            return {
                ...state,
                modKits: [...modKits, payload]
            };
        }
        default:
            return state;
    }
};
