import React from 'react';
import PropTypes from 'prop-types';

import ListElement from '../../listElement/ListElement';
import PropertyList from './PropertyList';

import styles from '../details.module.scss';
import './Modules.scss';

const Modules = ({
    modules,
    selectedModuleId,
    idProperty,
    header,
    linkTo,
    sortProps,
    render
}) => {
    const getModuleIdentifier = (element, index) =>
        idProperty ? element[idProperty].toString() : index.toString();

    const to = (element, index) =>
        `${linkTo}/${getModuleIdentifier(element, index)}`;

    const selectedModule = idProperty
        ? modules.find(m => m[idProperty] === selectedModuleId)
        : modules[selectedModuleId];

    return (
        <>
            <section className={styles.Modules}>
                <h4>{header}</h4>
                <ul className="ModulesList">
                    {modules.map((element, index) => (
                        <ListElement
                            key={getModuleIdentifier(element, index)}
                            to={to(element, index)}
                            activeModule={
                                selectedModuleId ===
                                getModuleIdentifier(element, index)
                            }
                        >
                            {render && render(element)}
                        </ListElement>
                    ))}
                </ul>
            </section>
            {selectedModule && (
                <section className={styles.ModuleProps}>
                    <section className={styles.AllProperties}>
                        {selectedModule.properties && (
                            <PropertyList
                                data={selectedModule.properties}
                                label="Properties"
                                sorting={sortProps}
                            />
                        )}
                        {selectedModule.calculatedProperties && (
                            <PropertyList
                                data={selectedModule.calculatedProperties}
                                sorting={sortProps}
                            />
                        )}
                    </section>
                </section>
            )}
        </>
    );
};

Modules.propTypes = {
    modules: PropTypes.array,
    selectedModuleId: PropTypes.string,
    linkTo: PropTypes.string,
    header: PropTypes.string,
    idProperty: PropTypes.string,
    sortProps: PropTypes.bool,
    render: PropTypes.func
};

Modules.defaultProps = {
    sortProps: true
};

export default Modules;
