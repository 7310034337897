import {
    CHANGE_ADDRESS,
    CLOSE_LOCATIONS_LIST,
    ENABLE_ADDRESS_EDIT,
    OPEN_LOCATIONS_LIST,
    RESET_STATE,
    SAVE_LOCATION,
    SELECT_LOCATION,
    UPDATE_SEARCH_RESULTS
} from './constants';

export default (state, action) => {
    const {
        address,
        initialState,
        searchResults,
        selectedLocation,
        type
    } = action;

    switch (type) {
        case CHANGE_ADDRESS:
            return {
                ...state,
                address,
                isSaveButtonDisabled: true,
                searchResults: []
            };
        case CLOSE_LOCATIONS_LIST:
            return {
                ...state,
                isLocationsListOpen: false
            };
        case ENABLE_ADDRESS_EDIT:
            return {
                ...state,
                isEditAddressDisabled: false
            };
        case OPEN_LOCATIONS_LIST:
            return {
                ...state,
                isLocationsListOpen: true
            };
        case RESET_STATE:
            return { ...initialState };
        case SELECT_LOCATION:
            return {
                ...state,
                address,
                isSaveButtonDisabled: false,
                searchResults: [],
                selectedLocation
            };
        case SAVE_LOCATION:
            return {
                ...state,
                isEditAddressDisabled: true,
                isSaveButtonDisabled: true
            };
        case UPDATE_SEARCH_RESULTS:
            return {
                ...state,
                searchResults
            };
        default:
            return state;
    }
};
