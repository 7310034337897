import React from 'react';
import { capitalize } from '../../../common/utils';

const renderObjectsProperties = properties =>
    Object.keys(properties)
        .sort()
        .filter(
            property =>
                !(
                    properties[property] instanceof Object ||
                    Array.isArray(properties[property])
                )
        )
        .map(property => (
            <ListItem
                key={property}
                property={property}
                value={properties[property]}
            />
        ));

const ListItem = ({ property, value }) => (
    <li className="list-item">
        <span className="list-item-name">{capitalize(property)}:</span>
        <span className="list-item-value">
            {typeof value === 'boolean' ? String(value) : value}
        </span>
    </li>
);

const List = ({ list }) => (
    <ul className="list">
        {list.map(properties => renderObjectsProperties(properties))}
    </ul>
);

export default List;
