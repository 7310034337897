import React from 'react';
import StatusMessage from '../statusMessage/StatusMessage';
import './uploadStatusMessage.scss';

export default ({ message, statusClass, errors = [] }) => (
    <div className="UploadStatus">
        <StatusMessage statusClass={statusClass} message={message} />
        {errors.map((error, index) => (
            <section key={index}>
                <StatusMessage
                    statusClass="ErrorInfo"
                    message={error.message}
                />
                <p className="ErrorPath ErrorInfo">Path: {error.path}</p>
            </section>
        ))}
    </div>
);
