import React from 'react';
import { Auth0Provider } from '@auth0/auth0-react';
import { useFetch } from 'react-async';
import { getRedirectUri } from '../../common/utils/auth-helper';

const redirectUri = getRedirectUri();

const authConfig = () => {
    if (process.env.NODE_ENV === 'development') {
        const { auth0 } = require('../../configurations/auth0');
        return auth0;
    }

    const { data, error } = useFetch('configurations/auth0', {
        headers: { accept: 'application/json' }
    });

    if (error) return error.message;
    if (data) {
        data.skipRedirectCallback = window.location.href === redirectUri + '#/';
        data.redirectUri = redirectUri;
        return data;
    }
    return null;
};

const Auth0ProviderWithHistory = ({ children, history }) => {
    const onRedirectCallback = appState => {
        history.push(appState ? appState.returnTo : window.location.pathname);
    };

    const auth0Config = authConfig();
    if (auth0Config == null) {
        console.warn('no auth0Config');
        return null;
    }

    return (
        <Auth0Provider {...auth0Config} onRedirectCallback={onRedirectCallback}>
            {children}
        </Auth0Provider>
    );
};

export default Auth0ProviderWithHistory;
