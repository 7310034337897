import { takeLatest, put, all } from 'redux-saga/effects';
import { fetchDataAction } from '../fetch';
import { UPLOAD_INSTRUMENT } from './constants';

function* startUpload({ formData }) {
    yield put(
        fetchDataAction('upload')('/api/v1/instruments', {
            method: 'post',
            data: formData
        })
    );
}

function* watchStartUpload() {
    yield takeLatest(UPLOAD_INSTRUMENT, startUpload);
}

export function* uploadSagas() {
    yield all([watchStartUpload()]);
}
