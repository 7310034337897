import { put, takeEvery, takeLatest, all, call } from 'redux-saga/effects';
import { fetchDataAction } from '../fetch';
import {
    FETCH_INSTRUMENT_LIST,
    FETCH_INSTRUMENT_LIST_BY_SN,
    FETCH_QUERY_PARAMS_META_DATA
} from './constants';
import { instrumentListIsUpdatingAction } from './actions';
import { buildQuery } from '../../common/utils/graphQL';

function* fetchInstrumentsList(queryParams = {}) {
    const params = queryParams.queryParams;
    const queryBody = buildQuery(
        params,
        'payload{modules{moduleCategory, moduleId}} metadata{maintenance{required, endDate} armMaintenance{moduleId, endDate} location{countryName latitude longitude} currentRevision{modificationTime}}'
    );

    yield put(instrumentListIsUpdatingAction());

    yield put(
        fetchDataAction('instruments')('/api/v1/query/search', {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            data: queryBody
        })
    );
}

function* fetchInstrumentsBySN({ serialNumber }) {
    yield put(instrumentListIsUpdatingAction({ serialNumber }));

    const queryParams = [
        {
            type: 'STRING',
            field: 'SerialNumber',
            operator: 'EQ',
            resource: 'HARDWARE',
            values: [serialNumber]
        }
    ];
    const queryBody = buildQuery(
        queryParams,
        'metadata{maintenance{required, endDate} currentRevision{modificationTime}}'
    );

    yield put(
        fetchDataAction('applyModKit.instruments')('/api/v1/query/search', {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            data: queryBody
        })
    );
}

function* fetchQueryParamsMetaData() {
    yield put(
        fetchDataAction('queryParamsMetaData')('/api/v1/query/definitions')
    );

    yield call(fetchInstrumentsList);
}

function* watchQueryInstrumentList() {
    yield takeLatest(FETCH_INSTRUMENT_LIST, fetchInstrumentsList);
}

function* watchQueryInstrumentListFetchBySN() {
    yield takeEvery(FETCH_INSTRUMENT_LIST_BY_SN, fetchInstrumentsBySN);
}

function* watchQueryMetaData() {
    yield takeEvery(FETCH_QUERY_PARAMS_META_DATA, fetchQueryParamsMetaData);
}

export function* querySagas() {
    yield all([
        watchQueryInstrumentList(),
        watchQueryMetaData(),
        watchQueryInstrumentListFetchBySN()
    ]);
}
