import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Loading from '../loading/Loading';
import UpdateInfo from './UpdateInfo';
import InstrumentsByUpdateList from './InstrumentsByUpdateList';
import {
    fetchInstrumentsByUpdateAction,
    changeTriggeredUpdateStatusAction
} from '../../controllers/update/actions';
import { UPDATE_STATUS, getKeyByValue } from '../../common/utils/updates';

const InstrumentsByUpdate = props => {
    const {
        selectedId,
        updates,
        instruments,
        loading,
        changeTriggeredUpdateStatusAction,
        fetchInstrumentsByUpdateAction
    } = props;

    let currentUpdate = null;

    if (selectedId && updates) {
        currentUpdate = updates.find(
            u => u.id.toString() === selectedId.toString()
        );
    }

    useEffect(
        () => {
            selectedId && fetchInstrumentsByUpdateAction(selectedId);
        },
        [selectedId]
    );

    const onResendUpdate = instrumentId => () => {
        changeTriggeredUpdateStatusAction({
            updateId: selectedId,
            instrumentId: instrumentId,
            updateStatus: getKeyByValue(UPDATE_STATUS, UPDATE_STATUS.NEW)
        });
    };

    const onCancelUpdate = instrumentId => () => {
        changeTriggeredUpdateStatusAction({
            updateId: selectedId,
            instrumentId: instrumentId,
            updateStatus: getKeyByValue(UPDATE_STATUS, UPDATE_STATUS.CANCELLED)
        });
    };

    return (
        <>
            {!!selectedId && currentUpdate ? (
                <Loading loading={loading}>
                    <div className="RightSideVerticalContainer">
                        <UpdateInfo {...currentUpdate} />
                        <div className="InstrumentsContainer">
                            <InstrumentsByUpdateList
                                instruments={instruments}
                                onResendUpdate={onResendUpdate}
                                onCancelUpdate={onCancelUpdate}
                            />
                        </div>
                    </div>
                </Loading>
            ) : (
                <p className="Empty">
                    Please select an update from the left menu.
                </p>
            )}
        </>
    );
};

InstrumentsByUpdate.propTypes = {
    selectedId: PropTypes.string,
    updates: PropTypes.array,
    instruments: PropTypes.array,
    loading: PropTypes.bool,
    changeTriggeredUpdateStatusAction: PropTypes.func,
    fetchInstrumentsByUpdateAction: PropTypes.func
};

export default connect(
    ({
        instrumentsByUpdate: { loading, items: instruments },
        updates: { items: updates }
    }) => ({
        loading: !!loading,
        instruments: !!instruments ? instruments : [],
        updates
    }),
    {
        fetchInstrumentsByUpdateAction,
        changeTriggeredUpdateStatusAction
    }
)(InstrumentsByUpdate);
