import { FETCH_SUCCESS } from '../../fetch/constants';
import { alphabeticSort } from '../../../common/utils/details';

export const orderModKits = modKits => {
    const retiredModkits = modKits.filter(
        modkit => modkit.status === 'RETIRED'
    );

    const otherModkits = modKits.filter(modkit => modkit.status !== 'RETIRED');

    retiredModkits.sort(alphabeticSort);
    otherModkits.sort(alphabeticSort);

    return [...otherModkits, ...retiredModkits];
};

export const modkitOrderingReducer = (state, { type, payload, meta }) => {
    if (meta && meta.namespace === 'modkits' && type === FETCH_SUCCESS) {
        return { ...state, items: orderModKits(payload.items) };
    } else {
        return state;
    }
};
