import {
    FETCH_MODKIT_LIST,
    MODKIT_LIST_IS_UPDATING,
    CREATE_MODKIT,
    CLEAR_CREATE_MODKIT,
    APPLY_MODKIT,
    UNDO_MODKIT,
    CLEAR_CHANGE_MODKIT_STATUS,
    DELETE_MODKIT,
    RETIRE_MODKIT,
    MODKIT_STATUS,
    REFRESH_MODKIT_LIST,
    FETCH_TYPES,
    FETCH_INSTRUMENTS_BY_MODKIT,
    EXPORT_MODKITS_MATRIX
} from './constants';

export const fetchModKitListAction = () => ({
    type: FETCH_MODKIT_LIST
});

export const refreshModKitListAction = () => ({
    type: REFRESH_MODKIT_LIST
});

export const modkitListIsUpdatingAction = () => ({
    type: MODKIT_LIST_IS_UPDATING
});

export const createModKitAction = formData => ({
    type: CREATE_MODKIT,
    formData
});

export const applyModKitAction = (
    { id, comment, instrumentId },
    reloadInstrument
) => ({
    type: APPLY_MODKIT,
    id,
    comment,
    instrumentId,
    reloadInstrument
});

export const undoModKitAction = (
    { id, comment, instrumentId },
    reloadInstrument
) => ({
    type: UNDO_MODKIT,
    id,
    comment,
    instrumentId,
    reloadInstrument
});

export const deleteModKitAction = id => ({
    type: DELETE_MODKIT,
    id
});

export const retireModKitAction = id => ({
    type: RETIRE_MODKIT,
    id
});

export const displayModKitStatusAction = (id, namespace, message, status) => ({
    type: MODKIT_STATUS,
    id,
    namespace,
    message,
    status
});

export const clearCreateModKitAction = () => ({
    type: CLEAR_CREATE_MODKIT
});

export const clearChangeModKitStatusAction = () => ({
    type: CLEAR_CHANGE_MODKIT_STATUS
});

export const fetchTypesAction = () => ({
    type: FETCH_TYPES
});

export const fetchInstrumentsByModKitAction = modKit => ({
    type: FETCH_INSTRUMENTS_BY_MODKIT,
    id: modKit.id,
    platformType: modKit.platformType
});

export const exportModkitsMatrixAction = ({ platformType, modkits }) => ({
    type: EXPORT_MODKITS_MATRIX,
    platformType,
    modkits
});
