import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Button from '../button/Button';
import { getCloseModalWindowAction } from '../../controllers/modal';
import './confirmation.scss';

const Confirmation = props => {
    const { message, getCloseModalWindowAction, onConfirm, onCancel } = props;

    const onConfirmAction = () => {
        onConfirm();
        getCloseModalWindowAction();
    };

    const onCancelAction = () => {
        onCancel && onCancel();
        getCloseModalWindowAction();
    };

    return (
        <>
            <div className="ConfirmMessage">{message}</div>
            <Button
                className="ConfirmButton"
                id="buttonConfirm"
                look="Primary"
                onClick={onConfirmAction}
                width="FullWidth"
            >
                Confirm
            </Button>
            <Button
                className="ConfirmButton"
                id="buttonCancel"
                look="Neutral"
                onClick={onCancelAction}
                width="FullWidth"
            >
                Cancel
            </Button>
        </>
    );
};

Confirmation.propTypes = {
    onConfirm: PropTypes.func.isRequired,
    onCancel: PropTypes.func,
    message: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array,
        PropTypes.object
    ]),
    getCloseModalWindowAction: PropTypes.func
};

export default connect(
    null,
    { getCloseModalWindowAction }
)(Confirmation);
