import React from 'react';
import PropTypes from 'prop-types';
import { Map, Marker, TileLayer } from 'react-leaflet';
import { MAP_CONFIG, MAP_TEMPLATE } from '../../../../common/utils/mapConfig';
import styles from './Map.module.scss';

const LocalMap = ({ location, isLocationValid }) => {
    const latitude = isLocationValid
        ? location.latitude
        : MAP_CONFIG.LATITUDE_GLOBAL_MAP;
    const longitude = isLocationValid
        ? location.longitude
        : MAP_CONFIG.LONGITUDE_GLOBAL_MAP;
    const zoom = isLocationValid ? 12 : MAP_CONFIG.MIN_ZOOM;

    return (
        <Map
            className={styles.MapContainer}
            center={[latitude, longitude]}
            zoom={zoom}
            minZoom={MAP_CONFIG.MIN_ZOOM}
            maxZoom={MAP_CONFIG.MAX_ZOOM}
            maxBounds={MAP_CONFIG.MAX_BOUNDS}
        >
            <TileLayer
                noWrap={true}
                url={MAP_TEMPLATE.URL}
                attribution={MAP_TEMPLATE.ATTRIBUTION}
            />
            {isLocationValid && <Marker position={[latitude, longitude]} />}
        </Map>
    );
};

LocalMap.propTypes = {
    location: PropTypes.object.isRequired,
    isLocationValid: PropTypes.bool.isRequired
};

export default LocalMap;
