import React from 'react';
import ReactDOM from 'react-dom';
import AuthBouncer from './components/auth/AuthBouncer';
import { Provider } from 'react-redux';
import createStore from './store';
import { Router } from 'react-router-dom';
import Auth0ProviderWithHistory from './components/auth/Auth0ProviderWithHistory';
import history from './utils/history';

// Store setup
const store = createStore();

// App
ReactDOM.render(
    <Router history={history}>
        <Provider store={store}>
            <Auth0ProviderWithHistory history={history}>
                <AuthBouncer />
            </Auth0ProviderWithHistory>
        </Provider>
    </Router>,
    document.getElementById('root')
);
