import { REMOVE_UPDATE_FROM_STORE } from '../constants';

export const fetchUpdatesReducer = (state, { type, updateId }) => {
    switch (type) {
        case REMOVE_UPDATE_FROM_STORE: {
            return {
                ...state,
                items: state.items.filter(update => update.id !== updateId)
            };
        }
        default:
            return state;
    }
};
