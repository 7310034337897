import React from 'react';
import PropTypes from 'prop-types';
import styles from './modal.module.scss';

const Modal = props => {
    const {
        isOpen,
        title,
        onClose,
        render,
        component: ComponentName,
        ...other
    } = props;

    return isOpen ? (
        <>
            <div className={styles.ModalOverlay} />
            <div className={styles.ModalWindow}>
                <span className={styles.TecanDot} />
                <div className={styles.Close} onClick={onClose}>
                    &times;
                </div>
                <div className={styles.ModalWindowTitle}>{title}</div>
                <div className={styles.ModalWindowContent}>
                    {!!ComponentName ? (
                        <ComponentName {...other} />
                    ) : (
                        render && render()
                    )}
                </div>
            </div>
        </>
    ) : null;
};

Modal.propTypes = {
    isOpen: PropTypes.bool,
    title: PropTypes.string,
    props: PropTypes.any,
    component: PropTypes.any,
    onClose: PropTypes.func,
    render: PropTypes.func
};

export default Modal;
