import { PROMOTE_HOTFIX } from '../constants';

export const promoteHotFixReducer = (state, { type }) => {
    switch (type) {
        case PROMOTE_HOTFIX:
            return {};
        default:
            return state;
    }
};
