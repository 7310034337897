import { FETCH_SUCCESS } from '../../fetch';
import { ADD_LOGBOOK_ENTRY } from '../../logbook/constants';

export const chanegMaintenanceStatusReducer = (
    state,
    { type, meta, payload, passToResponse }
) => {
    if (
        type === FETCH_SUCCESS &&
        meta &&
        meta.namespace &&
        meta.namespace === ADD_LOGBOOK_ENTRY &&
        !!state.instruments
    ) {
        const { instrumentId } = passToResponse;
        const currentInstrument = state.instruments.find(
            item => item.id === instrumentId
        );

        if (currentInstrument) {
            currentInstrument.metadata.maintenance =
                payload.maintenance || currentInstrument.metadata.maintenance;

            return {
                ...state,
                instruments: [
                    ...state.instruments.filter(
                        item => item.id !== instrumentId
                    ),
                    currentInstrument
                ]
            };
        }
    }

    return state;
};
