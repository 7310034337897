import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import PageWithLeftSidebar from '../../container/PageWithLeftSidebar';
import TagList from './TagList';
import { getOpenModalWindowAction } from '../../controllers/modal';
import { fetchTagListAction } from '../../controllers/tags';
import { toggleMultipleInstrumentsSelectionAction } from '../../controllers/instrument/actions';

const TagsPageLayout = props => {
    const {
        selectedId,
        loading,
        tagList,
        collapseSidebar,
        children,
        persistFiltersAndSortingCriteria,
        fetchTagListAction,
        getOpenModalWindowAction,
        toggleMultipleInstrumentsSelectionAction
    } = props;

    const [filters, setFilters] = useState([]);
    const [sortingCriteria, setSortingCriteria] = useState({});

    useEffect(() => {
        fetchTagListAction();
    }, []);

    useEffect(
        () => {
            setFilters([]);
            setSortingCriteria({});
            return () => toggleMultipleInstrumentsSelectionAction([]);
        },
        [selectedId]
    );

    const mainContent = persistFiltersAndSortingCriteria
        ? React.cloneElement(children, {
              presetFilters: filters,
              presetSortingCriteria: sortingCriteria,
              updatePresetFilters: setFilters,
              updatePresetSortingCriteria: setSortingCriteria
          })
        : children;

    return (
        <PageWithLeftSidebar
            loading={loading}
            defaultCollapsed={collapseSidebar}
            renderSidebarContent={() => (
                <TagList
                    items={tagList}
                    selectedId={selectedId}
                    getOpenModalWindowAction={getOpenModalWindowAction}
                />
            )}
            renderMainContent={() => mainContent}
        />
    );
};

TagsPageLayout.propTypes = {
    selectedId: PropTypes.string,
    tagList: PropTypes.array,
    loading: PropTypes.bool,
    collapseSidebar: PropTypes.bool,
    persistFiltersAndSortingCriteria: PropTypes.bool
};

export default connect(
    ({ tags }) => ({
        tagList: tags.items,
        loading: !!tags.loading
    }),
    {
        fetchTagListAction,
        getOpenModalWindowAction,
        toggleMultipleInstrumentsSelectionAction
    }
)(TagsPageLayout);
