import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Button from '../components/button/Button';
import Downshift from 'downshift';

import './filteredlist.scss';

const renderItem = ({
    item,
    selectedId,
    to,
    action,
    actionIcon,
    actionText
}) => {
    const isSelected = selectedId
        ? selectedId.toString() === item.id.toString()
        : false;

    const ItemActionIcon = actionIcon;

    return (
        <div
            key={item.id}
            className={classnames(
                'FilteredListItem',
                item.className,
                isSelected && 'FilteredListItemSelected'
            )}
        >
            <Button
                look="Clear"
                className="FilteredListItemTitle"
                key={item.id}
                to={to}
            >
                {item.name}
            </Button>
            {isSelected && !!action && !!actionIcon ? (
                <span className="FilteredListItemAction" onClick={action}>
                    {actionIcon && (
                        <ItemActionIcon key={item.id} title={actionText} />
                    )}
                </span>
            ) : null}
        </div>
    );
};

const containsText = (target, destination) =>
    destination
        .trim()
        .toLowerCase()
        .indexOf(target.trim().toLowerCase()) > -1;

const FilteredList = props => {
    const {
        items = [],
        selectedId,
        actionIcon,
        actionText,
        onItemAction,
        label,
        linkTo,
        renderAdditionalFilters,
        renderActionMenu
    } = props;

    const [filterValue, setFilterValue] = useState('');

    const filterListItems = items =>
        items.filter(item => {
            return containsText(filterValue, item.name);
        });

    const handleItemAction = item => () => {
        return onItemAction && onItemAction(item);
    };

    const filteredItems = filterListItems(items);

    return (
        <Downshift
            stateReducer={(state, changes) => {
                if (changes.type === Downshift.stateChangeTypes.mouseUp) {
                    return {
                        ...changes,
                        isOpen: true,
                        inputValue: filterValue
                    };
                }
                return changes;
            }}
            onInputValueChange={inputValue => setFilterValue(inputValue)}
            itemToString={item =>
                item && item.hasOwnProperty('name') ? item.name : ''
            }
        >
            {({ getInputProps }) => (
                <div className="FilteredList">
                    <div className="FilteredListHeader">
                        <div className="FilteredListFilters">
                            {renderAdditionalFilters &&
                                renderAdditionalFilters()}
                            <div className="FilteredListFilter">
                                {label && (
                                    <div className="FilteredListInputLabel">
                                        {label}
                                    </div>
                                )}
                                <input
                                    placeholder="Search..."
                                    className="FilteredListInput"
                                    {...getInputProps()}
                                />
                            </div>
                        </div>
                        {renderActionMenu && (
                            <div className="FilteredListActions">
                                {renderActionMenu()}
                            </div>
                        )}
                    </div>
                    <div className="FilteredListContent">
                        {Array.isArray(filteredItems) &&
                        filteredItems.length > 0 ? (
                            filteredItems.map(item =>
                                renderItem({
                                    item,
                                    selectedId,
                                    to: linkTo ? `${linkTo}/${item.id}` : null,
                                    actionIcon,
                                    actionText,
                                    action: handleItemAction(item)
                                })
                            )
                        ) : (
                            <span>No items</span>
                        )}
                    </div>
                </div>
            )}
        </Downshift>
    );
};

FilteredList.propTypes = {
    items: PropTypes.array,
    selectedId: PropTypes.string,
    actionText: PropTypes.string,
    actionIcon: PropTypes.func,
    label: PropTypes.string,
    onItemAction: PropTypes.func,
    linkTo: PropTypes.string,
    renderAdditionalFilters: PropTypes.func,
    renderActionMenu: PropTypes.func
};

export default FilteredList;
