import React from 'react';
import { PropTypes } from 'prop-types';
import classnames from 'classnames';
import Drawer from '../components/drawer/Drawer';
import Loading from '../components/loading/Loading';

import './page.scss';

const PageWithLeftSidebar = props => {
    const {
        loading,
        defaultCollapsed,
        renderSidebarContent,
        renderMainContent,
        pageClassName
    } = props;

    return (
        <section className={classnames('PageLayout', pageClassName)}>
            <Loading loading={loading}>
                <Drawer
                    defaultCollapsed={defaultCollapsed}
                    expandedClassName="Expanded"
                    collapsedClassName="Collapsed"
                >
                    <div className="LeftSidebar">
                        <div className="LeftSidebarMargin" />
                        {renderSidebarContent && renderSidebarContent()}
                    </div>
                </Drawer>
                <div className="RightSideContainer">
                    {renderMainContent && renderMainContent()}
                </div>
            </Loading>
        </section>
    );
};

PageWithLeftSidebar.propTypes = {
    loading: PropTypes.bool.isRequired,
    defaultCollapsed: PropTypes.bool.isRequired,
    renderSidebarContent: PropTypes.func,
    renderMainContent: PropTypes.func
};

PageWithLeftSidebar.defaultProps = {
    defaultCollapsed: false
};

export default PageWithLeftSidebar;
