import {
    ADD_HOTFIX_TO_STORE,
    CHANGE_HOTFIX_STATUS,
    CHANGE_HOTFIX_STATUS_FOR_INSTRUMENT,
    REMOVE_HOTFIX_FROM_STORE
} from '../constants';
import { orderHotFixes } from './orderHotfixes';
import { FETCH_SUCCESS } from '../../fetch';
import { HOTFIX_STATUS } from '../../../common/utils/hotfixdetails';

export const modifyHotfixListReducer = (
    state,
    { type, id, payload, hotfix, operation, actionStatus }
) => {
    switch (type) {
        case ADD_HOTFIX_TO_STORE: {
            return {
                ...state,
                items: orderHotFixes(state.items.concat([hotfix]))
            };
        }
        case REMOVE_HOTFIX_FROM_STORE: {
            return {
                ...state,
                items: state.items.filter(hotfix => hotfix.id !== id)
            };
        }
        case CHANGE_HOTFIX_STATUS_FOR_INSTRUMENT: {
            if (operation === 'apply' && actionStatus === FETCH_SUCCESS) {
                return {
                    ...state,
                    items: state.items.map(hotfix => {
                        if (hotfix.id === id) {
                            return {
                                ...hotfix,
                                status: HOTFIX_STATUS.USED.toUpperCase()
                            };
                        }
                        return hotfix;
                    })
                };
            }

            return state;
        }
        case CHANGE_HOTFIX_STATUS: {
            return {
                ...state,
                items: state.items.map(hotfix => {
                    if (hotfix.id === id) {
                        return {
                            ...hotfix,
                            ...payload
                        };
                    }

                    return hotfix;
                })
            };
        }
        default:
            return state;
    }
};
