import {
    CREATE_NOTIFICATION,
    CLEAR_TRIGGER_SOFTWARE_UPDATE,
    ASSIGN_NOTIFICATION_TO_INSTRUMENTS
} from '../constants';
import {
    FETCH_ERROR,
    FETCH_START,
    FETCH_SUCCESS,
    fetchToStatusClass
} from '../../../controllers/fetch';

const MESSAGES = {
    [CREATE_NOTIFICATION]: {
        error: 'Error while selecting an update.',
        success: 'Update was selected successfully.'
    },
    [ASSIGN_NOTIFICATION_TO_INSTRUMENTS]: {
        error: 'Error triggering update  for instruments.',
        success: 'Update was triggered successfully.'
    }
};

export const triggerUpdateStatusMessage = (payload, fetchStatus, namespace) => {
    let message = '';
    if (fetchStatus === FETCH_ERROR) {
        message =
            payload && payload.message
                ? payload.message
                : MESSAGES[namespace].error;
    } else if (fetchStatus === FETCH_SUCCESS) {
        message = MESSAGES[namespace].success;
    }
    return message;
};

export const triggerSoftwareUpdateReducer = (
    state = {},
    { type, payload, meta }
) => {
    switch (type) {
        case FETCH_SUCCESS:
        case FETCH_ERROR: {
            if (
                meta.namespace === ASSIGN_NOTIFICATION_TO_INSTRUMENTS ||
                (meta.namespace === CREATE_NOTIFICATION && type === FETCH_ERROR)
            ) {
                return {
                    ...state,
                    statusClass: fetchToStatusClass(type),
                    message: triggerUpdateStatusMessage(
                        payload,
                        type,
                        meta.namespace
                    ),
                    errors: payload.errors,
                    loading: false
                };
            }
            return state;
        }
        case FETCH_START: {
            if (
                meta.namespace === ASSIGN_NOTIFICATION_TO_INSTRUMENTS ||
                meta.namespace === CREATE_NOTIFICATION
            ) {
                return {
                    ...state,
                    loading: true
                };
            } else {
                return state;
            }
        }
        case CLEAR_TRIGGER_SOFTWARE_UPDATE:
            return {};
        default:
            return state;
    }
};
