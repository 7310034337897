import React from 'react';
import { connect } from 'react-redux';

import AddLogbookEntryForm from './AddLogbookEntryForm';
import PropertyList from '../../shared/PropertyList';
import { Table, COLUMN_TYPE, LAYOUT } from '../../../table';
import { SORT_ORDER } from '../../../../common/hooks/useSort';
import SaveIcon from '../../../image/SaveIcon';
import { getOpenModalWindowAction } from '../../../../controllers/modal';
import { getValue, convertDate } from '../../../../common/utils';

export const renderModule = module => {
    if (!!module) {
        const sn = module.serialNumber
            ? `(${getValue(module.serialNumber)})`
            : '';

        return `${module.type}: ${getValue(module.name)}${sn}`;
    }

    return '';
};

const LogbookTab = props => {
    const {
        logbook,
        instrumentId,
        isLatestVersion,
        styles,
        getOpenModalWindowAction
    } = props;

    const logbookData = logbook || [];

    const columns = [
        {
            type: COLUMN_TYPE.TEXT,
            key: 'operation',
            displayName: 'Operation',
            hasSorting: true,
            hasFiltering: true,
            width: '200px'
        },
        {
            type: COLUMN_TYPE.TEXT,
            key: 'module',
            displayName: 'Module',
            render: renderModule
        },
        {
            type: COLUMN_TYPE.TEXT,
            key: 'maintenance',
            displayName: 'Maintained Until',
            render: maintenance => {
                return maintenance && maintenance.endDate ? (
                    <span>{convertDate(maintenance.endDate)}</span>
                ) : null;
            }
        },
        {
            type: COLUMN_TYPE.TEXT,
            key: 'attachment.originalName',
            displayName: 'Attachment',
            hasSorting: false,
            hasFiltering: true
        },
        {
            type: COLUMN_TYPE.TEXT,
            key: 'attachment',
            displayName: 'Download',
            hasSorting: false,
            hasFiltering: false,
            width: 70,
            className: styles.CENTER,
            render: attachment =>
                attachment && (
                    <a
                        href={attachment.downloadLink}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={styles.ClearLink}
                        onClick={e => e.stopPropagation()}
                    >
                        <SaveIcon className={styles.Icon} />
                    </a>
                )
        },
        {
            type: COLUMN_TYPE.TEXT,
            key: 'modified.username',
            displayName: 'Created By',
            hasSorting: true,
            hasFiltering: true,
            width: '200px'
        },
        {
            type: COLUMN_TYPE.DATE,
            key: 'modified.at',
            displayName: 'Created At',
            hasSorting: true,
            hasFiltering: true,
            width: '220px'
        }
    ];

    const logbookActions = [
        {
            title: 'Add Log',
            component: AddLogbookEntryForm,
            disabled: !isLatestVersion,
            props: { instrumentId },
            onAction: getOpenModalWindowAction
        }
    ];

    return (
        <>
            <Table
                id="id"
                layout={LAYOUT.AUTO}
                initialSortColumn="modified.at"
                initialSortOrder={SORT_ORDER.DESCENDING}
                data={logbookData}
                actions={logbookActions}
                columns={columns}
                renderExtraInfo={row => {
                    let extraInfo = {};

                    if (row.comment) {
                        extraInfo['Comment'] = row.comment;
                    }

                    return (
                        <div className={styles.ExtraInfo}>
                            <PropertyList data={extraInfo} sorting={false} />
                        </div>
                    );
                }}
            />
        </>
    );
};

const ConnectedLogbookTab = connect(
    null,
    {
        getOpenModalWindowAction
    }
)(LogbookTab);

export default props => <ConnectedLogbookTab {...props} />;
