import {
    TOGGLE_ALL_INSTRUMENTS_SELECTION,
    TOGGLE_INSTRUMENT_SELECTION,
    LOAD_INSTRUMENT_VERSIONS,
    CLEAR_INSTRUMENT_VERSIONS,
    DELETE_INSTRUMENT,
    REMOVE_INSTRUMENT_FROM_STORE,
    DOWNLOAD_QCDATA_XML,
    DOWNLOAD_QCDATA_ARCHIVE
} from './constants';

export const toggleMultipleInstrumentsSelectionAction = instruments => ({
    type: TOGGLE_ALL_INSTRUMENTS_SELECTION,
    instruments
});

export const toggleSingleInstrumentSelectionAction = instrument => ({
    type: TOGGLE_INSTRUMENT_SELECTION,
    instruments: [instrument]
});

export const startLoadInstrumentVersionsAction = instrumentId => ({
    type: LOAD_INSTRUMENT_VERSIONS,
    instrumentId
});

export const clearInstrumentVersionsAction = () => ({
    type: CLEAR_INSTRUMENT_VERSIONS
});

export const deleteInstrumentAction = instrumentId => ({
    type: DELETE_INSTRUMENT,
    instrumentId
});

export const removeInstrumentFromStoreAction = instrumentId => ({
    type: REMOVE_INSTRUMENT_FROM_STORE,
    instrumentId
});

export const downloadQcDataXmlAction = (id, fileName) => ({
    type: DOWNLOAD_QCDATA_XML,
    id,
    fileName
});

export const downloadQcDataArchiveAction = (instrumentId, fileName) => ({
    type: DOWNLOAD_QCDATA_ARCHIVE,
    instrumentId,
    fileName
});
