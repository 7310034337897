import React from 'react';
import Module from './Module';
import NoData from './NoData';
import Title from './Title';
import { convertDate, hasElements } from '../../../common/utils';
import { customSort } from '../../../common/utils/table';
import { SORT_ORDER } from '../../../common/hooks/useSort';

const renderSoftwarePackages = softwarePackages =>
    customSort(softwarePackages, 'name', SORT_ORDER.ASCENDING).map(
        ({ name, ...softwarePackage }) => (
            <Module
                key={`${name}-${softwarePackage.installationDate}`}
                module={softwarePackage}
                moduleClassName="software-package"
                subModuleClassName="software-package-module"
                title={name}
                list={[
                    {
                        ...softwarePackage,
                        installationDate: convertDate(
                            softwarePackage.installationDate
                        )
                    },
                    hasElements(softwarePackage.modules) ? { modules: '' } : {}
                ]}
                getSubModuleList={subModule => [subModule]}
                getSubModuleKey={subModule =>
                    `${subModule.name}-${subModule.version}`
                }
                subModuleSortCriteria="name"
            />
        )
    );

const SoftwareSection = ({ software = {} }) => {
    const lastModified =
        software.modified instanceof Object
            ? {
                  at: software.modified.at,
                  by: software.modified.username
              }
            : null;

    return (
        <>
            <Title
                className="level_1"
                title="Software"
                lastModified={lastModified}
            />
            {hasElements(software.softwarePackages) ? (
                renderSoftwarePackages(software.softwarePackages)
            ) : (
                <NoData />
            )}
        </>
    );
};

export default SoftwareSection;
