import {
    END_PERIOD,
    START_PERIOD,
    RESET,
    BETWEEN,
    BEFORE,
    AFTER,
    REPLACE
} from './constants';
import {
    isDateInTheAcceptableRange,
    getDateTimeFromIsoString,
    getShortDateTimeFromIsoString
} from './helpers';

export const initialDates = {
    [START_PERIOD]: {
        value: {
            dateString: '',
            dateObject: null
        },
        modifiers: {
            disabled: { before: null }
        }
    },
    [END_PERIOD]: {
        value: {
            dateString: '',
            dateObject: null
        },
        modifiers: {
            disabled: { after: null }
        }
    }
};

export const toCalendarValues = (values, calendarRange) => {
    if (!calendarRange) return initialDates;
    if (!Array.isArray(values)) return initialDates;

    if (calendarRange === BETWEEN && values.length !== 2) {
        return initialDates;
    }
    if (calendarRange !== BETWEEN && values.length !== 1) {
        return initialDates;
    }

    let startDate = null,
        endDate = null;

    switch (calendarRange) {
        case BEFORE: {
            endDate = values[0];
            break;
        }
        case AFTER: {
            startDate = values[0];
            break;
        }
        case BETWEEN: {
            startDate = values[0];
            endDate = values[1];
            break;
        }
        default:
            break;
    }

    return {
        [START_PERIOD]: {
            value: {
                dateString: getShortDateTimeFromIsoString(startDate),
                dateObject: getDateTimeFromIsoString(startDate)
            },
            modifiers: {
                disabled: {
                    after: getDateTimeFromIsoString(endDate)
                }
            }
        },
        [END_PERIOD]: {
            value: {
                dateString: getShortDateTimeFromIsoString(endDate),
                dateObject: getDateTimeFromIsoString(endDate)
            },
            modifiers: {
                disabled: {
                    before: getDateTimeFromIsoString(startDate)
                }
            }
        }
    };
};

const getDate = (state, dateObject, periodType) =>
    isDateInTheAcceptableRange(
        periodType === START_PERIOD
            ? state[periodType].value.dateObject
            : dateObject,
        periodType === END_PERIOD
            ? state[periodType].value.dateObject
            : dateObject
    )
        ? dateObject
        : null;

export const datesReducer = (state, { type, payload }) => {
    switch (type) {
        case START_PERIOD:
            return {
                ...state,
                [START_PERIOD]: {
                    ...state[START_PERIOD],
                    value: {
                        dateString: payload.dateString,
                        dateObject: getDate(
                            state,
                            payload.dateObject,
                            END_PERIOD
                        )
                    }
                },
                [END_PERIOD]: {
                    ...state[END_PERIOD],
                    modifiers: {
                        disabled: {
                            before: getDate(
                                state,
                                payload.dateObject,
                                END_PERIOD
                            )
                        }
                    }
                }
            };
        case END_PERIOD:
            return {
                ...state,
                [END_PERIOD]: {
                    ...state[END_PERIOD],
                    value: {
                        dateString: payload.dateString,
                        dateObject: getDate(
                            state,
                            payload.dateObject,
                            START_PERIOD
                        )
                    }
                },
                [START_PERIOD]: {
                    ...state[START_PERIOD],
                    modifiers: {
                        disabled: {
                            after: getDate(
                                state,
                                payload.dateObject,
                                START_PERIOD
                            )
                        }
                    }
                }
            };
        case RESET:
            return initialDates;
        case REPLACE:
            return toCalendarValues(payload.values, payload.operator);
        default:
            return state;
    }
};
