import React from 'react';
import OperatorAndValues from './OperatorAndValues';

const MetadataCriteria = props => {
    const {
        selected,
        operatorOptions,
        onOperatorSelected,
        onUserValueChanged
    } = props;

    return (
        <OperatorAndValues
            selected={selected}
            operatorOptions={operatorOptions}
            onOperatorSelected={onOperatorSelected}
            onUserValueChanged={onUserValueChanged}
        />
    );
};

export default MetadataCriteria;
