import { parseString, processors } from 'xml2js';

const createFileReader = () => new FileReader();

export const readFile = (file, onFileLoadCallback) => {
    const reader = createFileReader();
    reader.addEventListener('load', onFileLoadCallback);
    reader.readAsText(file);
};

const KNOWN_FILE_TYPES = {
    STANDARD_FLUENT: {
        name: 'STANDARD_FLUENT',
        matcher: data => !!data.Instrument && !!data.Instrument.Fluent,
        extractor: fileData => ({
            platformType: fileData.Instrument.Fluent[0].PlatformType[0],
            serialNumber: fileData.Instrument.Fluent[0].SerialNumber[0]
        })
    },
    STANDARD_MAGNIFLEX: {
        name: 'STANDARD_MAGNIFLEX',
        matcher: data => !!data.Instrument && !!data.Instrument.Magniflex,
        extractor: fileData => ({
            platformType: fileData.Instrument.Magniflex[0].PlatformType[0],
            serialNumber: fileData.Instrument.Magniflex[0].SerialNumber[0]
        })
    },
    S_AND_S: {
        name: 'S&S',
        matcher: data => validateOldConfigurationFormat(data),
        extractor: fileData => {
            const conf = fileData.ConfigurationDictionary.Configuration.find(
                x => !!x.InstrumentConfiguration
            );

            const serialNumber = fileData.ConfigurationDictionary.$.IdPrefix.split(
                ','
            ).slice(-1)[0];

            const result = {
                platformType: conf.InstrumentConfiguration[0].InstrumentType[0],
                serialNumber
            };

            return result;
        }
    }
};

const validateOldConfigurationFormat = data => {
    return (
        !!data.ConfigurationDictionary &&
        !!data.ConfigurationDictionary.$.IdPrefix &&
        data.ConfigurationDictionary.$.IdPrefix.indexOf(',') > 0
    );
};

/**
 * Probe by parsing the known basic parameters of supported file-types
 *
 * @param {String} fileData
 *
 * @throws exception when file-type is not known
 * @returns basic parameters of parsed file as an Object({platformType, serialNumber})
 */
const parseInputFileJSON = fileData => {
    for (let fileType of Object.values(KNOWN_FILE_TYPES)) {
        if (fileType.matcher(fileData)) {
            return fileType.extractor(fileData);
        }
    }

    throw Error('Unknown file-type');
};

export const parseInputFile = (xmlContent, cb) => {
    const processorOptions = { tagNameProcessors: [processors.stripPrefix] };

    return parseString(xmlContent, processorOptions, (err, result) => {
        if (err) {
            throw err;
        }
        if (Object.keys(result).length > 0) {
            const parsed = parseInputFileJSON(result);
            cb(parsed);
        }
    });
};
