import { OPEN_SNACKBAR, CLOSE_SNACKBAR } from './constants';

export const openSnackbarAction = ({ mode, message, messageId, onHide }) => ({
    type: OPEN_SNACKBAR,
    mode,
    message,
    messageId,
    onHide
});

export const closeSnackbarAction = () => ({
    type: CLOSE_SNACKBAR
});
