import { INSTRUMENT_MODKIT_STATUS } from '../../common/utils/details';

export const isApplied = status =>
    INSTRUMENT_MODKIT_STATUS[status] === INSTRUMENT_MODKIT_STATUS.APPLIED;
export const isRevoked = status =>
    INSTRUMENT_MODKIT_STATUS[status] === INSTRUMENT_MODKIT_STATUS.REVOKED;
export const isNotApplied = status =>
    INSTRUMENT_MODKIT_STATUS[status] === INSTRUMENT_MODKIT_STATUS.NOT_APPLIED;

export const buildInstrumentsModkitsMatrix = (
    platformType,
    instruments,
    modkits
) => {
    const modkitsByPlatformType = modkits.filter(
        m => m.platformType === platformType
    );

    const header = ['Platform Type', 'Size', 'Serial Number'];
    modkitsByPlatformType.forEach(m => header.push(`""="${m.id}"`)); //interpret numbers as string

    return instruments.reduce(
        (acc, inst) => {
            const instrumentData = [];

            instrumentData.push(platformType);
            instrumentData.push(`""="${inst.instrumentId.size}"`);
            instrumentData.push(`""="${inst.instrumentId.serialNumber}"`);

            const instrumentModKits = inst.metadata.modKits;

            modkitsByPlatformType.forEach(m => {
                const currentModKit = instrumentModKits.find(
                    modKit => modKit.id === m.id
                );
                instrumentData.push(
                    currentModKit ? currentModKit.status : 'NOT APPLIED'
                );
            });

            acc.push(instrumentData);
            return acc;
        },
        [header]
    );
};
