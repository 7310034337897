import React from 'react';

import { renderInstrumentMaintenance } from './renderInstrumentMaintenance';
import Table from '../../table/Table';
import { COLUMN_TYPE } from '../../table/constants';
import { navigateTo } from '../../../common/utils';
import { modifyInstrumentsByAddingMaintenanceStatus } from '../../../common/utils/details';
import history from '../../../history';
import { SORT_ORDER } from '../../../common/hooks/useSort';

import './results.scss';

const columns = [
    {
        type: COLUMN_TYPE.TEXT,
        key: 'instrumentId.type',
        displayName: 'Type',
        width: 150,
        hasSorting: true,
        hasFiltering: true
    },
    {
        type: COLUMN_TYPE.TEXT,
        key: 'maintenanceStatus',
        displayName: 'Device Status',
        width: 100,
        hasSorting: false,
        hasFiltering: false,
        render: (status, rowData) => renderInstrumentMaintenance(rowData)
    },
    {
        type: COLUMN_TYPE.TEXT,
        key: 'instrumentId.size',
        displayName: 'Size',
        width: 120,
        hasSorting: true,
        hasFiltering: true
    },
    {
        type: COLUMN_TYPE.TEXT,
        key: 'instrumentId.serialNumber',
        displayName: 'Serial Number',
        hasSorting: true,
        hasFiltering: true
    },
    {
        type: COLUMN_TYPE.DATE,
        key: 'metadata.currentRevision.modificationTime',
        displayName: 'Last Updated',
        width: 220,
        hasSorting: true,
        hasFiltering: true
    }
];

const ResultsWithActions = props => {
    const {
        instrumentList = [],
        selectedInstruments,
        toggleMultipleInstrumentsSelectionAction,
        toggleSingleInstrumentSelectionAction,
        getOpenModalWindowAction,
        actions,
        title,
        presetFilters,
        presetSortingCriteria,
        updatePresetFilters,
        updatePresetSortingCriteria
    } = props;

    return (
        <div className="ResultContainerWrapper">
            <div className="ResultContainer">
                <Table
                    initialSortColumn="metadata.currentRevision.modificationTime"
                    initialSortOrder={SORT_ORDER.DESCENDING}
                    columns={columns}
                    data={modifyInstrumentsByAddingMaintenanceStatus(
                        instrumentList
                    )}
                    actions={
                        Array.isArray(actions)
                            ? actions.map(action => ({
                                  ...action,
                                  onAction: getOpenModalWindowAction
                              }))
                            : []
                    }
                    isSelectable
                    selectedIDs={selectedInstruments}
                    toggleSingleSelection={instrumentId =>
                        toggleSingleInstrumentSelectionAction(instrumentId)
                    }
                    toggleMultipleSelection={instrumentIds =>
                        toggleMultipleInstrumentsSelectionAction(instrumentIds)
                    }
                    navigationTarget={id => () =>
                        navigateTo(`${history.location.pathname}/${id}`)}
                    title={title}
                    presetFilters={presetFilters}
                    presetSortingCriteria={presetSortingCriteria}
                    updatePresetFilters={updatePresetFilters}
                    updatePresetSortingCriteria={updatePresetSortingCriteria}
                />
            </div>
        </div>
    );
};

export default ResultsWithActions;
