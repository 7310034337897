export const TOGGLE_ALL_INSTRUMENTS_SELECTION =
    'selection.toggleAllInstruments';
export const TOGGLE_INSTRUMENT_SELECTION = 'selection.toggleInstrument';
export const LOAD_INSTRUMENT_VERSIONS = 'instrument.loadInstrumentVersions';
export const CLEAR_INSTRUMENT_VERSIONS = 'instrument.clearInstrumentVersions';
export const DELETE_INSTRUMENT = 'instrument.deleteInstrument';
export const REMOVE_INSTRUMENT_FROM_STORE =
    'instrument.removeInstrumentFromStore';
export const DOWNLOAD_QCDATA_XML = 'instrument.qcDataXml';
export const DOWNLOAD_QCDATA_ARCHIVE = 'instrument.qcDataArchive';
