import React from 'react';
import PropTypes from 'prop-types';
import DeleteIcon from '../image/DeleteIcon';
import DeleteTagForm from './DeleteTagForm';
import FilteredList from '../../container/FilteredList';

const TagList = props => {
    const { items, selectedId, getOpenModalWindowAction } = props;

    const onDelete = tag => {
        getOpenModalWindowAction(DeleteTagForm, 'Delete tag', {
            name: tag.name,
            id: tag.id
        });
    };

    return (
        <FilteredList
            items={items}
            selectedId={selectedId}
            onItemAction={onDelete}
            label="tags"
            linkTo="/tags"
            actionText="Delete"
            actionIcon={DeleteIcon}
        />
    );
};

TagList.propTypes = {
    items: PropTypes.array,
    selectedId: PropTypes.string,
    getOpenModalWindowAction: PropTypes.func
};

export default TagList;
