import React, { useEffect, useReducer, useRef, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { doesUserHavePermission, fetch } from '../../../../common/utils';
import Button from '../../../button/Button';
import LocalMap from './LocalMap';
import LocationsList from './LocationsList';
import RoleBasedAccess from '../../../roleBasedAccess/RoleBasedAccess';
import { isLocationValid } from '../../../../common/utils/verifyLocation';
import debounceFactory from '../../../../common/utils/debounce';
import { openSnackbarAction } from '../../../../controllers/snackbar/actions';
import { updateLocationAction } from '../../../../controllers/location/actions';
import reducer from './reducer';
import {
    changeAddressAction,
    closeLocationsListAction,
    enableAddressEditAction,
    openLocationsListAction,
    resetStateAction,
    saveLocationAction,
    selectLocationAction,
    updateSearchResultsAction
} from './actions';
import { GEOCODING_API } from '../../../../common/utils/mapConfig';
import styles from './Map.module.scss';

const debounce = debounceFactory();
const EDIT_LOCATION_PERMISSION = 'instrumentDetails:editInstrumentLocation';

const getQueryString = queryParameters =>
    Object.keys(queryParameters).reduce((queryString, key, index) => {
        const separator = index === 0 ? '?' : '&';

        return `${queryString}${separator}${key}=${encodeURIComponent(
            queryParameters[key]
        )}`;
    }, '');

const getUrl = (url, path, queryParameters) =>
    `${url}${path}${getQueryString(queryParameters)}`;

const getAddressPlaceholder = (isLatestVersion, roles) => {
    const message = 'No address information.';
    if (!doesUserHavePermission(roles, EDIT_LOCATION_PERMISSION)) {
        return message;
    }

    return isLatestVersion
        ? `${message} You can add from here.`
        : `${message} In order to add an address select the latest revision.`;
};

const createLocationObject = ({ address, position }, ip, type) => ({
    ip,
    type,
    address: address.label,
    countryName: address.countryName,
    city: address.city,
    zip: address.postalCode,
    latitude: position.lat,
    longitude: position.lng
});

const getInitialAddress = location => {
    if (!isLocationValid(location)) {
        return '';
    }

    return location.address
        ? location.address
        : `${location.city} ${location.zip}, ${location.countryName}`;
};

const getInitialState = location => ({
    address: getInitialAddress(location),
    searchResults: [],
    selectedLocation: location,
    isEditAddressDisabled: true,
    isLocationsListOpen: false,
    isSaveButtonDisabled: true
});

const LocalMapWrapper = ({
    addressLocator,
    instrumentId,
    isLatestVersion,
    location,
    openSnackbarAction,
    roles,
    updateLocationAction
}) => {
    const [state, dispatch] = useReducer(reducer, getInitialState(location));
    const {
        address,
        searchResults,
        selectedLocation,
        isEditAddressDisabled,
        isLocationsListOpen,
        isSaveButtonDisabled
    } = state;
    const [addressPlaceholder, setAddressLocation] = useState('');
    const addressInputRef = useRef();
    const { serviceUrl, token } = addressLocator;

    useEffect(() => dispatch(resetStateAction(getInitialState(location))), [
        location
    ]);

    useEffect(
        () => {
            document.addEventListener('click', handleClick);

            return () => document.removeEventListener('click', handleClick);
        },
        [isLocationsListOpen]
    );

    useEffect(
        () => setAddressLocation(getAddressPlaceholder(isLatestVersion, roles)),
        [isLatestVersion, roles]
    );

    const handleClick = event => {
        if (addressInputRef.current.contains(event.target)) {
            !isLocationsListOpen && dispatch(openLocationsListAction());
        } else {
            isLocationsListOpen && dispatch(closeLocationsListAction());
        }
    };

    const searchForAddress = address => {
        if (!address) {
            return;
        }

        const queryParameters = {
            q: address,
            lang: GEOCODING_API.RESPONSE_LANGUAGE
        };
        const url = getUrl(
            serviceUrl,
            GEOCODING_API.SERVICE_PATH,
            queryParameters
        );
        const options = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };

        fetch(url, options)
            .then(response =>
                dispatch(updateSearchResultsAction(response.items))
            )
            .catch(error =>
                //TODO not sure what KEY does HERE Maps use for error messages
                openSnackbarAction({
                    mode: 'error',
                    message: error.message || error.error
                })
            );
    };

    const handleChange = ({ target: { value } }) => {
        dispatch(changeAddressAction(value));
        debounce(searchForAddress, 300, value);
    };

    const handleCancel = () => {
        dispatch(resetStateAction(getInitialState(location)));
    };

    const handleEnableEdit = () => {
        dispatch(enableAddressEditAction());
    };

    const handleSelect = newLocation => {
        const newLocationObject = createLocationObject(
            newLocation,
            location.ip,
            location.type
        );

        dispatch(
            selectLocationAction({
                address: newLocationObject.address,
                selectedLocation: newLocationObject
            })
        );
    };

    const handleSave = () => {
        dispatch(saveLocationAction());
        updateLocationAction({ instrumentId, location: selectedLocation });
    };

    return (
        <>
            <div className={styles.InfoContainer}>
                <span className={styles.Label}>IP:</span>
                {location.ip || '-'}
            </div>
            <div className={styles.InfoContainer}>
                <div className={styles.AddressContainer}>
                    <input
                        className={styles.AddressInput}
                        value={address}
                        onChange={handleChange}
                        disabled={isEditAddressDisabled}
                        placeholder={addressPlaceholder}
                        ref={addressInputRef}
                    />
                    <LocationsList
                        locations={searchResults}
                        handleSelect={handleSelect}
                        isOpen={isLocationsListOpen}
                    />
                </div>
                <RoleBasedAccess permission={EDIT_LOCATION_PERMISSION}>
                    {isLatestVersion &&
                        (isEditAddressDisabled ? (
                            <Button
                                look="Primary"
                                className={styles.Button}
                                onClick={handleEnableEdit}
                            >
                                Edit
                            </Button>
                        ) : (
                            <>
                                <Button
                                    look="Primary"
                                    className={styles.Button}
                                    onClick={handleSave}
                                    disabled={isSaveButtonDisabled}
                                >
                                    Save
                                </Button>
                                <Button
                                    look="Secondary"
                                    className={styles.Button}
                                    onClick={handleCancel}
                                >
                                    Cancel
                                </Button>
                            </>
                        ))}
                </RoleBasedAccess>
            </div>
            <LocalMap
                location={selectedLocation}
                isLocationValid={isLocationValid(selectedLocation)}
            />
        </>
    );
};

LocalMapWrapper.propTypes = {
    instrumentId: PropTypes.string.isRequired,
    isLatestVersion: PropTypes.bool.isRequired,
    location: PropTypes.object.isRequired,
    openSnackbarAction: PropTypes.func.isRequired,
    updateLocationAction: PropTypes.func.isRequired
};

const ConnectedLocalMapWrapper = connect(
    ({ user: { addressLocator = {}, roles } }) => ({ addressLocator, roles }),
    {
        openSnackbarAction,
        updateLocationAction
    }
)(LocalMapWrapper);

export default ({ location, instrumentId, isLatestVersion }) => (
    <ConnectedLocalMapWrapper
        location={location || {}}
        instrumentId={instrumentId}
        isLatestVersion={isLatestVersion}
    />
);
