// this pattern checks whether the string ends with @tecan.<com/onmicrosoft.com/net>
const emailPattern = /^.+@tecan\.(com|onmicrosoft\.com|net){1}$/;

export const isSamlUser = email => emailPattern.test(email);

export const getRedirectUri = () => {
    let url = window.location.origin;

    if (window.location.hostname !== 'localhost') {
        const prefix = window.location.pathname.split('/')[1];
        if (
            prefix == 'dev' ||
            prefix == 'qa' ||
            prefix == 'stage' ||
            prefix == 'prod'
        ) {
            url += '/' + prefix;
        }
    }
    return `${url}/index.html`;
};

export const getSamlLogoutRedirectUri = () =>
    'https://login.microsoftonline.com/common/wsfederation?wa=wsignout1.0';
