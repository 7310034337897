import React from 'react';
import List from './List';
import NoData from './NoData';
import Title from './Title';
import { customSort } from '../../../common/utils/table';
import { SORT_ORDER } from '../../../common/hooks/useSort';
import { INSTRUMENT_MODKIT_STATUS } from '../../../common/utils/details';
import getModificationDetails from './getModificationDetails';
import { hasElements } from '../../../common/utils';

const { APPLIED, REVOKED } = INSTRUMENT_MODKIT_STATUS;

const HotFix = ({
    active,
    documentNumber,
    id,
    modified,
    name,
    status,
    ...rest
}) => (
    <div className="hotfix">
        <List
            list={[
                {
                    documentNumber: `${documentNumber}${
                        active ? '' : ' (retired)'
                    }`
                },
                {
                    ...rest,
                    description: name,
                    ...getModificationDetails(modified)
                }
            ]}
        />
    </div>
);

const renderModKits = hotfixes =>
    hotfixes.map(hotfixes => (
        <HotFix key={hotfixes.documentNumber} {...hotfixes} />
    ));

const HotFixSection = ({ hotfixes = [] }) => {
    debugger;
    const sortedModKits = customSort(
        hotfixes,
        'documentNumber',
        SORT_ORDER.ASCENDING
    );
    const appliedModKitsActive = sortedModKits.filter(
        modKit => modKit.active && modKit.status === APPLIED
    );
    const appliedModKitsRetired = sortedModKits.filter(
        modKit => !modKit.active && modKit.status === APPLIED
    );
    const revokedModKitsActive = sortedModKits.filter(
        modKit => modKit.active && modKit.status === REVOKED
    );
    const revokedModKitsRetired = sortedModKits.filter(
        modKit => !modKit.active && modKit.status === REVOKED
    );
    const appliedModKits = [...appliedModKitsActive, ...appliedModKitsRetired];
    const retiredModKits = [...revokedModKitsActive, ...revokedModKitsRetired];

    return (
        <>
            <Title className="level_1" title="HotFixes" />

            <Title className="level_2" title="Applied" />
            {hasElements(appliedModKits) ? (
                renderModKits(appliedModKits)
            ) : (
                <NoData />
            )}

            <Title className="level_2" title="Revoked" />
            {hasElements(retiredModKits) ? (
                renderModKits(retiredModKits)
            ) : (
                <NoData />
            )}
        </>
    );
};

export default HotFixSection;
