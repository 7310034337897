export const findProp = o => value => {
    return (
        (o.properties && o.properties.find(p => p.field === value)) ||
        (o.calculatedProperties &&
            o.calculatedProperties.find(p => p.field === value)) ||
        {}
    );
};

export const mapPropsSorted = props => {
    if (!props || props.length === 0) {
        return [];
    }
    const options = props.map(p => ({
        name: p.name,
        value: p.field
    }));

    return sortPropertyList(options);
};

const sortPropertyList = (list, field) => {
    if (!Array.isArray(list)) return [];

    const property = field ? field : 'name';

    return list.sort(
        (a, b) =>
            a[property].toLowerCase() > b[property].toLowerCase() ? 1 : -1
    );
};

export const getPropertyOptions = o => {
    const properties = mapPropsSorted(o.properties);
    properties.label = 'Properties';

    const calculatedProperties = mapPropsSorted(o.calculatedProperties);
    calculatedProperties.label = 'Calculated Properties';

    let propList = [{ name: '-', value: null }];

    if (properties.length > 0) {
        propList = propList
            .concat([properties])
            .concat(
                calculatedProperties.length > 0 ? [calculatedProperties] : []
            );
    }

    return propList;
};
