import React from 'react';
import classnames from 'classnames';
import {
    getInstrumentMaintenanceStatusMessage,
    MAINTENANCE_STATUS
} from '../../../common/utils/details';
import moment from 'moment';
export const renderInstrumentMaintenance = data => {
    let instrumentStatus = MAINTENANCE_STATUS.UNKNOWN;

    const armMaintenanceAll = data.metadata.armMaintenance;

    if (
        data.payload != undefined &&
        data.payload != null &&
        data.payload.modules != undefined &&
        data.payload.modules != null
    ) {
        const allArms = data.payload.modules.filter(
            x => x.moduleCategory == 'ARM'
        );

        var armMaintenance = [];

        armMaintenanceAll.forEach(a => {
            if (allArms.find(l => l.moduleId == a.moduleId) != null) {
                armMaintenance.push(a);
            }
        });

        if (armMaintenance != undefined && armMaintenance.length > 0) {
            // All arms are green
            const greenArms = armMaintenance.filter(
                x => moment.utc(x.endDate).toDate() > moment.utc().toDate()
            );

            if (greenArms.length === allArms.length) {
                instrumentStatus = MAINTENANCE_STATUS.MAINTAINED;
            }

            // At lease one arm is maintained -> instrument state is yellow
            if (greenArms.length < allArms.length && greenArms.length > 0) {
                instrumentStatus = MAINTENANCE_STATUS.PARTIAL_MAINTAINED;
            }

            const redArms = armMaintenance.filter(
                x => moment.utc(x.endDate).toDate() < moment.utc().toDate()
            );

            // At lease one arm is maintained -> instrument state is yellow
            if (greenArms.length === 0 && redArms.length > 0) {
                instrumentStatus = MAINTENANCE_STATUS.NOT_MAINTAINED;
            }
        }
    }

    const message = getInstrumentMaintenanceStatusMessage(
        instrumentStatus,
        data.metadata.maintenance
    );

    return (
        <span
            className={classnames(['MaintenanceStatus', instrumentStatus])}
            title={message}
        />
    );
};
