import createSagaMiddleware from 'redux-saga';
import { createStore, applyMiddleware, combineReducers } from 'redux';
import reducers from './reducers';
import { rootSaga } from './rootSaga';
import { composeWithDevTools } from 'redux-devtools-extension';

export default () => {
    const sagaMiddleware = createSagaMiddleware();
    const store = createStore(
        combineReducers(reducers),
        composeWithDevTools(applyMiddleware(sagaMiddleware))
    );

    sagaMiddleware.run(rootSaga);

    return store;
};
