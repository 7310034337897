import React, { useState } from 'react';

const InputFile = ({ input }) => {
    const [fileValue, setFileValue] = useState('');

    const handleChange = event => {
        const file = event.target.files[0];

        setFileValue(file.name);
        input.onChange(file);
    };

    const clearAttachment = event => {
        event.preventDefault();
        setFileValue('');
        input.onChange('');
    };

    return (
        <>
            <input
                id="file-input"
                type="file"
                onChange={handleChange}
                onBlur={input.onBlur}
            />
            <label htmlFor="file-input">
                <input
                    type="text"
                    value={fileValue}
                    className="AttachmentName"
                    readOnly
                />
                {!fileValue ? (
                    <span className="AddAttachment">Browse</span>
                ) : (
                    <span className="ClearAttachment" onClick={clearAttachment}>
                        Clear
                    </span>
                )}
            </label>
        </>
    );
};

export default InputFile;
