import { SAVE_SUPPORT_LIST, DELETE_SUPPORT, GET_USERS } from './constants';

export const createSupportReducer = (state, action) => {
    switch (action.type) {
        case SAVE_SUPPORT_LIST:
            return state.items.map(
                support =>
                    support.id === action.payload.id ? action.payload : support
            );

        case DELETE_SUPPORT:
            return state.items.filter((item, index) => index !== action.index);

        default:
            return state;
    }
};
