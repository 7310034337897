import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Loading from '../loading/Loading';
import {
    fetchInstrumentsByTag,
    clearInstrumentsByTag
} from '../../controllers/tags';
import {
    toggleMultipleInstrumentsSelectionAction,
    toggleSingleInstrumentSelectionAction
} from '../../controllers/instrument/actions';
import { getOpenModalWindowAction } from '../../controllers/modal';
import ResultWithActions from '../query/results/ResultsWithActions';
import RemoveTagFromInstrumentsForm from './RemoveTagFromInstrumentsForm';

const getUnassignTagAction = loading => ({
    title: 'Unassign tag',
    component: RemoveTagFromInstrumentsForm,
    isSubtract: true,
    actionHexCode: <>&#8722;</>,
    disabled: loading
});

const tagExists = (tags, id) => {
    return tags && tags.filter(t => t.id === id).length > 0;
};

const InstrumentsByTag = props => {
    const {
        selectedId,
        loading,
        instrumentList,
        tags,
        presetFilters,
        presetSortingCriteria,
        updatePresetFilters,
        updatePresetSortingCriteria,
        selectedInstruments,
        fetchInstrumentsByTag,
        clearInstrumentsByTag,
        toggleSingleInstrumentSelectionAction,
        toggleMultipleInstrumentsSelectionAction,
        getOpenModalWindowAction,
        unassignTagAction
    } = props;

    useEffect(
        () => {
            if (selectedId && tagExists(tags, selectedId)) {
                fetchInstrumentsByTag(selectedId);
            }

            return clearInstrumentsByTag;
        },
        [selectedId]
    );

    const message =
        tags && tags.length > 0
            ? 'Please select a tag from the left menu.'
            : 'There are no tags in the system';

    return (
        <Loading loading={loading}>
            {!selectedId ? (
                <p>{message}</p>
            ) : (
                <>
                    <ResultWithActions
                        actions={[
                            {
                                ...unassignTagAction,
                                props: { tagId: selectedId }
                            }
                        ]}
                        instrumentList={instrumentList}
                        selectedInstruments={selectedInstruments}
                        toggleMultipleInstrumentsSelectionAction={
                            toggleMultipleInstrumentsSelectionAction
                        }
                        toggleSingleInstrumentSelectionAction={
                            toggleSingleInstrumentSelectionAction
                        }
                        getOpenModalWindowAction={getOpenModalWindowAction}
                        presetFilters={presetFilters}
                        presetSortingCriteria={presetSortingCriteria}
                        updatePresetFilters={updatePresetFilters}
                        updatePresetSortingCriteria={
                            updatePresetSortingCriteria
                        }
                    />
                </>
            )}
        </Loading>
    );
};

InstrumentsByTag.propTypes = {
    selectedId: PropTypes.string,
    loading: PropTypes.bool,
    instrumentList: PropTypes.array,
    tags: PropTypes.array,
    presetFilters: PropTypes.array,
    presetSortingCriteria: PropTypes.object,
    updatePresetFilters: PropTypes.func,
    updatePresetSortingCriteria: PropTypes.func
};

export default connect(
    ({
        instrumentsByTag: { loading, instruments },
        tags,
        selectedInstruments,
        unassignTagFromInstruments
    }) => ({
        loading: !!loading,
        instrumentList: instruments ? instruments : [],
        tags: tags.items,
        selectedInstruments,
        unassignTagAction: getUnassignTagAction(
            unassignTagFromInstruments.loading
        )
    }),
    {
        fetchInstrumentsByTag,
        clearInstrumentsByTag,
        getOpenModalWindowAction,
        toggleSingleInstrumentSelectionAction,
        toggleMultipleInstrumentsSelectionAction
    }
)(InstrumentsByTag);
