import React from 'react';
import List from './List';
import Module from './Module';
import NoData from './NoData';
import Title from './Title';
import { customSort } from '../../../common/utils/table';
import { SORT_ORDER } from '../../../common/hooks/useSort';
import { getSerialNumberKey, hasElements } from '../../../common/utils/details';

const renderHardwareProperties = data => (
    <>
        <div className="hardware-properties">
            <List list={[data.properties]} />
        </div>
        <div className="hardware-properties">
            <List list={[data.calculatedProperties]} />
        </div>
    </>
);

const renderHardwareModules = modules =>
    customSort(modules, 'name', SORT_ORDER.ASCENDING).map(module => (
        <Module
            key={module.moduleId}
            module={module}
            moduleClassName="hardware-module"
            subModuleClassName="channel"
            title={`${module.name} - ${
                module.properties[getSerialNumberKey(module)]
            }`}
            list={[
                { category: module.category },
                module.properties,
                module.calculatedProperties,
                hasElements(module.modules) ? { channels: '' } : {}
            ]}
            getSubModuleList={subModule => [
                subModule.properties,
                subModule.calculatedProperties
            ]}
            getSubModuleKey={subModule => subModule.moduleId}
            subModuleSortCriteria="properties.ChannelNumber"
        />
    ));

const HardwareSection = ({ data }) => (
    <>
        <Title
            className="level_1"
            title="Hardware Details"
            lastModified={{ at: data.lastModifiedAt, by: data.lastModifiedBy }}
        />
        {hasElements(Object.keys(data.properties)) ||
        hasElements(Object.keys(data.calculatedProperties)) ? (
            renderHardwareProperties(data)
        ) : (
            <NoData />
        )}

        <Title className="level_1" title="Hardware Modules" />
        {hasElements(data.modules) ? (
            renderHardwareModules(data.modules)
        ) : (
            <NoData />
        )}
    </>
);

export default HardwareSection;
