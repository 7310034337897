import { CLEAR_CHANGE_HOTFIX_STATUS } from '../constants';

export const changeHotFixStatusReducer = (state, { type }) => {
    switch (type) {
        case CLEAR_CHANGE_HOTFIX_STATUS:
            return {};
        default:
            return state;
    }
};
