import React from 'react';

const DeleteIcon = ({ className, title }) => (
    <svg className={className} viewBox="0 0 24 24">
        {title && <title>{title}</title>}
        <path
            fill="currentColor"
            d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM8 9h8v10H8V9zm7.5-5l-1-1h-5l-1 1H5v2h14V4z"
        />
        <path fill="none" d="M0 0h24v24H0V0z" />
    </svg>
);

export default DeleteIcon;
