import React, { Component } from 'react';
import { Form } from 'react-final-form';
import { connect } from 'react-redux';
import { queryInstrumentListBySerialNumberAction } from '../../controllers/query';
import Button from '../button/Button';
import Input from '../input/Input';
import CheckErrors from '../statusMessage/CheckErrors';
import './simplequery.scss';
import { PropTypes } from 'prop-types';
import { clearInstrumentListAction } from '../../controllers/query/actions';
import { ENTER } from '../../common/constants';

class SimpleQuery extends Component {
    onSubmit = ({ serialNumber }) => {
        this.props.queryInstrumentListBySerialNumberAction({
            serialNumber
        });
        this.props.navigateOnSearch();
    };
    componentDidMount = () => {
        this.props.clearInstrumentListAction();
    };

    onKeyPress = event => {
        const {
            key,
            target: { value }
        } = event;

        if (key === ENTER && !!value && value.trim() === value) {
            this.onSubmit({ serialNumber: value });
        }
    };

    render = () => (
        <div className="SimpleQuery">
            <section>
                <Form
                    onSubmit={this.onSubmit}
                    subscription={{ touched: true, error: true, invalid: true }}
                >
                    {({ handleSubmit, pristine, invalid }) => (
                        <form
                            className="SimpleQueryForm"
                            onKeyPress={this.onKeyPress}
                            onSubmit={event => event.preventDefault()}
                        >
                            <Input
                                name="serialNumber"
                                type="text"
                                component="input"
                                label="Please enter serial number:"
                                required
                            />
                            <Button
                                look="Primary"
                                behaviour="button"
                                disabled={pristine || invalid}
                                onClick={handleSubmit}
                            >
                                Submit
                            </Button>
                        </form>
                    )}
                </Form>
                <CheckErrors
                    error={this.props.error}
                    errorMessage={this.props.message}
                />
            </section>
            <section className="Content">{this.props.children}</section>
        </div>
    );
}

SimpleQuery.propTypes = {
    navigateOnSearch: PropTypes.func.isRequired
};

export default connect(
    null,
    {
        queryInstrumentListBySerialNumberAction,
        clearInstrumentListAction
    }
)(SimpleQuery);
