import React from 'react';
import { PropTypes } from 'prop-types';
import './loading.scss';

const renderLoadingImage = ({ imageSize }) => (
    <div
        className="loadingOuter"
        style={{ width: `${imageSize}px`, height: `${imageSize}px` }}
    >
        &nbsp;
        <div className="loadingInner">&nbsp;</div>
        <div className="loadingOrbiter">&nbsp;</div>
    </div>
);

const renderLoadingText = ({ text, textSize }) => (
    <div className="loadingTextOuter">
        <div className="loadingTextInner" style={{ fontSize: `${textSize}px` }}>
            {text}
        </div>
    </div>
);

const Loading = ({ loading, children, imageSize, textSize, mode, text }) => {
    return loading ? (
        mode === 'image' ? (
            renderLoadingImage({ imageSize })
        ) : (
            renderLoadingText({ text, textSize })
        )
    ) : (
        <React.Fragment>{children}</React.Fragment>
    );
};

Loading.propTypes = {
    loading: PropTypes.bool.isRequired,
    mode: PropTypes.oneOf(['image', 'text']),
    imageSize: PropTypes.number,
    textSize: PropTypes.number,
    text: PropTypes.string
};

Loading.defaultProps = {
    imageSize: 128,
    textSize: 14,
    mode: 'image',
    text: 'Loading'
};

export default Loading;
