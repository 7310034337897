import React from 'react';
import PropTypes from 'prop-types';

const KeysAndValues = ({ data, sorting }) => {
    const propKeys = Object.keys(data);

    if (propKeys.length === 0) {
        return <></>;
    }

    sorting && propKeys.sort();

    return propKeys.map((curr, index) => (
        <p key={index}>
            <strong>{curr}: </strong>
            <span>
                {typeof data[curr] === 'boolean'
                    ? String(data[curr])
                    : data[curr]}
            </span>
        </p>
    ));
};

KeysAndValues.propTypes = {
    data: PropTypes.object.isRequired,
    sorting: PropTypes.bool
};

KeysAndValues.defaultProps = {
    sorting: true
};

export default KeysAndValues;
