import { FETCH_SUCCESS } from '../fetch/constants';
import { ADD_LOGBOOK_ENTRY } from './constants';

export const addLogbookEntryReducer = (state, { type, payload, meta }) => {
    if (
        type === FETCH_SUCCESS &&
        meta &&
        meta.namespace &&
        meta.namespace === ADD_LOGBOOK_ENTRY
    ) {
        const newLogbookEntry = payload;
        const newMaintenance = newLogbookEntry.maintenance
            ? newLogbookEntry.maintenance
            : state.maintenance;
        const oldLogbooks = Array.isArray(state.logbook) ? state.logbook : [];

        return {
            ...state,
            maintenance: newMaintenance,
            logbook: [...oldLogbooks, newLogbookEntry]
        };
    } else {
        return state;
    }
};
