import React from 'react';
import PropTypes from 'prop-types';
import Table from '../table/Table';
import { COLUMN_TYPE, LAYOUT } from '../table/constants';
import { isApplied } from '../../controllers/modkit/utils';
import { formatInstrumentModKitStatus } from '../../common/utils/details';

const InstrumentsByModKitList = ({
    instruments,
    applyModKitAction,
    undoModKitAction,
    modkit
}) => {
    const columns = [
        {
            type: COLUMN_TYPE.TEXT,
            displayName: 'Serial Number',
            key: 'instrumentId.serialNumber',
            hasSorting: true,
            hasFiltering: true
        },
        {
            type: COLUMN_TYPE.TEXT,
            displayName: 'Size',
            key: 'instrumentId.size',
            hasSorting: true,
            hasFiltering: true,
            width: 80
        },
        {
            type: COLUMN_TYPE.TEXT,
            displayName: 'Modified By',
            key: 'modified.username',
            hasSorting: true,
            hasFiltering: true,
            width: 150
        },
        {
            type: COLUMN_TYPE.DATE,
            displayName: 'Modified At',
            key: 'modified.at',
            hasSorting: true,
            hasFiltering: true,
            width: 220
        },
        {
            type: COLUMN_TYPE.TEXT,
            displayName: 'Status',
            key: 'status',
            hasSorting: true,
            hasFiltering: true,
            width: 150,
            render: status => formatInstrumentModKitStatus(status)
        },
        {
            type: COLUMN_TYPE.CONFIRMATION,
            key: 'id',
            displayName: 'Action',
            width: 90,
            getButtonLook: ({ status }) =>
                isApplied(status) ? 'Secondary' : 'Primary',
            getText: ({ status }) => (isApplied(status) ? 'Revoke' : 'Apply'),
            confirmation: {
                key: 'status',
                confirmInputName: 'comment',
                onAction: ({ id, status }) => formData => {
                    const action = isApplied(status)
                        ? undoModKitAction
                        : applyModKitAction;

                    action(
                        {
                            instrumentId: id,
                            id: modkit.id,
                            comment: formData['comment']
                        },
                        false
                    );
                },
                disabled: ({ status }) =>
                    modkit.status === 'RETIRED' && !isApplied(status),
                getMessage: ({ status }) => {
                    return isApplied(status)
                        ? `Do you really want to revoke modkit "${
                              modkit.documentNumber
                          }" from this instrument?`
                        : `Do you really want to apply modkit "${
                              modkit.documentNumber
                          }" to this instrument?`;
                }
            }
        }
    ];

    return (
        <div className="InstrumentsContainer">
            <div className="ResultContainerWrapper">
                <div className="ResultContainer">
                    <Table
                        id="id"
                        data={instruments}
                        columns={columns}
                        layout={LAYOUT.AUTO}
                    />
                </div>
            </div>
        </div>
    );
};

InstrumentsByModKitList.propTypes = {
    applyModKitAction: PropTypes.func,
    undoModKitAction: PropTypes.func,
    instruments: PropTypes.array,
    modkit: PropTypes.object
};

export default InstrumentsByModKitList;
