import React, { Component } from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { QueryBuilder } from './QueryBuilder';
import {
    fetchQueryParamsMetaDataAction,
    queryInstrumentListAction
} from '../../controllers/query';
import PageWithLeftSidebar from '../../container/PageWithLeftSidebar';

import './layout.scss';

class QueryPageLayout extends Component {
    constructor(props) {
        super(props);
        this.initialState = { filters: [], sortingCriteria: {} };
        this.state = this.initialState;
    }

    updateFilters = filters => this.setState({ filters });

    updateSortingCriteria = sortingCriteria =>
        this.setState({ sortingCriteria });

    clearFiltersAndSorting = () => this.setState(this.initialState);

    render = () => {
        const mainContent = this.props.persistFiltersAndSortingCriteria
            ? React.cloneElement(this.props.children, {
                  presetFilters: this.state.filters,
                  presetSortingCriteria: this.state.sortingCriteria,
                  updatePresetFilters: this.updateFilters,
                  updatePresetSortingCriteria: this.updateSortingCriteria
              })
            : this.props.children;

        return (
            <PageWithLeftSidebar
                loading={this.props.loading}
                defaultCollapsed={this.props.collapseSearch}
                renderSidebarContent={() => (
                    <QueryBuilder
                        queryParamsMetaData={this.props.queryParamsMetaData}
                        queryInstrumentListAction={
                            this.props.queryInstrumentListAction
                        }
                        navigateOnSearch={this.props.navigateOnSearch}
                        clearPresetFiltersAndSorting={
                            this.clearFiltersAndSorting
                        }
                    />
                )}
                renderMainContent={() => mainContent}
            />
        );
    };

    componentDidMount = () => {
        this.props.fetchQueryParamsMetaDataAction();
    };
}

QueryPageLayout.propTypes = {
    navigateOnSearch: PropTypes.func.isRequired,
    collapseSearch: PropTypes.bool
};

QueryPageLayout.defaultProps = {
    collapseSearch: false
};

export default connect(
    ({ queryParamsMetaData }) => ({
        loading: Boolean(queryParamsMetaData.loading),
        queryParamsMetaData
    }),
    {
        fetchQueryParamsMetaDataAction,
        queryInstrumentListAction
    }
)(QueryPageLayout);
