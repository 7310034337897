import {
    FETCH_INSTRUMENT_LIST,
    SELECT_INSTRUMENT,
    INSTRUMENT_LIST_IS_UPDATING,
    FETCH_QUERY_PARAMS_META_DATA,
    CLEAR_INSTRUMENT_LIST,
    FETCH_INSTRUMENT_LIST_BY_SN
} from './constants';

export const clearInstrumentListAction = () => ({
    type: CLEAR_INSTRUMENT_LIST
});

export const fetchInstrumentListAction = () => ({
    type: FETCH_INSTRUMENT_LIST
});

export const selectInstrumentAction = (id, version) => ({
    type: SELECT_INSTRUMENT,
    id,
    version
});

// TODO: Do we need this action?
export const instrumentListIsUpdatingAction = () => ({
    type: INSTRUMENT_LIST_IS_UPDATING
});

export const fetchQueryParamsMetaDataAction = () => ({
    type: FETCH_QUERY_PARAMS_META_DATA
});

export const queryInstrumentListAction = ({ queryParams }) => ({
    type: FETCH_INSTRUMENT_LIST,
    queryParams
});

export const queryInstrumentListBySerialNumberAction = ({ serialNumber }) => ({
    type: FETCH_INSTRUMENT_LIST_BY_SN,
    serialNumber
});
