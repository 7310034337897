import React from 'react';
import { connect } from 'react-redux';
import { SORT_ORDER } from '../../../../common/hooks/useSort';
import { downloadQcDataXmlAction } from '../../../../controllers/instrument/actions';
import { COLUMN_TYPE, Table } from '../../../table';
import PropertyList from '../../shared/PropertyList';

const renderQcData = props => {
    const { qcdata, styles, downloadQcDataXml } = props;
    const qcDataList = qcdata ? qcdata.map(n => ({ ...n })) : [];

    const columns = [
        {
            type: COLUMN_TYPE.LOCALDATE,
            displayName: 'Execution Time',
            key: 'executionTime',
            hasSorting: true,
            hasFiltering: true,
            width: 140
        },
        {
            type: COLUMN_TYPE.TEXT,
            displayName: 'Device Name',
            key: 'deviceName',
            hasSorting: true,
            hasFiltering: true,
            width: 180
        },
        {
            type: COLUMN_TYPE.TEXT,
            displayName: 'Test Name',
            key: 'actionName',
            hasSorting: true,
            hasFiltering: true,
            width: 180
        },
        {
            type: COLUMN_TYPE.TEXT,
            displayName: 'Result',
            key: 'successfulServiceActions',
            hasSorting: true,
            hasFiltering: true,
            width: 80
        },
        {
            type: COLUMN_TYPE.TEXT,
            displayName: 'Params',
            key: 'parameters.length',
            hasSorting: true,
            hasFiltering: true,
            width: 80
        },
        {
            type: COLUMN_TYPE.TEXT,
            displayName: 'Version',
            key: 'applicationVersion',
            hasSorting: true,
            hasFiltering: true,
            width: 80
        },
        {
            type: COLUMN_TYPE.TEXT,
            key: 'id',
            displayName: 'Download',
            hasSorting: false,
            hasFiltering: false,
            width: 80,
            className: styles.CENTER,
            render: (id, data) => (
                <div
                    data-name="qc-data-download-button"
                    onClick={e => {
                        e.stopPropagation();
                        const fileName = `${
                            data.instrumentId
                        }-${data.actionName.replace(
                            / /g,
                            ''
                        )}-${data.executionTime.replace(/:/g, '_')}`;
                        downloadQcDataXml(id, fileName);
                    }}
                >
                    download
                </div>
            )
        }
    ];

    const renderQcDataExtraInfo = parameters => {
        return parameters.map(
            (parameter, index) => (
                (parameter.value = parameter.value.replace(',', ', ')),
                (
                    <div
                        key={index}
                        style={{
                            margin: '0',
                            padding: '5px 10px 5px 0',
                            width: '25%',
                            float: 'left'
                        }}
                    >
                        <div
                            style={{
                                backgroundColor: '#fff',
                                padding: '5px 15px',
                                fontSize: '12px'
                            }}
                        >
                            <PropertyList
                                key={parameter.element}
                                data={parameter}
                                sorting={false}
                            />
                        </div>
                    </div>
                )
            )
        );
    };

    return (
        <Table
            id="id"
            initialSortColumn="executionTime"
            initialSortOrder={SORT_ORDER.DESCENDING}
            data={qcDataList}
            columns={columns}
            renderExtraInfo={row => renderQcDataExtraInfo(row.parameters)}
        />
    );
};

const ConnectedQCDataTab = connect(
    null,
    {
        downloadQcDataXml: downloadQcDataXmlAction
    }
)(renderQcData);

export default props => <ConnectedQCDataTab {...props} />;
