import React from 'react';
import PropTypes from 'prop-types';
import Button from '../button/Button';
import classnames from 'classnames';
import './listElement.scss';

const ListElement = ({
    children,
    to,
    onClick,
    activeModule,
    className,
    component
}) => {
    const classes = classnames(className, 'ListElement', {
        ActiveModule: activeModule
    });

    const Component = component;

    return to ? (
        <Component>
            <Button to={to} look="Clear" className={classes}>
                {children}
            </Button>
        </Component>
    ) : (
        <Component onClick={onClick} className={classes}>
            {children}
        </Component>
    );
};

ListElement.propTypes = {
    children: PropTypes.node.isRequired,
    component: PropTypes.string
};

ListElement.defaultProps = {
    component: 'li'
};

export default ListElement;
