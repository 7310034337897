export const FETCH_UPDATES_ACTION = 'updates.fetchUpdatesList';
export const FETCH_INSTRUMENTS_BY_UPDATE = 'updates.fetchInstrumentsByUpdate';
export const CHANGE_TRIGGERED_UPDATE_STATUS_FOR_INSTRUMENTS =
    'updates.changeTriggeredUpdateStatusForInstrument';
export const REFRESH_INSTRUMENT_UPDATE_STATUS =
    'updates.refresInstrumentUpdateStatus';
export const TRIGGER_SOFTWARE_UPDATE = 'storageBrowser.triggerSoftwareUpdate';
export const CLEAR_TRIGGER_SOFTWARE_UPDATE =
    'storageBrowser.clearTriggerSoftwareUpdate';
export const CREATE_NOTIFICATION = 'storageBrowser.createNotification';
export const ASSIGN_NOTIFICATION_TO_INSTRUMENTS =
    'storageBrowser.assignNotificationToInstruments';
export const RETIRE_UPDATE = 'updates.retireUpdate';
export const REMOVE_UPDATE_FROM_STORE = 'updates.removeUpdateFromStore';
