import React from 'react';

const InvalidCredentials = () => (
    <div>
        <br />
        <p>You have insufficient privileges, please contact IT</p>
    </div>
);

export default InvalidCredentials;
