import React from 'react';
import PropTypes from 'prop-types';
import { getUpdateNameFromPath } from '../../common/utils';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { convertDate } from '../../common/utils/';
import CopyToClipboardIcon from '../image/CopyToClipboardIcon';

const UpdateInfo = props => {
    const {
        id,
        type,
        payload: { PATH, URL },
        modified
    } = props;

    return (
        <div className="InformationBlock">
            <div className="Row">
                <span className="Label">Name:</span>
                <span className="Value">{getUpdateNameFromPath(PATH)}</span>
            </div>
            <div className="Row">
                <span className="Label">Update Type:</span>
                <span className="Value">{type}</span>
            </div>
            <div className="Row">
                <span className="Label">Path:</span>
                <span className="Value" title={PATH}>
                    {PATH}
                </span>
            </div>
            <div className="Row">
                <span className="Label">Package Url:</span>
                <span className="Value">
                    <a
                        href={URL}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="Text"
                    >
                        {URL}
                    </a>
                    <CopyToClipboard className="Icon" text={URL}>
                        <span>
                            <CopyToClipboardIcon title="copy link to clipboard" />
                        </span>
                    </CopyToClipboard>
                </span>
            </div>
            <div className="Row">
                <span className="Label">System Id:</span>
                <span className="Value">{id}</span>
            </div>
            <div className="Row">
                <span className="Label">Created by:</span>
                <span className="Value">{modified.username}</span>
            </div>
            <div className="Row">
                <span className="Label">Created at:</span>
                <span className="Value">{convertDate(modified.at)}</span>
            </div>
        </div>
    );
};

UpdateInfo.propTypes = {
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    payload: PropTypes.shape({
        PATH: PropTypes.string.isRequired,
        URL: PropTypes.string.isRequired
    })
};

export default UpdateInfo;
