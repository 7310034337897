import { CLEAR_CREATE_MODKIT, CREATE_MODKIT } from '../constants';
import {
    FETCH_SUCCESS,
    FETCH_ERROR,
    fetchToStatusClass
} from '../../fetch/constants';

export const defaultStatusMessage = (payload, fetchStatus) => {
    let message = '';
    if (fetchStatus === FETCH_ERROR) {
        if (payload && payload.message) {
            message = payload.message;
        } else {
            message = 'Error creating ModKit.';
        }
    } else if (fetchStatus === FETCH_SUCCESS) {
        message = `Created ModKit with Document Number: "${
            payload.documentNumber
        }"`;
    }
    return message;
};

export const createModkitReducer = (state, { type, payload, meta }) => {
    switch (type) {
        case FETCH_SUCCESS:
        case FETCH_ERROR:
            if (meta.namespace !== 'createModKit') {
                return state;
            }
            return {
                statusClass: fetchToStatusClass(type),
                ...state,
                message: defaultStatusMessage(payload, type),
                errors: payload.errors,
                loading: false
            };
        case CLEAR_CREATE_MODKIT:
            return {};
        case CREATE_MODKIT:
            return {
                ...state,
                loading: true
            };
        default:
            return state;
    }
};
