import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import Button from '../button/Button';
import { Form } from 'react-final-form';
import Input from '../input/Input';
import StatusMessage from '../statusMessage/StatusMessage';

const Drilldown = props => {
    const {
        className,
        confirmInputName,
        confirmMessage,
        statusMessage,
        statusClass,
        onConfirm,
        onToggle,
        shown,
        disableButtons
    } = props;

    const [isButtonDisabled, setIsButtonDisabled] = useState(disableButtons);

    useEffect(
        () => {
            setIsButtonDisabled(disableButtons);
        },
        [disableButtons]
    );

    const onClick = event => {
        setIsButtonDisabled(true);
        onConfirm(event);
    };

    return (
        !!shown && (
            <>
                <Form onSubmit={onClick}>
                    {({ handleSubmit }) => (
                        <form onSubmit={handleSubmit} className={className}>
                            {confirmInputName && (
                                <div>
                                    <Input
                                        name={confirmInputName}
                                        label="Comment"
                                        type="text"
                                        component="input"
                                    />
                                </div>
                            )}
                            <section className={classnames(['PromptSection'])}>
                                <span>{confirmMessage}</span>
                                <Button
                                    className="Confirm"
                                    look="Primary"
                                    type="submit"
                                    disabled={isButtonDisabled}
                                    onClick={handleSubmit}
                                >
                                    Confirm
                                </Button>
                                <Button
                                    className="Cancel"
                                    look="Secondary"
                                    onClick={onToggle}
                                    disabled={isButtonDisabled}
                                >
                                    Cancel
                                </Button>
                            </section>
                        </form>
                    )}
                </Form>
                <StatusMessage
                    message={statusMessage}
                    statusClass={statusClass}
                />
            </>
        )
    );
};

export default Drilldown;
