import moment from 'moment';

const FORMAT = 'DD MMM YYYY HH:mm:ss';

export const concatPropValues = (...labelValuePairs) => {
    const pairs = [];
    for (let i = 0; i < labelValuePairs.length; i += 2) {
        if (labelValuePairs[i + 1]) {
            pairs.push(`${labelValuePairs[i]}: ${labelValuePairs[i + 1]}`);
        }
    }
    return pairs.join(', ');
};

export const convertLocalDate = date => {
    if (!date) {
        return '';
    }
    return moment.utc(date).format('DD MMM YYYY HH:mm');
};

export const convertDate = date => {
    if (!date) {
        return '';
    }
    return moment.utc(date).format(FORMAT) + ' GMT';
};

export const getValue = data => (data === undefined ? '' : data);

export const listWithEmptyElement = (
    list,
    renderElementCb,
    emptyListCb,
    sortCb
) => {
    if (list.length > 0) {
        if (sortCb) {
            list.sort(sortCb);
        }
        return list.map(renderElementCb);
    } else {
        return emptyListCb();
    }
};

export const alphabeticSort = (a, b) =>
    a.documentNumber.toLowerCase() > b.documentNumber.toLowerCase() ? 1 : -1;

export const sortedModKitList = (modkitList, renderElementCb, emptyListCb) => {
    return listWithEmptyElement(
        modkitList,
        renderElementCb,
        emptyListCb,
        alphabeticSort
    );
};

export const sortVersionsDescending = versions =>
    Array.isArray(versions)
        ? [...versions].sort((v1, v2) => v2.number - v1.number)
        : [];

export const MODKIT_STATUS = {
    NEW: 'NEW',
    USED: 'USED',
    RETIRED: 'RETIRED'
};

export const INSTRUMENT_MODKIT_STATUS = {
    NOT_APPLIED: 'NOT_APPLIED',
    APPLIED: 'APPLIED',
    REVOKED: 'REVOKED'
};

export const formatModKitStatus = status => {
    switch (status) {
        case MODKIT_STATUS.NEW:
            return 'New';
        case MODKIT_STATUS.RETIRED:
            return 'Retired';
        case MODKIT_STATUS.USED:
            return 'Used';
        default:
            return status;
    }
};

export const formatInstrumentModKitStatus = status => {
    switch (status) {
        case INSTRUMENT_MODKIT_STATUS.APPLIED:
            return 'Applied';
        case INSTRUMENT_MODKIT_STATUS.NOT_APPLIED:
            return 'Not Applied';
        case INSTRUMENT_MODKIT_STATUS.REVOKED:
            return 'Revoked';
        default:
            return status;
    }
};

export const concatPropertiesToStrings = (...strings) => {
    const concatedString = strings.join(' ');
    return concatedString;
};

export const hasElements = array => Array.isArray(array) && array.length > 0;

export const getSerialNumberKey = ({ category, name }) => {
    if (name === 'CLLD') {
        return 'MainVersion';
    }

    switch (category) {
        case 'Arm':
            return 'SerialNumberArm';
        case 'FW':
            return 'ApplicationVersion';
        case 'Option':
            return 'SerialNumber';
        default:
            return 'SerialNumber';
    }
};
export const hasSoftwareInformation = data => {
    return (
        data.software &&
        data.software.softwarePackages &&
        data.software.softwarePackages.length > 0
    );
};

export const hasHardwareInformation = data =>
    (data.properties && Object.keys(data.properties).length > 0) ||
    (data.calculatedProperties &&
        Object.keys(data.calculatedProperties).length > 0) ||
    hasElements(data.modules);

export const MAINTENANCE_STATUS = {
    UNKNOWN: 'UNKNOWN',
    MAINTAINED: 'MAINTAINED',
    NOT_MAINTAINED: 'NOT_MAINTAINED',
    PARTIAL_MAINTAINED: 'PARTIAL_MAINTAINED'
};

export const getInstrumentMaintenanceStatus = (maintenance, modules) => {
    const { required, endDate } = maintenance;

    let instrumentStatus = MAINTENANCE_STATUS.UNKNOWN;

    if (modules !== undefined && modules !== null) {
        const arms = modules.filter(x => x.category === 'Arm');

        // All arms are green
        const greenArms = arms.filter(
            x =>
                x.armMaintenanceModule !== undefined &&
                moment.utc(x.armMaintenanceModule.endDate).toDate() >
                    moment.utc().toDate()
        );

        if (greenArms.length > 0 && greenArms.length === arms.length) {
            instrumentStatus = MAINTENANCE_STATUS.MAINTAINED;
        }

        // At lease one arm is maintained -> instrument state is yellow
        if (greenArms.length < arms.length && greenArms.length > 0) {
            instrumentStatus = MAINTENANCE_STATUS.PARTIAL_MAINTAINED;
        }

        const redArms = arms.filter(
            x =>
                x.armMaintenanceModule !== undefined &&
                moment.utc(x.armMaintenanceModule.endDate).toDate() <
                    moment.utc().toDate()
        );

        // At lease one arm is maintained -> instrument state is yellow
        if (greenArms.length === 0 && redArms.length > 0) {
            instrumentStatus = MAINTENANCE_STATUS.NOT_MAINTAINED;
        }
    } else {
        if (moment.utc(endDate).toDate() > moment.utc().toDate()) {
            instrumentStatus = MAINTENANCE_STATUS.MAINTAINED;
        }
    }

    return instrumentStatus;
};

export const getInstrumentMaintenanceStatusMessage = (
    maintenanceStatus,
    maintenance
) => {
    const { endDate } = maintenance;

    let message = `Status ${maintenanceStatus}.`;

    if (endDate) {
        message = message + ` Valid until ${convertDate(endDate)}`;
    }

    return message;
};

export const modifyInstrumentsByAddingMaintenanceStatus = instrumentList => {
    return instrumentList.map(instrument => ({
        ...instrument,
        maintenanceStatus: getInstrumentMaintenanceStatus(
            instrument.metadata.maintenance,
            instrument.metadata.modules
        )
    }));
};
