import { FETCH_ERROR } from '../fetch/constants';
import { UPDATE_LOCATION } from './constants';

export const updateLocationReducer = (state, { type, location, meta }) => {
    if (type === FETCH_ERROR && meta.namespace === UPDATE_LOCATION) {
        return {
            ...state,
            location: { ...state.location }
        };
    }

    return state;
};
