import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Loading from '../loading/Loading';
import Instrument from '../details/Instrument';
import { selectInstrumentAction } from '../../controllers/query';
import CheckErrors from '../statusMessage/CheckErrors';
import { FETCH_ERROR } from '../../controllers/fetch';
import { PropTypes } from 'prop-types';
import { clearInstrumentVersionsAction } from '../../controllers/instrument/actions';

const InstrumentDetail = props => {
    useEffect(
        () => {
            const {
                selectedId,
                selectInstrumentAction,
                clearInstrumentVersionsAction
            } = props;

            selectInstrumentAction(selectedId);

            return () => clearInstrumentVersionsAction();
        },
        [props.selectedId]
    );

    const {
        selectedInstrumentVersions,
        loading,
        error,
        errorMessage,
        ...instrumentProps
    } = props;

    let isLatestVersion = true;

    if (!loading && selectedInstrumentVersions.length) {
        const currentRevisionNumber =
            instrumentProps.data.currentRevision.number;
        const latestInstrumentVersion = selectedInstrumentVersions.sort(
            (v1, v2) => v2.number - v1.number
        )[0];

        isLatestVersion =
            currentRevisionNumber === latestInstrumentVersion.number;
    }

    return (
        <Loading loading={loading}>
            <CheckErrors error={error} errorMessage={errorMessage}>
                <Instrument
                    data={instrumentProps.data}
                    selectedId={instrumentProps.selectedId}
                    moduleIdentifier={instrumentProps.moduleIdentifier}
                    subModuleIdentifier={instrumentProps.subModuleIdentifier}
                    onNavigateBack={instrumentProps.onNavigateBack}
                    parentPage={instrumentProps.parentPage}
                    currentTab={instrumentProps.currentTab}
                    isLatestVersion={isLatestVersion}
                    roles={instrumentProps.roles}
                />
            </CheckErrors>
        </Loading>
    );
};

InstrumentDetail.propTypes = {
    onNavigateBack: PropTypes.func.isRequired
};

export default connect(
    ({ selectedInstrument, selectedInstrumentVersions, user }) => ({
        data: selectedInstrument,
        loading: !!selectedInstrument.loading,
        error: selectedInstrument.fetchStatus === FETCH_ERROR,
        errorMessage: selectedInstrument.message,
        selectedInstrumentVersions: selectedInstrumentVersions
            ? selectedInstrumentVersions.items
            : [],
        roles: user.roles
    }),
    {
        selectInstrumentAction,
        clearInstrumentVersionsAction
    }
)(InstrumentDetail);
