import { UPLOAD_CLEAR, UPLOAD_INVALID_FILE_SELECTED } from './constants';
import {
    FETCH_SUCCESS,
    FETCH_ERROR,
    fetchToStatusClass
} from '../fetch/constants';

const defaultUploadStatusMessage = (message, fetchStatus) => {
    if (!message && fetchStatus) {
        return 'Error uploading file.';
    }
    return message;
};

export const uploadReducer = (
    state,
    { type, message, statusClass, payload, meta }
) => {
    switch (type) {
        case UPLOAD_CLEAR:
            return {};
        case UPLOAD_INVALID_FILE_SELECTED:
            return { message, statusClass };
        case FETCH_SUCCESS:
        case FETCH_ERROR:
            if (meta.namespace !== 'upload') {
                return state;
            }

            const defaultMessage =
                type === FETCH_SUCCESS
                    ? 'Upload successful.'
                    : 'Upload failed.';

            return {
                statusClass: fetchToStatusClass(state.fetchStatus),
                ...state,
                message: defaultUploadStatusMessage(
                    payload && payload.message
                        ? payload.message
                        : defaultMessage,
                    state.fetchStatus
                ),
                errors: payload.errors
            };
        default:
            return state;
    }
};
