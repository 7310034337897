export const MAP_TEMPLATE = {
    URL:
        'https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png',
    ATTRIBUTION:
        '&copy; <a href=&quot;https://www.openstreetmap.org/copyright&quot;>OpenStreetMap</a> contributors &copy; <a href=&quot;https://carto.com/attributions&quot;>CARTO</a>'
};

export const MAP_CONFIG = {
    MIN_ZOOM: 3,
    MAX_ZOOM: 18,
    MAX_BOUNDS: [[-180, -180], [180, 180]],
    LATITUDE_GLOBAL_MAP: 30,
    LONGITUDE_GLOBAL_MAP: 0
};

export const GEOCODING_API = {
    RESPONSE_LANGUAGE: 'en-us',
    SERVICE_PATH: 'v1/geocode'
};
