import { put, takeEvery, all, call, take, takeMaybe } from 'redux-saga/effects';
import {
    ASSIGN_NOTIFICATION_TO_INSTRUMENTS,
    CREATE_NOTIFICATION,
    FETCH_UPDATES_ACTION,
    FETCH_INSTRUMENTS_BY_UPDATE,
    TRIGGER_SOFTWARE_UPDATE,
    CHANGE_TRIGGERED_UPDATE_STATUS_FOR_INSTRUMENTS,
    REFRESH_INSTRUMENT_UPDATE_STATUS,
    RETIRE_UPDATE,
    REMOVE_UPDATE_FROM_STORE
} from './constants';
import history from '../../history';
import { FETCH_SUCCESS, fetchDataAction } from '../fetch';
import { onSuccessful, onError } from '../fetch/actions';
import { openSnackbarAction } from '../snackbar/actions';

let messageId = 1;

function* fetchUpdatesList() {
    yield put(
        fetchDataAction('updates')('/api/v1/notifications', {
            method: 'get'
        })
    );
}

function* fetchInstrumentsByUpdate({ updateId }) {
    yield put(
        fetchDataAction(FETCH_INSTRUMENTS_BY_UPDATE)(
            `/api/v1/instruments/notifications/${updateId}`,
            {
                method: 'get',
                params: {}
            }
        )
    );
}

function* changeTriggeredUpdateStatusForInstruments({
    updateId,
    instrumentId,
    updateStatus
}) {
    yield put(
        fetchDataAction(CHANGE_TRIGGERED_UPDATE_STATUS_FOR_INSTRUMENTS)(
            `/api/v1/instruments/${instrumentId}/notifications`,
            {
                method: 'patch',
                data: {
                    id: updateId,
                    status: updateStatus,
                    updateTime: new Date().toISOString()
                }
            }
        )
    );

    yield call(
        refreshInstrumentsByUpdateAfterChangeStatus,
        updateId,
        instrumentId,
        updateStatus
    );
}

function* triggerSoftwareUpdate({ path, instrumentIds, updateType }) {
    // TRIGGER_SOFTWARE_UPDATE
    yield put(
        fetchDataAction(CREATE_NOTIFICATION)('/api/v1/notifications', {
            method: 'post',
            data: {
                type: updateType,
                payload: {
                    PATH: path
                }
            }
        })
    );

    yield call(assignNotificationToInstruments, instrumentIds);
}

function* assignNotificationToInstruments(instrumentIds) {
    const action = yield takeMaybe([
        onSuccessful(CREATE_NOTIFICATION),
        onError(CREATE_NOTIFICATION)
    ]);

    if (action && action.type === FETCH_SUCCESS) {
        yield put(
            fetchDataAction(ASSIGN_NOTIFICATION_TO_INSTRUMENTS)(
                '/api/v1/instruments/notifications',
                {
                    method: 'post',
                    data: {
                        assignId: action.payload.id,
                        instrumentIds
                    }
                }
            )
        );
    }
}

function* retireUpdate({ updateId }) {
    yield put(
        fetchDataAction(RETIRE_UPDATE)(`/api/v1/notifications/${updateId}`, {
            method: 'delete'
        })
    );
    yield call(refreshUpdatesList, updateId);
}

function* refreshUpdatesList(updateId) {
    yield history.push('/updates');
    yield put({
        type: REMOVE_UPDATE_FROM_STORE,
        updateId
    });

    const action = yield takeMaybe([
        onSuccessful(RETIRE_UPDATE),
        onError(RETIRE_UPDATE)
    ]);

    if (action) {
        if (action.type === FETCH_SUCCESS) {
            yield put(
                openSnackbarAction({
                    messageId: messageId++,
                    message: 'Update was retired successfully.',
                    mode: 'success'
                })
            );
        } else {
            yield call(fetchUpdatesList);
        }
    }
}

function* refreshInstrumentsByUpdateAfterChangeStatus(
    updateId,
    instrumentId,
    updateStatus
) {
    const action = yield takeMaybe([
        onSuccessful(CHANGE_TRIGGERED_UPDATE_STATUS_FOR_INSTRUMENTS),
        onError(CHANGE_TRIGGERED_UPDATE_STATUS_FOR_INSTRUMENTS)
    ]);

    if (action) {
        yield put({
            type: REFRESH_INSTRUMENT_UPDATE_STATUS,
            updateId,
            instrumentId,
            notificationStatus: action.payload,
            actionStatus: action.type
        });
    }
}

function* watchTriggerSoftwareUpdate() {
    yield takeEvery(TRIGGER_SOFTWARE_UPDATE, triggerSoftwareUpdate);
}

function* watchFetchingTriggeredUpdatesList() {
    yield takeEvery(FETCH_UPDATES_ACTION, fetchUpdatesList);
}

function* watchFetchingInstrumentsByUpdate() {
    yield takeEvery(FETCH_INSTRUMENTS_BY_UPDATE, fetchInstrumentsByUpdate);
}

function* watchEveryTriggeredUpdateStatusChangeForInstruments() {
    yield takeEvery(
        CHANGE_TRIGGERED_UPDATE_STATUS_FOR_INSTRUMENTS,
        changeTriggeredUpdateStatusForInstruments
    );
}

function* watchRetireUpdate() {
    yield takeEvery(RETIRE_UPDATE, retireUpdate);
}

export function* updateSagas() {
    yield all([
        watchFetchingTriggeredUpdatesList(),
        watchFetchingInstrumentsByUpdate(),
        watchTriggerSoftwareUpdate(),
        watchEveryTriggeredUpdateStatusChangeForInstruments(),
        watchRetireUpdate()
    ]);
}
