import {
    FETCH_SUCCESS,
    DEFAULT_OPTIONS,
    FETCH_ERROR,
    FETCH_START
} from './constants';

const computeInitialState = options => {
    if (!Object.prototype.hasOwnProperty.call(options, 'initialState')) {
        return DEFAULT_OPTIONS.initialState;
    }
    return options.initialState;
};

const withStatus = fetchStatus => o => ({ ...o, fetchStatus });

const withPayloadErrorMessage = payload => o => {
    if (payload && payload.message) {
        return { ...o, message: payload.message };
    }
    return o;
};

export const fetchReducer = (namespace, options = DEFAULT_OPTIONS) => (
    state = computeInitialState(options),
    { type, payload, meta }
) => {
    if (meta && meta.namespace && meta.namespace !== namespace) {
        return state;
    }
    const contentPath = options.contentPath || DEFAULT_OPTIONS.contentPath;
    switch (type) {
        case FETCH_SUCCESS:
            return withStatus(FETCH_SUCCESS)(
                contentPath ? payload[contentPath] : payload
            );
        case FETCH_ERROR:
            return withStatus(FETCH_ERROR)(
                withPayloadErrorMessage(payload)({
                    ...state,
                    loading: false,
                    message:
                        'An error occurred by accessing back-end, please refresh or try again later!'
                })
            );
        case FETCH_START: {
            return {
                ...state,
                loading: true,
                fetchStatus: undefined
            };
        }
        default:
            return state;
    }
};
