import {
    FETCH_UPDATES_ACTION,
    FETCH_INSTRUMENTS_BY_UPDATE,
    TRIGGER_SOFTWARE_UPDATE,
    CLEAR_TRIGGER_SOFTWARE_UPDATE,
    CHANGE_TRIGGERED_UPDATE_STATUS_FOR_INSTRUMENTS,
    RETIRE_UPDATE
} from './constants';

export const fetchUpdatesListAction = () => ({
    type: FETCH_UPDATES_ACTION
});

export const fetchInstrumentsByUpdateAction = updateId => ({
    type: FETCH_INSTRUMENTS_BY_UPDATE,
    updateId
});

export const changeTriggeredUpdateStatusAction = ({
    updateId,
    instrumentId,
    updateStatus
}) => ({
    type: CHANGE_TRIGGERED_UPDATE_STATUS_FOR_INSTRUMENTS,
    updateId,
    instrumentId,
    updateStatus
});

export const triggerSoftwareUpdateAction = (
    path,
    instrumentIds,
    updateType
) => ({
    type: TRIGGER_SOFTWARE_UPDATE,
    path,
    instrumentIds,
    updateType
});

export const clearTriggerSoftwareUpdateAction = () => ({
    type: CLEAR_TRIGGER_SOFTWARE_UPDATE
});

export const retireUpdateAction = updateId => ({
    type: RETIRE_UPDATE,
    updateId
});
