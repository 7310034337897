export const FETCH_MODKIT_LIST = 'modkits.fetchModKitList';
export const REFRESH_MODKIT_LIST = 'modkits.refreshModKitList';
export const MODKIT_LIST_IS_UPDATING = 'modkits.modKitListUpdating';
export const CREATE_MODKIT = 'modkits.createModKit';
export const CLEAR_CREATE_MODKIT = 'modkits.clearCreateModKitStatus';
export const APPLY_MODKIT = 'modkits.applyModKit';
export const UNDO_MODKIT = 'modkits.undoModKit';
export const CLEAR_CHANGE_MODKIT_STATUS = 'modkits.clearChangeModKitStatus';
export const DELETE_MODKIT = 'modkits.deleteModKit';
export const RETIRE_MODKIT = 'modkits.retireModKit';
export const MODKIT_STATUS = 'modkit.displayStatus';
export const FETCH_TYPES = 'modkit.fetchTypes';
export const ADD_MODKIT_TO_STORE = 'modkits.addModkitToStore';
export const REMOVE_MODKIT_FROM_STORE = 'modkits.removeModKitFromStore';
export const CHANGE_MODKIT_STATUS = 'modkits.changeModkitStatus';
export const FETCH_INSTRUMENTS_BY_MODKIT = 'modkits.fetchInstrumentsByModKit';
export const CHANGE_MODKIT_STATUS_FOR_INSTRUMENT =
    'modkits.changeModKitStatusForInstrument';
export const EXPORT_MODKITS_MATRIX = 'modkits.exportMatrix';
