import React from 'react';
import PropertyList from '../../shared/PropertyList';
import { convertDate } from '../../../../common/utils/details';

export default data => {
    const auditProperties = data.lastModifiedAt
        ? {
              'Last modified at': convertDate(data.lastModifiedAt),
              'Last modified by': data.lastModifiedBy
          }
        : undefined;

    return (
        <>
            <PropertyList data={data.properties} label="Properties" />
            <PropertyList data={data.calculatedProperties} />
            {auditProperties && (
                <PropertyList data={auditProperties} label="Audit Properties" />
            )}
        </>
    );
};
