import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Button from '../../button/Button';
import { concatPropValues } from '../../../common/utils';
import SaveIcon from '../../image/SaveIcon';
import VersionSelector from '../actions/VersionSelector';
import ActionMenu from '../../actionMenu/ActionMenu';
import DeleteIcon from '../../image/DeleteIcon';
import { getOpenModalWindowAction } from '../../../controllers/modal';
import DeleteInstrumentForm from '../actions/DeleteInstrumentForm';
import { getActionsThatUserCanExecute } from '../../../common/utils/roles';
import ExportHtmlForm from '../export/ExportHtmlForm';
import styles from '../details.module.scss';

import {
    getInstrumentMaintenanceStatus,
    getInstrumentMaintenanceStatusMessage
} from '../../../common/utils/details';
import { downloadQcDataArchiveAction } from '../../../controllers/instrument/actions';

const DetailsHeader = ({
    data,
    onNavigateBack,
    withVersions,
    getOpenModalWindowAction,
    downloadQcDataArchiveAction,
    instrumentId,
    isDeleteInstrumentDisabled = false,
    isDownloadRevisionDisabled = false,
    roles
}) => {
    const actionItems = [
        {
            title: 'Export to HTML',
            icon: <SaveIcon />,
            isDisabled: isDownloadRevisionDisabled,
            onClick: () =>
                getOpenModalWindowAction(ExportHtmlForm, 'Export to HTML', {
                    data
                }),
            permission: 'instrument:downloadRevision'
        },
        {
            title: 'Export QC Data',
            icon: <SaveIcon />,
            isDisabled: data.qcData == null || data.qcData.length < 1,
            onClick: () => downloadQcDataArchiveAction(data.id, data.id),
            permission: 'instrument:downloadRevision'
        }
        // {
        //     title: 'Create Support Ticket',
        //     icon: <AddIcon />,
        //     isDisabled: false,
        //     onClick: () =>
        //         (window.location =
        //             'http://localhost:8080/api/v1/remote-support/create/' +
        //             data.id),
        //     permission: 'instrument:downloadRevision'
        // },
    ];
    if (
        window.location.hostname != 'config-management.tecan.com' &&
        window.location.hostname != 'config-management-stage.tecan.com'
    ) {
        actionItems.push({
            title: 'Delete Instrument',
            icon: <DeleteIcon />,
            isDisabled: isDeleteInstrumentDisabled,
            onClick: () =>
                getOpenModalWindowAction(
                    DeleteInstrumentForm,
                    'Delete Instrument',
                    {
                        instrumentId: instrumentId,
                        name: data.id
                    }
                ),
            permission: 'instrument:delete'
        });
    }

    const permittedActionItems = getActionsThatUserCanExecute(
        actionItems,
        roles
    ).filter(item => !item.isDisabled);

    const maintenanceStatus = getInstrumentMaintenanceStatus(
        data.maintenance,
        data.modules
    );

    return (
        <header className={`${styles.headerInst}`}>
            <span
                className={classnames([
                    styles.MaintenanceStatus,
                    maintenanceStatus
                ])}
                title={getInstrumentMaintenanceStatusMessage(
                    maintenanceStatus,
                    data.maintenance
                )}
            />
            <span className={styles.primaryData}>
                {`${data.type}, ${concatPropValues(
                    'Size',
                    data.size,
                    'SN',
                    data.serialNumber
                )}`}
            </span>
            {withVersions && (
                <span className={styles.audit}>
                    <VersionSelector
                        defaultVersion={data.currentRevision}
                        instrumentId={data.id}
                    />
                </span>
            )}
            <ActionMenu
                items={permittedActionItems}
                className={styles.DetailsActionMenu}
            />
            <Button title="Close" look="Clear" onClick={onNavigateBack}>
                &times;
            </Button>
        </header>
    );
};

DetailsHeader.propTypes = {
    data: PropTypes.object.isRequired,
    onNavigateBack: PropTypes.func.isRequired,
    isDeleteInstrumentDisabled: PropTypes.bool,
    isDownloadRevisionDisabled: PropTypes.bool
};

export default connect(
    ({ user: { roles } }) => ({ roles }),
    {
        getOpenModalWindowAction,
        downloadQcDataArchiveAction
    }
)(DetailsHeader);
