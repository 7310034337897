import {
    TOGGLE_ALL_INSTRUMENTS_SELECTION,
    TOGGLE_INSTRUMENT_SELECTION
} from '../constants';
import { FETCH_SUCCESS, FETCH_ERROR } from '../../fetch';

export const selectInstrumentsReducer = (
    state = [],
    { type, meta, instruments }
) => {
    switch (type) {
        case TOGGLE_ALL_INSTRUMENTS_SELECTION:
            return instruments;
        case TOGGLE_INSTRUMENT_SELECTION: {
            const instrument = instruments[0];
            if (state.indexOf(instrument) === -1) {
                return [...state, instrument];
            } else {
                return state.filter(s => s !== instrument);
            }
        }
        case FETCH_SUCCESS:
        case FETCH_ERROR:
            return meta.namespace === 'instruments' ? [] : state;
        default:
            return state;
    }
};
