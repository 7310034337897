import React from 'react';
import { string, array } from 'prop-types';
import Image from '../image/Image';
import Menu from '../menu/Menu';
import Profile from '../profile/Profile';
import './header.scss';
import logo from './logo.png';
import version from './version';
import { useFetch } from 'react-async';
const divStyle = {
    textAlign: 'right',
    fontSize: '10px',
    fontWeight: 'bold',
    marginTop: '2px',
    color: '#666666'
};

const Header = ({ username, userRoles }) => {
    return (
        <header className="Header">
            <div className="LogoWrapper">
                <Image
                    source={logo}
                    look="Logo"
                    alt="TECAN Configuration Management"
                />
                <div style={divStyle}>{version}</div>
            </div>
            {username && userRoles ? (
                <div className="Navigation">
                    <Menu userRoles={userRoles} />
                    <Profile username={username} userRoles={userRoles} />
                </div>
            ) : null}
        </header>
    );
};

Header.propTypes = {
    username: string,
    userRoles: array
};

export default Header;
