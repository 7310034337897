import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Link } from 'react-router-dom';
import { Map, Marker, Popup, TileLayer } from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import { FETCH_ERROR } from '../../controllers/fetch';
import { fetchInstrumentListAction } from '../../controllers/query';
import withData from '../query/results/withData';
import { MAP_CONFIG, MAP_TEMPLATE } from '../../common/utils/mapConfig';
import { isLocationValid } from '../../common/utils/verifyLocation';
import styles from './Map.module.scss';

const groupInstrumentsByCountry = (instrumentList = []) =>
    instrumentList.reduce((instrumentsByCountry, instrument) => {
        const { location } = instrument.metadata;
        if (isLocationValid(location)) {
            const country = location.countryName.toLowerCase();
            if (!instrumentsByCountry[country]) {
                instrumentsByCountry[country] = [];
            }
            instrumentsByCountry[country].push(instrument);
        }

        return instrumentsByCountry;
    }, {});

const renderMarkerClustersByCountry = instrumentList => {
    const instrumentsByCountry = groupInstrumentsByCountry(instrumentList);
    const countries = Object.keys(instrumentsByCountry);

    return countries.map(country => (
        <MarkerClusterGroup
            key={country}
            showCoverageOnHover={false}
            maxClusterRadius={zoom =>
                zoom === MAP_CONFIG.MIN_ZOOM ? Infinity : 80
            }
        >
            {instrumentsByCountry[country].map(
                ({ id, instrumentId, metadata: { location = {} } }) => (
                    <Marker
                        key={id}
                        position={[location.latitude, location.longitude]}
                    >
                        <Popup>
                            <Link to={`/query/${id}`} className={styles.Link}>
                                <div>Type: {instrumentId.type}</div>
                                <div>Size: {instrumentId.size}</div>
                                <div>SN: {instrumentId.serialNumber}</div>
                            </Link>
                        </Popup>
                    </Marker>
                )
            )}
        </MarkerClusterGroup>
    ));
};

const GlobalMap = ({ instrumentList }) => (
    <section className="PageLayout">
        <Map
            className={styles.MapContainer}
            center={[
                MAP_CONFIG.LATITUDE_GLOBAL_MAP,
                MAP_CONFIG.LONGITUDE_GLOBAL_MAP
            ]}
            zoom={MAP_CONFIG.MIN_ZOOM}
            minZoom={MAP_CONFIG.MIN_ZOOM}
            maxZoom={MAP_CONFIG.MAX_ZOOM}
            maxBounds={MAP_CONFIG.MAX_BOUNDS}
        >
            <TileLayer
                noWrap={true}
                url={MAP_TEMPLATE.URL}
                attribution={MAP_TEMPLATE.ATTRIBUTION}
            />
            {renderMarkerClustersByCountry(instrumentList)}
        </Map>
    </section>
);

export default compose(
    connect(
        ({ instruments }) => ({
            instrumentList: instruments.instruments,
            loading: !!instruments.loading,
            error: instruments.fetchStatus === FETCH_ERROR,
            errorMessage: instruments.message
        }),
        {
            fetchInstrumentListAction
        }
    ),
    withData
)(GlobalMap);
