import React from 'react';
import List from './List';
import NoData from './NoData';
import Title from './Title';
import getModificationDetails from './getModificationDetails';
import { renderModule } from '../tabs/logbook/renderLogbook';
import { hasElements } from '../../../common/utils';

const renderLogbookEntries = logbook =>
    logbook.map(({ id, operation, ...entry }) => (
        <div key={id} className="logbook-entry">
            <List
                list={[
                    { operation },
                    { module: renderModule(entry.module) },
                    entry,
                    getModificationDetails(entry.modified)
                ]}
            />
        </div>
    ));

const LogbookSection = ({ logbook = [] }) => (
    <>
        <Title className="level_1" title="Logbook" />
        {hasElements(logbook) ? renderLogbookEntries(logbook) : <NoData />}
    </>
);

export default LogbookSection;
