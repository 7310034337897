import {
    APPLY_MODKIT,
    CHANGE_MODKIT_STATUS_FOR_INSTRUMENT,
    UNDO_MODKIT
} from '../constants';
import { FETCH_ERROR, FETCH_SUCCESS } from '../../fetch';
import { INSTRUMENT_MODKIT_STATUS } from '../../../common/utils/details';

const changeModKitStatusForInstrument = ({
    instruments,
    instrumentId,
    operation,
    payload,
    actionStatus
}) => {
    return instruments.map(instrument => {
        if (instrument.id === instrumentId) {
            let updatedProperties = {};

            if (actionStatus === FETCH_ERROR) {
                updatedProperties = {
                    loading: false,
                    statusClass: 'error',
                    statusMessage:
                        'There was a problem executing the operation!'
                };
            } else if (actionStatus === FETCH_SUCCESS) {
                const status =
                    operation === 'apply'
                        ? INSTRUMENT_MODKIT_STATUS.APPLIED.toUpperCase()
                        : INSTRUMENT_MODKIT_STATUS.REVOKED.toUpperCase();

                updatedProperties = {
                    loading: false,
                    statusClass: null,
                    statusMessage: null,
                    status,
                    modified: payload.modified
                };
            }

            return { ...instrument, ...updatedProperties };
        }

        return instrument;
    });
};

const startChangeModKitStatusForInstrument = (instruments, instrumentId) => {
    const updatedProperties = {
        loading: true,
        statusClass: null,
        statusMessage: null
    };

    return instruments.map(
        instrument =>
            instrument.id === instrumentId
                ? { ...instrument, ...updatedProperties }
                : instrument
    );
};

export const changeModKitStatusForInstrumentReducer = (
    state,
    { type, instrumentId, operation, payload, actionStatus }
) => {
    switch (type) {
        case CHANGE_MODKIT_STATUS_FOR_INSTRUMENT: {
            if (!state.instruments) {
                return state;
            }

            return {
                ...state,
                instruments: changeModKitStatusForInstrument({
                    instruments: state.instruments,
                    instrumentId,
                    operation,
                    payload,
                    actionStatus
                })
            };
        }
        case APPLY_MODKIT:
        case UNDO_MODKIT: {
            if (!state.instruments) {
                return state;
            }

            return {
                ...state,
                instruments: startChangeModKitStatusForInstrument(
                    state.instruments,
                    instrumentId
                )
            };
        }
        default:
            return state;
    }
};
