import React, { Component } from 'react';
import Criterias from './Criterias';
import Button from '../button/Button';
import { PropTypes } from 'prop-types';
import { ENTER } from '../../common/constants';

import './querybuilder.scss';

class QueryBuilder extends Component {
    state = {
        valid: false,
        queryParams: ''
    };

    onChange = ({ queryParams, valid }) => {
        this.setState({ queryParams, valid });
    };

    sendQuery = () => {
        this.props.clearPresetFiltersAndSorting();
        this.props.queryInstrumentListAction({
            queryParams: this.state.queryParams
        });
        this.props.navigateOnSearch();
    };

    onKeyPress = event => {
        const { key } = event;
        if (key === ENTER && this.state.valid) {
            event.preventDefault();
            this.sendQuery();
        }
    };

    render = () => (
        <div className="QueryBuilder" onKeyPress={this.onKeyPress}>
            <Criterias
                queryParamsMetaData={this.props.queryParamsMetaData}
                onChange={this.onChange}
            />
            <div className="SubmitArea">
                <Button
                    look="Primary"
                    disabled={!this.state.valid}
                    onClick={this.sendQuery}
                    className="SubmitQuery"
                >
                    Submit
                </Button>
            </div>
        </div>
    );
}

QueryBuilder.propTypes = {
    queryParamsMetaData: PropTypes.shape({
        hardware: PropTypes.shape({
            properties: PropTypes.array,
            calculatedProperties: PropTypes.array
        }),
        hardwareModules: PropTypes.arrayOf(PropTypes.object),
        software: PropTypes.shape({
            properties: PropTypes.array
        }),
        metadata: PropTypes.array,
        modKits: PropTypes.shape({
            properties: PropTypes.array
        }),
        operators: PropTypes.object
    }).isRequired,
    queryInstrumentListAction: PropTypes.func.isRequired,
    navigateOnSearch: PropTypes.func.isRequired,
    clearPresetFiltersAndSorting: PropTypes.func
};

export { QueryBuilder };
