import { FETCH_SUCCESS } from '../fetch/constants';
import { CLEAR_INSTRUMENT_LIST } from './constants';

export const clearInstrumentList = (state, { type }) => {
    if (type === CLEAR_INSTRUMENT_LIST) {
        return {
            items: []
        };
    }
    return state;
};

export const normalizeQueryDefinitionData = (
    state,
    { type, meta, payload }
) => {
    if (type === FETCH_SUCCESS && meta.namespace === 'queryParamsMetaData') {
        const metaData = {
            operators: payload.operators,
            hardware: {
                properties: payload.hardwareDetailsProperties,
                calculatedProperties:
                    payload.calculatedHardwareDetailsProperties
            },
            software: {
                properties: payload.softwareDetailsProperties
            },
            hardwareModules: payload.hardwareModules.map(moduleData => ({
                name: moduleData.name,
                properties: moduleData.properties,
                calculatedProperties: moduleData.calculatedProperties
            })),
            metadata: payload.metadata,
            modKits: {
                properties: payload.modKits
            },
            hotfixes: {
                properties: payload.hotfixes
            }
        };
        return metaData;
    } else {
        return state;
    }
};
