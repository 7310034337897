import React from 'react';
import Input from '../input/Input';
import Button from '../button/Button';
import styles from './formContent.module.scss';

const FormContent = ({ handleSubmit, pristine, invalid, fileChange, data }) => (
    <form onSubmit={handleSubmit} className="UploadForm">
        <div className={styles.Info}>
            <p>
                <strong>File:</strong>{' '}
                {data.fileName ? data.fileName : 'No uploaded file'}
            </p>
            <p>
                <span>
                    <strong>Instrument:</strong>{' '}
                    {data.platformType ? data.platformType : 'N/A'}
                </span>
                <br />
                <span>
                    <strong>S/N:</strong>{' '}
                    {data.serialNumber ? data.serialNumber : 'N/A'}
                </span>
            </p>
        </div>
        <div className="ButtonGroup">
            <Input
                name="browseInstrumentData"
                type="file"
                label="Browse Instrument Data"
            >
                {({ input }) => (
                    <input
                        {...input}
                        type="file"
                        accept=".xml,.config"
                        onChange={fileChange}
                    />
                )}
            </Input>
            <Button
                look="Primary"
                behaviour="button"
                width="FullWidth"
                disabled={pristine || invalid || !data.platformType}
                onClick={handleSubmit}
            >
                Upload Instrument Data
            </Button>
        </div>
    </form>
);

export default FormContent;
