import { fetchDataAction, FETCH_SUCCESS } from '../fetch';
import { channel } from 'redux-saga';
import {
    put,
    takeEvery,
    all,
    take,
    call,
    takeLatest,
    takeMaybe
} from 'redux-saga/effects';

import {
    DELETE_INSTRUMENT,
    LOAD_INSTRUMENT_VERSIONS,
    DOWNLOAD_QCDATA_XML,
    DOWNLOAD_QCDATA_ARCHIVE
} from '../instrument/constants';

import { exportToXmlFromBlob } from '../../common/utils/exportToFile';
import history from '../../history';
import {
    removeInstrumentFromStoreAction,
    startLoadInstrumentVersionsAction
} from './actions';
import { onError, onSuccessful } from '../fetch/actions';
import { openSnackbarAction } from '../snackbar/actions';
import { SELECT_INSTRUMENT } from '../query/constants';

let messageId = 1;

const onHideChannel = channel();

function* fetchInstrument({ id, version }) {
    yield put(
        fetchDataAction('selectedInstrument')(`/api/v1/instruments/${id}`, {
            method: 'get',
            params: !isNaN(version)
                ? {
                      version: version
                  }
                : {}
        })
    );

    yield call(loadInstrumentVersions, id);
}

function* fetchInstrumentVersionsList({ instrumentId }) {
    yield put(
        fetchDataAction(LOAD_INSTRUMENT_VERSIONS)(
            `/api/v1/instruments/${instrumentId}`,
            {
                method: 'get',
                params: {}
            }
        )
    );
}

function* fetchQcDataXml({ id, fileName }) {
    yield put(
        fetchDataAction(DOWNLOAD_QCDATA_XML)(
            `/api/v1/instruments/${id}/qcdata-xml-download`,
            {
                method: 'get',
                headers: {
                    'Content-Type': 'application/xml'
                },
                responseType: 'blob'
            }
        )
    );

    yield call(downloadQcDataXmlFile, fileName);
}

function* fetchQcDataArchive({ instrumentId, fileName }) {
    yield put(
        fetchDataAction(DOWNLOAD_QCDATA_ARCHIVE)(
            `/api/v1/instruments/${instrumentId}/qcdata-download`,
            {
                method: 'get',
                headers: {
                    'Content-Type': 'application/zip'
                },
                responseType: 'blob'
            }
        )
    );

    yield call(downloadQcDataArchive, fileName);
}

function* loadInstrumentVersions(instrumentId) {
    const action = yield takeMaybe([onSuccessful('selectedInstrument')]);

    if (action) {
        yield put(startLoadInstrumentVersionsAction(instrumentId));
    }
}

function* deleteInstrument({ instrumentId }) {
    yield put(
        fetchDataAction(DELETE_INSTRUMENT)(
            `/api/v1/instruments/${instrumentId}`,
            {
                method: 'delete',
                params: {}
            }
        )
    );
    yield call(refreshInstrumentList, instrumentId);
}

function* refreshInstrumentList(instrumentId) {
    const action = yield takeMaybe([
        onSuccessful(DELETE_INSTRUMENT),
        onError(DELETE_INSTRUMENT)
    ]);

    if (action) {
        if (action.type === FETCH_SUCCESS) {
            yield put(removeInstrumentFromStoreAction(instrumentId));

            yield put(
                openSnackbarAction({
                    messageId: messageId++,
                    message: 'Instrument was deleted successfully.',
                    mode: 'success'
                })
            );
        }

        yield history.push('/query');
    }
}

function* downloadQcDataXmlFile(fileName) {
    const action = yield takeMaybe([
        onSuccessful(DOWNLOAD_QCDATA_XML),
        onError(DOWNLOAD_QCDATA_XML)
    ]);

    if (action && action.type === FETCH_SUCCESS) {
        exportToXmlFromBlob(action.payload, `${fileName}.xml`);
    }
}

function* downloadQcDataArchive(fileName) {
    const action = yield takeMaybe([
        onSuccessful(DOWNLOAD_QCDATA_ARCHIVE),
        onError(DOWNLOAD_QCDATA_ARCHIVE)
    ]);

    if (action && action.type === FETCH_SUCCESS) {
        exportToXmlFromBlob(action.payload, `${fileName}.zip`);
    }
}

function* watchQueryInstrument() {
    yield takeLatest(SELECT_INSTRUMENT, fetchInstrument);
}

function* watchGetInstrumentVersions() {
    yield takeEvery(LOAD_INSTRUMENT_VERSIONS, fetchInstrumentVersionsList);
}

function* watchDeleteInstrument() {
    yield takeEvery(DELETE_INSTRUMENT, deleteInstrument);
}

function* watchHideSnackbar() {
    while (true) {
        const action = yield take(onHideChannel);
        yield put(action);
    }
}

function* watchQcDataXmlDownload() {
    yield takeEvery(DOWNLOAD_QCDATA_XML, fetchQcDataXml);
}

function* watchQcDataArchiveDownload() {
    yield takeEvery(DOWNLOAD_QCDATA_ARCHIVE, fetchQcDataArchive);
}

export function* instrumentSagas() {
    yield all([
        watchGetInstrumentVersions(),
        watchDeleteInstrument(),
        watchQueryInstrument(),
        watchHideSnackbar(),
        watchQcDataXmlDownload(),
        watchQcDataArchiveDownload()
    ]);
}
