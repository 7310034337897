import React, { Component } from 'react';
import Selector from '../../selector/Selector';
import UserValue from './UserValue';

export default class OperatorAndValues extends Component {
    shouldComponentUpdate = newProps => {
        return (
            this.props.selected.operator !== newProps.selected.operator ||
            this.props.selected.values !== newProps.selected.values ||
            this.props.operatorOptions !== newProps.operatorOptions
        );
    };
    render = () => {
        const {
            operatorOptions,
            selected,
            onOperatorSelected,
            onUserValueChanged
        } = this.props;

        return (
            <>
                <li>
                    <Selector
                        label="Operator"
                        className="Operator"
                        options={operatorOptions}
                        selectedOption={
                            selected.operator && selected.operator.name
                        }
                        onSelectionChange={onOperatorSelected}
                    />
                </li>
                <li>
                    <UserValue
                        count={
                            selected.operator &&
                            selected.operator.numberOfArguments - 1
                        }
                        property={selected.property}
                        selectedValues={selected.values || []}
                        onUserValueChanged={onUserValueChanged}
                        operator={selected.operator}
                    />
                </li>
            </>
        );
    };
}
