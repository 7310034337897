import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { doesUserHavePermission } from '../../common/utils/roles';

const RoleBasedAccess = ({ roles, permission, children }) => (
    <>{doesUserHavePermission(roles, permission) ? children : null}</>
);

RoleBasedAccess.propTypes = {
    permission: PropTypes.string,
    roles: PropTypes.array
};

export default connect(
    ({ user: { roles } }) => ({ roles }),
    null
)(RoleBasedAccess);
