import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';

import { START_PERIOD, END_PERIOD, FORMAT } from './constants';
import './calendar.scss';

const Calendar = ({
    startDate,
    endDate,
    selectedPeriodDate,
    periodType,
    additionalModifiers,
    onDateChange,
    ...other
}) => {
    const onDayClick = (day, { selected, disabled }) => {
        if (disabled) {
            return;
        }

        if (
            selected &&
            day &&
            selectedPeriodDate &&
            moment(day).format(FORMAT) ===
                moment(selectedPeriodDate).format(FORMAT)
        ) {
            onDateChange('');
            return;
        }

        onDateChange(moment(day).format(FORMAT));
    };

    return (
        <DayPicker
            onDayClick={onDayClick}
            selectedDays={[
                startDate,
                endDate,
                { from: startDate, to: endDate }
            ]}
            modifiers={{
                start: startDate,
                end: endDate,
                ...additionalModifiers
            }}
            month={selectedPeriodDate || startDate || endDate}
            toMonth={periodType === START_PERIOD ? endDate : null}
            fromMonth={periodType === END_PERIOD ? startDate : null}
            {...other}
        />
    );
};

Calendar.propTypes = {
    startDate: PropTypes.object,
    endDate: PropTypes.object,
    selectedPeriodDate: PropTypes.object,
    periodType: PropTypes.oneOf([START_PERIOD, END_PERIOD]),
    additionalModifiers: PropTypes.object,
    onDateChange: PropTypes.func
};

export default Calendar;
