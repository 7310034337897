import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './drawer.module.scss';

const Drawer = props => {
    const {
        defaultCollapsed,
        children,
        expandedClassName,
        collapsedClassName
    } = props;
    const [isCollapsed, setIsCollapsed] = useState(defaultCollapsed);

    useEffect(() => setIsCollapsed(defaultCollapsed), [defaultCollapsed]);

    const onToggle = () => {
        setIsCollapsed(!isCollapsed);
    };

    return (
        <div
            className={classnames(
                styles.DrawerContainer,
                isCollapsed ? collapsedClassName : expandedClassName
            )}
        >
            <div
                className={styles.DrawerContent}
                style={{ display: isCollapsed ? 'none' : 'block' }}
            >
                {children}
            </div>
            <div className={isCollapsed ? styles.Collapsed : styles.Expanded}>
                <div className={styles.DrawerIndicator} onClick={onToggle} />
            </div>
        </div>
    );
};

Drawer.propTypes = {
    defaultCollapsed: PropTypes.bool,
    children: PropTypes.any
};

export default Drawer;
