import React, { useEffect, useState } from 'react';
import TextInput from './common/TextInput';
import PropTypes from 'prop-types';
import { convertDate, convertLocalDate } from '../../common/utils';
import { Link } from 'react-router-dom';

function SupportForm(props) {
    const { users, timeZone } = props;

    const renderListItems = items => {
        return items.map(({ id, name }) => (
            <option key={id} value={name + ' (' + id + ')'}>
                {name}
            </option>
        ));
    };

    return (
        <div className="container text-left">
            <br />
            <br />
            <div>{props.errors.loading}</div>
            <div className="card">
                <div className="card-header">
                    <Link
                        to={'/supports/'}
                        className="btn btn-sm bg-white float-right"
                    >
                        Close
                    </Link>
                    <h4>Support Request Details</h4>
                </div>
                <form onSubmit={props.onSubmit}>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-6 text-left">
                                <ul className="list-group list-group-flush">
                                    <li className="list-group-item">
                                        <button
                                            onClick={props.delete}
                                            className="btn btn btn-light float-right text-danger"
                                        >
                                            Delete
                                        </button>
                                        <Link
                                            className="btn btn-outline-info"
                                            to={
                                                '/query/' +
                                                props.support.instrumentId
                                            }
                                        >
                                            Instrument Details
                                        </Link>{' '}
                                        <Link
                                            className="btn btn-outline-info"
                                            to={
                                                '/query/' +
                                                props.support.instrumentId +
                                                '/logbook'
                                            }
                                        >
                                            Logbooks
                                        </Link>
                                    </li>
                                    <li className="list-group-item">
                                        <b>Service Notification Number:</b>{' '}
                                        {
                                            props.support
                                                .serviceNotificationNumber
                                        }
                                    </li>
                                    <li className="list-group-item">
                                        <b>Alias:</b> {props.support.alias}
                                    </li>
                                    <li className="list-group-item">
                                        <b>Instrument:</b>{' '}
                                        {props.support.instrumentId}
                                    </li>
                                    <li className="list-group-item">
                                        <b>Request Time:</b>{' '}
                                        {convertDate(props.support.requestTime)}
                                    </li>
                                    <li className="list-group-item">
                                        <b>Customer Name:</b>{' '}
                                        {props.support.name}
                                    </li>
                                    <li className="list-group-item">
                                        <b>Location:</b>{' '}
                                        <Link
                                            className="text-primary"
                                            style={{ display: 'inline' }}
                                            to={
                                                '/query/' +
                                                props.support.instrumentId +
                                                '/map'
                                            }
                                        >
                                            {props.support.clientLocation}
                                        </Link>
                                    </li>
                                    <li className="list-group-item">
                                        <b>Description:</b>{' '}
                                        {props.support.description}
                                    </li>
                                    <li className="list-group-item">
                                        <b>Client Link:</b>{' '}
                                        <span className="text-secondary">
                                            ( valid until:{' '}
                                            {convertDate(
                                                props.support.sessionTimeout
                                            )}{' '}
                                            )
                                        </span>{' '}
                                        <a
                                            target="_blank"
                                            href={props.support.clientLink}
                                        >
                                            {props.support.clientLink}
                                        </a>
                                    </li>
                                    <li className="list-group-item">
                                        <b>Supporter Link:</b>
                                        <a
                                            target="_blank"
                                            href={props.support.supporterLink}
                                        >
                                            {props.support.supporterLink}
                                        </a>
                                    </li>
                                    <li className="list-group-item">
                                        <b>Session Time:</b>{' '}
                                        {convertDate(
                                            props.support.sessionTimeGMT
                                        )}
                                    </li>
                                    <li className="list-group-item">
                                        <b>Client Local Session Time:</b>{' '}
                                        {convertLocalDate(
                                            props.support.clientSessionTime
                                        )}{' '}
                                        <span className="text-secondary">
                                            {' '}
                                            ( {props.support.clientTimeZone} )
                                        </span>
                                    </li>
                                </ul>
                                <br />
                            </div>
                            <div className="col-md-6 text-left">
                                <label htmlFor="supporter">Supporter</label>
                                <select
                                    className="form-control"
                                    name="supporterName"
                                    id="supporterName"
                                    onChange={props.onChange}
                                    value={props.support.supporterName || ''}
                                >
                                    <option>Select</option>
                                    {renderListItems(users)}
                                </select>
                                <div />
                                <div className="form-group">
                                    <label htmlFor="author">Status</label>
                                    <div className="field">
                                        <select
                                            id="status"
                                            name="status"
                                            onChange={props.onChange}
                                            value={props.support.status || ''}
                                            className="form-control"
                                        >
                                            <option>Open</option>
                                            <option>Scheduled</option>
                                        </select>
                                    </div>
                                    {props.errors.status && (
                                        <div className="alert alert-danger">
                                            {props.errors.status}
                                        </div>
                                    )}
                                </div>

                                <label>Message to client</label>
                                <textarea
                                    className="form-control"
                                    rows="6"
                                    id="noteForClient"
                                    label="noteForClient"
                                    name="noteForClient"
                                    onChange={props.onChange}
                                    value={props.support.noteForClient}
                                    error={props.errors.noteForClient}
                                />
                                <br />
                                <div className="row">
                                    <div className="col-sm-12">
                                        <label>Session Time</label>
                                    </div>
                                    <div className="col-sm-6">
                                        {' '}
                                        <input
                                            className="form-control"
                                            type="date"
                                            name="sessionDate"
                                            id="sessionDate"
                                            onChange={props.onChange}
                                            value={props.support.sessionDate}
                                        />
                                    </div>
                                    <div className="col-sm-6">
                                        {' '}
                                        <input
                                            className="form-control"
                                            type="time"
                                            name="sessionTime"
                                            id="sessionTime"
                                            onChange={props.onChange}
                                            value={props.support.sessionTime}
                                        />
                                    </div>
                                    <div className="col-sm-12">
                                        <br />
                                        <label>
                                            Current Timezone:{' '}
                                            {timeZone.browserTimeZoneOffset}{' '}
                                            {timeZone.browserTimeZoneName}
                                        </label>
                                        <select
                                            className="form-control"
                                            id="sessionTimeZone"
                                            name="sessionTimeZone"
                                            onChange={props.onChange}
                                            value={
                                                props.support.sessionTimeZone
                                            }
                                        >
                                            <option
                                                value={
                                                    timeZone.browserTimeZoneOffset
                                                }
                                            >
                                                {timeZone.browserTimeZoneOffset}{' '}
                                                {timeZone.browserTimeZoneName}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div>
                                    <a
                                        style={{
                                            display: props.support.clientLink
                                                ? 'inline-block'
                                                : 'none',
                                            marginBottom: 10,
                                            marginTop: 10
                                        }}
                                        href={
                                            'api/v1/remote-support/connect?url=' +
                                            props.support.supporterLink +
                                            '&guid=' +
                                            props.support.guid +
                                            '&clientLink=' +
                                            props.support.clientLink
                                        }
                                        target="_blank"
                                        className="btn btn-light btn-lg float-left"
                                    >
                                        Start Session
                                    </a>
                                    <div>{props.errors.title}</div>
                                    <input
                                        style={{
                                            marginBottom: 10,
                                            marginTop: 10
                                        }}
                                        type="submit"
                                        value="Update"
                                        className="btn btn-info btn-lg float-right"
                                    />
                                </div>
                            </div>

                            <div className="col-md-12">
                                <div
                                    className="alert alert-warning"
                                    style={{
                                        paddingTop: 5,
                                        paddingLeft: 10,
                                        paddingRight: 10
                                    }}
                                >
                                    <label>Internal note</label>
                                    <textarea
                                        className="form-control"
                                        rows="3"
                                        id="note"
                                        label="note"
                                        name="note"
                                        onChange={props.onChange}
                                        value={props.support.note}
                                        error={props.errors.note}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}

SupportForm.propTypes = {
    support: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    errors: PropTypes.object.isRequired
};

export default SupportForm;
