export const buildQuery = (queryParams, fields, includeInstrumentId = true) => {
    const params = Array.isArray(queryParams) ? queryParams : [];

    const instrumentIdFields = includeInstrumentId
        ? 'instrumentId{ type size serialNumber }'
        : '';
    return `query {instruments(params: [${params
        .map(p => serializeQueryParam(p))
        .join(',')}] ){id ${instrumentIdFields} ${fields} } }`;
};

const serializeQueryParam = param =>
    `{field:"${param.field}",type:"${param.type}",operator:"${
        param.operator
    }",resource:"${param.resource}",values:${JSON.stringify(param.values)}}`;
