import { fetchReducer } from '../controllers/fetch';
import { uploadReducer } from '../controllers/upload';
import {
    createModkitReducer,
    changeModKitStatusReducer,
    changeModKitStatusForInstrumentReducer,
    modkitStatusReducer,
    modkitOrderingReducer,
    modifyModkitListReducer,
    formatInstrumentsByModkitResponse
} from '../controllers/modkit/reducer';

import {
    createHotfixReducer,
    changeHotFixStatusReducer,
    changeHotFixStatusForInstrumentReducer,
    hotfixStatusReducer,
    hotfixOrderingReducer,
    modifyHotfixListReducer,
    formatInstrumentsByHotfixResponse,
    promoteHotFixReducer
} from '../controllers/hotfix/reducer';

import { instrumentModkitsOrderingReducer } from '../controllers/instrument/reducers/orderInstrumentModkits';
import { instrumenthotfixesOrderingReducer } from '../controllers/instrument/reducers/orderInstrumentHotfixes';

import { instrumentVersionsReducer } from '../controllers/instrument/reducers/instrumentVersions';
import {
    normalizeQueryDefinitionData,
    clearInstrumentList
} from '../controllers/query/reducers';
import { modalWindowReducer } from '../controllers/modal';
import {
    createTagReducer,
    deleteTagReducer,
    assignTagReducer,
    unassignTagReducer,
    orderTagListReducer,
    instrumentsByTagReducer
} from '../controllers/tags/reducers';
import { selectInstrumentsReducer } from '../controllers/instrument/reducers/selectInstruments';
import { snackbarReducer } from '../controllers/snackbar/reducers';
import { LOAD_INSTRUMENT_VERSIONS } from '../controllers/instrument/constants';
import { selectedInstrumentVersionsInitialState } from './initialState';
import {
    FETCH_INSTRUMENTS_BY_TAG,
    ASSIGN_TAG_TO_INSTRUMENTS,
    UNASSIGN_TAG_FROM_INSTRUMENTS,
    CREATE_TAG,
    DELETE_TAG
} from '../controllers/tags/constants';
import { FETCH_INSTRUMENTS_BY_UPDATE } from '../controllers/update/constants';
import { FETCH_INSTRUMENTS_BY_MODKIT } from '../controllers/modkit/constants';
import {
    changeTriggeredUpdateStatusReducer,
    triggerSoftwareUpdateReducer,
    fetchUpdatesReducer
} from '../controllers/update/reducers';
import { deleteInstrumentReducer } from '../controllers/instrument/reducers/deleteInstrument';
import { chanegMaintenanceStatusReducer } from '../controllers/instrument/reducers/changeMaintenanceStatus';
import { addLogbookEntryReducer } from '../controllers/logbook/reducers';
import { updateLocationReducer } from '../controllers/location/reducers';
import { createSupportReducer } from '../controllers/support/reducers';
import { FETCH_INSTRUMENTS_BY_HOTFIX } from '../controllers/hotfix/constants';

const compose = (...reducersToCompose) => (state, action) => {
    let newState = state;
    for (let i = 0; i < reducersToCompose.length; i++) {
        newState = reducersToCompose[i](newState, action);
    }

    return newState;
};

export default {
    user: fetchReducer('user', {
        initialState: {}
    }),
    instruments: compose(
        fetchReducer('instruments', {
            initialState: {}
        }),
        deleteInstrumentReducer,
        chanegMaintenanceStatusReducer
    ),
    selectedInstrument: compose(
        fetchReducer('selectedInstrument', {
            initialState: { loading: true }
        }),
        instrumentModkitsOrderingReducer,
        instrumenthotfixesOrderingReducer,
        addLogbookEntryReducer,
        updateLocationReducer
    ),
    selectedInstrumentVersions: compose(
        fetchReducer(LOAD_INSTRUMENT_VERSIONS, {
            initialState: selectedInstrumentVersionsInitialState
        }),
        instrumentVersionsReducer
    ),
    queryParamsMetaData: compose(
        fetchReducer('queryParamsMetaData', {
            initialState: {}
        }),
        normalizeQueryDefinitionData
    ),

    createHotFix: compose(
        fetchReducer('createHotFix', {
            initialState: {}
        }),
        createHotfixReducer
    ),

    promoteHotFix: compose(
        fetchReducer('promoteHotFix', {
            initialState: {}
        }),
        promoteHotFixReducer
    ),

    hotfixes: compose(
        fetchReducer('hotfixes', {
            initialState: { items: [] }
        }),
        hotfixOrderingReducer,
        modifyHotfixListReducer
    ),
    hotfix: hotfixStatusReducer,
    changeHotFixStatus: compose(
        fetchReducer('changeHotFixStatus', {
            initialState: {}
        }),
        changeHotFixStatusReducer
    ),
    'applyHotFix.instruments': compose(
        fetchReducer('applyHotFix.instruments', {
            initialState: {}
        }),
        clearInstrumentList,
        chanegMaintenanceStatusReducer
    ),

    createModKit: compose(
        fetchReducer('createModKit', {
            initialState: {}
        }),
        createModkitReducer
    ),
    modkits: compose(
        fetchReducer('modkits', {
            initialState: { items: [] }
        }),
        modkitOrderingReducer,
        modifyModkitListReducer
    ),
    modkit: modkitStatusReducer,
    changeModKitStatus: compose(
        fetchReducer('changeModKitStatus', {
            initialState: {}
        }),
        changeModKitStatusReducer
    ),
    'applyModKit.instruments': compose(
        fetchReducer('applyModKit.instruments', {
            initialState: {}
        }),
        clearInstrumentList,
        chanegMaintenanceStatusReducer
    ),

    upload: compose(
        fetchReducer('upload', {
            initialState: {}
        }),
        uploadReducer
    ),
    modal: modalWindowReducer,
    snackbar: snackbarReducer,
    tags: compose(
        fetchReducer('tags', {
            initialState: { items: undefined }
        }),
        orderTagListReducer
    ),
    createTag: compose(
        fetchReducer(CREATE_TAG, {
            initialState: {}
        }),
        createTagReducer
    ),

    supports: compose(
        fetchReducer('supports', {
            initialState: {}
        }),
        createSupportReducer
    ),

    users: compose(
        fetchReducer('users', {
            initialState: {}
        }),
        createSupportReducer
    ),

    deleteTag: compose(
        fetchReducer(DELETE_TAG, {
            initialState: {}
        }),
        deleteTagReducer
    ),
    assignTagToInstruments: compose(
        fetchReducer(ASSIGN_TAG_TO_INSTRUMENTS, {
            initialState: {}
        }),
        assignTagReducer
    ),
    unassignTagFromInstruments: compose(
        fetchReducer(UNASSIGN_TAG_FROM_INSTRUMENTS, {
            initialState: {}
        }),
        unassignTagReducer
    ),
    selectedInstruments: selectInstrumentsReducer,
    instrumentsByTag: compose(
        fetchReducer(FETCH_INSTRUMENTS_BY_TAG, {
            initialState: {}
        }),
        instrumentsByTagReducer,
        chanegMaintenanceStatusReducer
    ),
    triggerSoftwareUpdate: triggerSoftwareUpdateReducer,
    updates: compose(
        fetchReducer('updates', {
            initialState: { items: undefined }
        }),
        fetchUpdatesReducer
    ),
    instrumentsByUpdate: compose(
        fetchReducer(FETCH_INSTRUMENTS_BY_UPDATE, {
            initialState: {}
        }),
        changeTriggeredUpdateStatusReducer
    ),
    instrumentsByModKit: compose(
        fetchReducer(FETCH_INSTRUMENTS_BY_MODKIT, {
            initialState: {}
        }),
        formatInstrumentsByModkitResponse,
        changeModKitStatusForInstrumentReducer
    ),
    instrumentsByHotFix: compose(
        fetchReducer(FETCH_INSTRUMENTS_BY_HOTFIX, {
            initialState: {}
        }),
        formatInstrumentsByHotfixResponse,
        changeHotFixStatusForInstrumentReducer
    ),
    platformTypes: fetchReducer('platformTypes', {
        initialState: {
            items: []
        }
    })
};
