import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';

import Confirmation from '../confirmation/Confirmation';
import Loading from '../loading/Loading';
import { retireUpdateAction } from '../../controllers/update';

import { UPDATE_STATUS, getKeyByValue } from '../../common/utils/updates';

const RetireUpdateForm = props => {
    const { instruments, retireUpdateAction, id, name, loading } = props;

    const filterByStatusNew = () =>
        instruments.filter(
            inst =>
                inst.notificationStatus.id === id &&
                inst.notificationStatus.status ===
                    getKeyByValue(UPDATE_STATUS, UPDATE_STATUS.NEW)
        );

    const countInstrumentsInStatusNew = filterByStatusNew().length;

    const message = parse(
        `You are about to retire update <span class="ConfirmMessageHighlight">'${name}'</span> from the system. <br /> The update will be cancelled for all ${countInstrumentsInStatusNew} instrument(s) where the update is in status 'NEW'`
    );

    return loading ? (
        <Loading loading={loading} imageSize={48} />
    ) : (
        <Confirmation
            onConfirm={() => retireUpdateAction(id)}
            message={message}
        />
    );
};

RetireUpdateForm.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    instruments: PropTypes.array,
    retireUpdateAction: PropTypes.func
};

export default connect(
    ({ instrumentsByUpdate: { items, loading } }) => ({
        instruments: items || [],
        loading
    }),
    {
        retireUpdateAction
    }
)(RetireUpdateForm);
