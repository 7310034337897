import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';

import {
    deleteHotFixAction,
    fetchHotFixListAction,
    retireHotFixAction,
    exportHotfixesMatrixAction,
    fetchTypesAction
} from '../../controllers/hotfix/actions';
import { getOpenModalWindowAction } from '../../controllers/modal';
import { FETCH_ERROR } from '../../controllers/fetch';
import CheckErrors from '../statusMessage/CheckErrors';
import PageWithLeftSidebar from '../../container/PageWithLeftSidebar';
import FilteredList from '../../container/FilteredList';
import { DeleteIcon, RetireIcon, AddIcon, SaveIcon } from '../image';
import Button from '../button/Button';
import {
    HOTFIX_STATUS,
    formatHotFixStatus
} from '../../common/utils/hotfixdetails';
import DiscardHotFixForm from './DiscardHotFixForm';
import CreateHotFix from './CreateHotFix';
import Autocomplete from '../autocomplete/Autocomplete';
import ActionMenu from '../actionMenu/ActionMenu';

import styles from './hotfix.module.scss';

const getSelectedHotfixActionObject = (hotfixes, selectedId, actions) => {
    const selectedItem =
        hotfixes.find(hotfix => hotfix.id === selectedId) || null;
    const status = selectedItem ? selectedItem.status : '';

    let actionIcon;
    let actionText;
    let modalWindowOptions = {};

    switch (status.toLowerCase()) {
        case HOTFIX_STATUS.NEW.toLowerCase():
            actionIcon = DeleteIcon;
            actionText = 'Delete';
            modalWindowOptions = {
                confirmAction: actions.deleteHotFixAction,
                title: 'Delete HotFix',
                operation: 'delete'
            };
            break;
        case HOTFIX_STATUS.USED.toLowerCase():
            actionIcon = RetireIcon;
            actionText = 'Retire';
            modalWindowOptions = {
                confirmAction: actions.retireHotFixAction,
                title: 'Retire HotFix',
                operation: 'retire'
            };
            break;
        default:
            actionIcon = null;
            actionText = null;
            modalWindowOptions = null;
            break;
    }

    let onItemAction = null;
    if (modalWindowOptions) {
        onItemAction = hotfix => {
            actions.getOpenModalWindowAction(
                DiscardHotFixForm,
                modalWindowOptions.title,
                {
                    name: hotfix.documentNumber,
                    id: hotfix.id,
                    confirmAction: modalWindowOptions.confirmAction,
                    operation: modalWindowOptions.operation
                }
            );
        };
    }

    return {
        actionIcon,
        actionText,
        onItemAction
    };
};

const HotFixesWithData = props => {
    const {
        children,
        error,
        errorMessage,
        loading,
        hotfixList,
        selectedId,
        platformTypes,
        deleteHotFixAction,
        fetchHotFixListAction,
        retireHotFixAction,
        getOpenModalWindowAction,
        fetchTypesAction,
        loadingPlatformTypes,
        exportHotfixesMatrixAction
    } = props;
    const [platformType, setPlatformType] = useState();
    const [hotfixes, setHotfixes] = useState([]);
    const [filteredHotfixes, setFilteredHotfixes] = useState([]);
    const [disableMenuActions, setDisableMenuActions] = useState(true);

    useEffect(() => {
        fetchHotFixListAction();
    }, []);

    useEffect(() => {
        fetchTypesAction();
    }, []);

    useEffect(
        () => {
            const modifiedHotFixs = hotfixList.map(m => ({
                ...m,
                description: m.name,
                name: `${m.documentNumber} ${
                    m.status === 'RETIRED'
                        ? `(${formatHotFixStatus(m.status)})`
                        : ''
                }`,
                className:
                    HOTFIX_STATUS[m.status] === HOTFIX_STATUS.RETIRED
                        ? 'FilteredListItemInactive'
                        : null
            }));
            setHotfixes(modifiedHotFixs);
        },
        [hotfixList]
    );

    useEffect(
        () => {
            setDisableMenuActions(!platformType);

            if (platformType) {
                setFilteredHotfixes(
                    hotfixes.filter(m => m.platformType === platformType)
                );
            } else {
                setFilteredHotfixes(hotfixes);
            }
        },
        [hotfixes, platformType]
    );

    const menuActionItems = [
        {
            title: 'Export HotFixs Matrix',
            icon: <SaveIcon />,
            isDisabled: false,
            onClick: () =>
                exportHotfixesMatrixAction({
                    platformType,
                    hotfixes: hotfixList
                })
        }
    ];

    const renderAdditionalFilters = () => (
        <div className="AdditionalFilterContainer">
            <div className="AdditionalFilterLabel">PLATFORM</div>
            <div className="AdditionalFilterInput">
                <Autocomplete
                    controlledValue={platformType}
                    onValueChange={setPlatformType}
                    placeholder="Select..."
                    items={platformTypes
                        .sort()
                        .map(value => ({ value, id: value }))}
                    showAllSuggestionsOnFocus={true}
                    loading={loading}
                />
            </div>
        </div>
    );

    const renderActionMenu = () =>
        disableMenuActions ? null : (
            <ActionMenu
                items={menuActionItems}
                className={classnames([styles.PlatformActions])}
            />
        );

    const {
        actionIcon,
        actionText,
        onItemAction
    } = getSelectedHotfixActionObject(filteredHotfixes, selectedId, {
        deleteHotFixAction,
        retireHotFixAction,
        getOpenModalWindowAction
    });

    return (
        <CheckErrors error={error} errorMessage={errorMessage}>
            <PageWithLeftSidebar
                loading={loading}
                renderSidebarContent={() => (
                    <>
                        <FilteredList
                            label="hotfixes"
                            items={filteredHotfixes}
                            selectedId={selectedId}
                            linkTo="/hotfixes"
                            actionIcon={actionIcon}
                            actionText={actionText}
                            onItemAction={onItemAction}
                            loading={!!loadingPlatformTypes}
                            renderAdditionalFilters={renderAdditionalFilters}
                            renderActionMenu={renderActionMenu}
                        />
                        <Button
                            look="Transparent"
                            onClick={() =>
                                getOpenModalWindowAction(
                                    CreateHotFix,
                                    'Create HotFix'
                                )
                            }
                            className={styles.CreateHotFixButton}
                        >
                            <AddIcon className={styles.AddIcon} />
                        </Button>
                    </>
                )}
                renderMainContent={() => children}
            />
        </CheckErrors>
    );
};

export default connect(
    ({ hotfixes, platformTypes }) => ({
        hotfixList: hotfixes.items,
        loading: !!hotfixes.loading,
        error: hotfixes.fetchStatus === FETCH_ERROR,
        errorMessage: hotfixes.message,
        platformTypes: platformTypes.items,
        loadingPlatformTypes: platformTypes.loading
    }),
    {
        fetchHotFixListAction,
        deleteHotFixAction,
        retireHotFixAction,
        getOpenModalWindowAction,
        fetchTypesAction,
        exportHotfixesMatrixAction
    }
)(HotFixesWithData);
