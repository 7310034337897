import React from 'react';
import { convertDate } from '../../common/utils/details';

const ModKitInfo = props => {
    const {
        modified: { at, firstName, lastName, username },
        documentNumber,
        name,
        platformType,
        status
    } = props;

    return (
        <div className="InformationBlock">
            <div className="Row">
                <span className="Label">Document Number:</span>
                <span className="Value">{documentNumber}</span>
            </div>
            <div className="Row">
                <span className="Label">Description:</span>
                <span className="Value">{name}</span>
            </div>
            <div className="Row">
                <span className="Label">Platform Type:</span>
                <span className="Value">{platformType}</span>
            </div>
            <div className="Row">
                <span className="Label">Status:</span>
                <span className="Value">{status}</span>
            </div>
            <div className="Row">
                <span className="Label">Modified By:</span>
                <span className="Value">
                    {firstName} {lastName} {username}
                </span>
            </div>
            <div className="Row">
                <span className="Label">Modified At:</span>
                <span className="Value">{convertDate(at)}</span>
            </div>
        </div>
    );
};

export default ModKitInfo;
