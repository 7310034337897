import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Tab from './Tab';
import './tabs.scss';
import { doesUserHavePermission } from '../../common/utils/roles';
import { connect } from 'react-redux';

const Tabs = props => {
    const { children, level, direction, withShadow, roles, currentTab } = props;

    const defaultActiveTab =
        currentTab ||
        (Array.isArray(props.children)
            ? props.children[0].props.label
            : props.children.props.label);

    const [activeTab, setActiveTab] = useState(defaultActiveTab);

    useEffect(
        () => {
            currentTab && setActiveTab(currentTab);
        },
        [currentTab]
    );

    const onClickTabItem = tab => {
        setActiveTab(tab);
        props.setActiveTab && props.setActiveTab(tab);
    };

    const shadowClassname = withShadow === true ? 'Shadow' : null;

    const getTabContentWhenPermission = tab => {
        const {
            props: { permission }
        } = tab;

        if (permission && !doesUserHavePermission(roles, permission)) {
            return null;
        }

        return tab;
    };

    return (
        <div className={classnames('Tabs', `Tabs--${direction}`)}>
            <ol className={classnames('TabList', `TabList--${level}`)}>
                {React.Children.map(children, child => {
                    const tab = getTabContentWhenPermission(child);
                    if (!tab) {
                        return null;
                    }

                    const { label } = tab.props;

                    return (
                        <Tab
                            activeTab={activeTab}
                            key={label}
                            label={label}
                            onClick={onClickTabItem}
                        />
                    );
                })}
            </ol>
            <div className={classnames('TabContent', shadowClassname)}>
                {React.Children.map(children, child => {
                    const tab = getTabContentWhenPermission(child);
                    if (
                        !tab ||
                        tab.props.label.toLowerCase() !==
                            activeTab.toLowerCase()
                    )
                        return null;
                    return tab.props.children;
                })}
                {props.renderFooter && (
                    <div className="TabFooter">{props.renderFooter()}</div>
                )}
            </div>
        </div>
    );
};

Tabs.propTypes = {
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
        .isRequired,
    level: PropTypes.number,
    direction: PropTypes.oneOf(['vertical', 'horizontal']),
    withShadow: PropTypes.bool
};

Tabs.defaultProps = {
    level: 1,
    direction: 'horizontal',
    withShadow: false
};

export default connect(({ user: { roles } }) => ({ roles }))(Tabs);
