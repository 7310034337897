import {
    UPLOAD_INSTRUMENT,
    UPLOAD_CLEAR,
    UPLOAD_INVALID_FILE_SELECTED
} from './constants';

export const uploadInstrumentAction = ({ fileName, fileContent }) => {
    const formData = new FormData();

    formData.append('name', fileName);
    formData.append('file', new Blob([fileContent], { type: 'text/xml' }));

    return {
        type: UPLOAD_INSTRUMENT,
        formData
    };
};

export const clearUploadInstrumentAction = () => ({
    type: UPLOAD_CLEAR
});

export const invalidFileSelectedAction = ({ message, statusClass }) => ({
    type: UPLOAD_INVALID_FILE_SELECTED,
    message,
    statusClass
});
