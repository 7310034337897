import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Field } from 'react-final-form';
import styles from './input.module.scss';
import { ComponentWithLabelAndErrorMessage } from './ComponentWithLabelAndErrorMessage';
import errors from './errorMessages.json';
import './input.scss';

const isRequired = value => (value ? undefined : errors['Required']);

const defaultValidation = value => {
    if (value.trim() === '') {
        return errors['Empty'];
    }
    if (value.match(/^\s/i) || value.match(/\s$/i)) {
        return errors['Spaces'];
    }
    return undefined;
};

const Input = ({
    name,
    label,
    type,
    accept,
    validate,
    component,
    className,
    styleNames,
    children,
    required,
    ...rest
}) => {
    const labelClasses = classnames(styles.Label);

    const inputClasses = classnames(className, styleNames, {
        [styles.Select]: type === 'dropdown',
        [styles.Input]: type === 'text',
        [styles.Multiline]: type === 'multiline'
    });

    return (
        <Field
            name={name}
            validate={value => {
                if (typeof validate === 'function') return validate(value);
                if (value && type === 'text') {
                    return defaultValidation(value);
                }
                if (required) return isRequired(value);
            }}
            subscription={{
                invalid: true,
                value: true,
                active: true,
                error: true,
                touched: true,
                pristine: true,
                modified: true
            }}
            className={inputClasses}
            component={component}
        >
            {({ input, meta }) => {
                let error = '';
                if (meta.touched && meta.error) {
                    error = meta.error;
                }
                return (
                    <ComponentWithLabelAndErrorMessage
                        Component={component}
                        error={error}
                        input={input}
                        labelClasses={labelClasses}
                        label={label}
                        className={inputClasses}
                        rest={{
                            type,
                            accept,
                            ...rest
                        }}
                    >
                        {children}
                    </ComponentWithLabelAndErrorMessage>
                );
            }}
        </Field>
    );
};

Input.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    type: PropTypes.oneOf(['text', 'multiline', 'dropdown', 'file']),
    accept: PropTypes.string
};

export default Input;
