import React from 'react';
import PropTypes from 'prop-types';

import DetailsHeader from './shared/DetailsHeader';
import Tabs from '../tabs/Tabs';
import {
    hasElements,
    navigateTo,
    hasSoftwareInformation,
    hasHardwareInformation
} from '../../common/utils';

import renderTags from './tabs/tags/renderTags';
import renderModKits from './tabs/modkits/renderModkits';
import renderHotFixes from './tabs/hotfixes/renderHotfixes';
import renderLogbook from './tabs/logbook/renderLogbook';
import renderUpdates from './tabs/updates/renderUpdates';
import renderHardwareInformation from './tabs/hardware/renderHardware';
import renderHardwareModules from './tabs/hardware/renderHardwareModules';
import renderSoftwarePackages from './tabs/software/renderSoftwarePackages';
import renderMap from './tabs/map/renderMap';
import renderQcData from './tabs/qcdata/renderQcData';

import styles from './details.module.scss';
import './shared/Modules.scss';

const Instrument = ({
    data,
    currentTab,
    selectedId,
    moduleIdentifier,
    subModuleIdentifier,
    onNavigateBack,
    parentPage,
    isLatestVersion
}) => {
    const getParentPage = tab => {
        return `${parentPage}/${selectedId}/${tab.toLowerCase()}`;
    };

    const navigateToTab = tab => {
        navigateTo(getParentPage(tab));
    };

    const getHardwareSubTab = () => {
        if (!!subModuleIdentifier) {
            return 'Channels';
        }
        if (!!moduleIdentifier) {
            return 'Modules';
        }

        return 'Details';
    };

    const getSoftwareSubTab = () => {
        if (!!subModuleIdentifier) {
            return 'Modules';
        }

        if (!!moduleIdentifier) {
            return 'Software Packages';
        }

        return null;
    };

    return (
        <article className={styles.Details}>
            <DetailsHeader
                data={data}
                onNavigateBack={onNavigateBack}
                withVersions
                instrumentId={selectedId}
            />
            <Tabs
                level={0}
                direction="vertical"
                setActiveTab={navigateToTab}
                currentTab={currentTab}
            >
                <div label="Hardware">
                    {hasHardwareInformation(data) ? (
                        <Tabs
                            level={1}
                            direction="horizontal"
                            currentTab={getHardwareSubTab()}
                        >
                            <div label="Details">
                                <section className={styles.ModuleProps}>
                                    <section className={styles.AllProperties}>
                                        {renderHardwareInformation(data)}
                                    </section>
                                </section>
                            </div>
                            {renderHardwareModules({
                                modules: data.modules,
                                instrumentId: selectedId,
                                parentPage: getParentPage('hardware'),
                                moduleIdentifier,
                                subModuleIdentifier
                            })}
                        </Tabs>
                    ) : (
                        <p>
                            There is no hardware information for this
                            instrument.
                        </p>
                    )}
                </div>
                <div label="Software">
                    {hasSoftwareInformation(data) ? (
                        <Tabs
                            level={1}
                            direction="horizontal"
                            currentTab={getSoftwareSubTab()}
                        >
                            {renderSoftwarePackages({
                                packages: data.software.softwarePackages,
                                modified: data.software.modified,
                                instrumentId: selectedId,
                                parentPage: getParentPage('software'),
                                moduleIdentifier,
                                subModuleIdentifier
                            })}
                        </Tabs>
                    ) : (
                        <p>
                            There is no software information for this
                            instrument.
                        </p>
                    )}
                </div>
                <div label="Tags" permission="instrumentDetails:tags">
                    <Tabs level={1} direction="horizontal">
                        <div label="Tags">
                            <section className={styles.Tags}>
                                <h4>Assigned tags</h4>
                                {hasElements(data.tags) ? (
                                    renderTags(data.tags, styles)
                                ) : (
                                    <p>The instrument has no assigned tags.</p>
                                )}
                            </section>
                        </div>
                    </Tabs>
                </div>
                <div label="HotFixes">
                    <Tabs level={1} direction="horizontal">
                        <div label="HotFixes">
                            <section className={styles.ModKitSection}>
                                {renderHotFixes({
                                    hotfixes: data.hotfixes,
                                    instrumentId: selectedId,
                                    isLatestVersion,
                                    styles
                                })}
                            </section>
                        </div>
                    </Tabs>
                </div>
                <div label="ModKits">
                    <Tabs level={1} direction="horizontal">
                        <div label="ModKits">
                            <section className={styles.ModKitSection}>
                                {renderModKits({
                                    modKits: data.modKits,
                                    instrumentId: selectedId,
                                    isLatestVersion,
                                    styles
                                })}
                            </section>
                        </div>
                    </Tabs>
                </div>
                <div label="Updates">
                    <Tabs level={1} direction="horizontal">
                        <div label="Updates">
                            <section className={styles.NotificationsSection}>
                                {renderUpdates({
                                    notifications: data.notifications,
                                    styles
                                })}
                            </section>
                        </div>
                    </Tabs>
                </div>
                <div label="Logbook">
                    <Tabs level={1} direction="horizontal">
                        <div label="Logbook">
                            <section className={styles.NotificationsSection}>
                                {renderLogbook({
                                    logbook: data.logbook,
                                    instrumentId: selectedId,
                                    isLatestVersion,
                                    styles
                                })}
                            </section>
                        </div>
                    </Tabs>
                </div>
                <div label="Map">
                    <Tabs level={1} direction="horizontal">
                        <div label="Map">
                            <section className={styles.MapSection}>
                                {renderMap({
                                    location: data.location,
                                    instrumentId: selectedId,
                                    isLatestVersion
                                })}
                            </section>
                        </div>
                    </Tabs>
                </div>
                <div label="QC Data">
                    <Tabs level={1} direction="horizontal">
                        <div label="QC Data">
                            <section>
                                {renderQcData({
                                    qcdata: data.qcData,
                                    styles
                                })}
                            </section>
                        </div>
                    </Tabs>
                </div>
            </Tabs>
        </article>
    );
};
Instrument.propTypes = {
    data: PropTypes.object.isRequired,
    onNavigateBack: PropTypes.func.isRequired,
    isLatestVersion: PropTypes.bool
};

Instrument.defaultProps = {
    parentPage: '/query'
};

export default Instrument;
