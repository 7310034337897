import { all } from 'redux-saga/effects';
import { fetchSagas } from '../controllers/fetch';
import { authSagas } from '../controllers/auth';
import { querySagas } from '../controllers/query';
import { uploadSagas } from '../controllers/upload';
import { modkitSagas } from '../controllers/modkit';
import { instrumentSagas } from '../controllers/instrument/sagas';
import { tagsSagas } from '../controllers/tags';
import { updateSagas } from '../controllers/update/sagas';
import { logbookSagas } from '../controllers/logbook/sagas';
import { locationSagas } from '../controllers/location/sagas';
import { supportSagas } from '../controllers/support';
import { hotfixSagas } from '../controllers/hotfix';

export function* helloSaga(action) {
    yield console.log(`${process.env.NODE_ENV} environment!`);
}

export function* rootSaga(action) {
    yield all([
        helloSaga(),
        fetchSagas(),
        authSagas(),
        querySagas(),
        uploadSagas(),
        modkitSagas(),
        instrumentSagas(),
        tagsSagas(),
        supportSagas(),
        updateSagas(),
        logbookSagas(),
        locationSagas(),
        hotfixSagas()
    ]);
}
