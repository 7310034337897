import React from 'react';
import { convertDate } from '../../../common/utils';

const LastModified = ({ lastModifiedAt, lastModifiedBy }) => (
    <div className="last-modified">
        <span className="last-modified-by">
            Last modified by: {lastModifiedBy}
        </span>
        <span className="last-modified-at">
            Last modified at: {convertDate(lastModifiedAt)}
        </span>
    </div>
);

export default LastModified;
