import React, { Component } from 'react';

import './statusMessage.scss';
import { PropTypes } from 'prop-types';
import { MESSAGE_DISPLAY_TIMEOUT } from '../../common/constants';

class StatusMessage extends Component {
    render = () => {
        const { message, statusClass } = this.props;

        return !!message ? <p className={statusClass}>{message}</p> : null;
    };

    componentDidMount = () => {
        if (this.props.onHide && this.props.message) {
            this.timer = setTimeout(this.props.onHide, MESSAGE_DISPLAY_TIMEOUT);
        }
    };

    componentWillUnmount = () => {
        if (this.timer) {
            clearTimeout(this.timer);
        }
    };
}

StatusMessage.propTypes = {
    message: PropTypes.string,
    statusClass: PropTypes.string,
    onHide: PropTypes.func
};

export default StatusMessage;
