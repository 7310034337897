import React from 'react';
import PropTypes from 'prop-types';
import Downshift from 'downshift';
import styles from './autocomplete.module.scss';
import Button from '../button/Button';
import AddIcon from '../image/AddIcon';
import Loading from '../loading/Loading';
import classnames from 'classnames';

const renderSuggestion = ({
    suggestion,
    itemProps,
    index,
    highlightedIndex
}) => {
    const isHighlighted = highlightedIndex === index;

    return (
        <div
            {...itemProps}
            selected={isHighlighted}
            className={classnames(
                styles.SuggestionItem,
                isHighlighted && styles.Highlight
            )}
            key={suggestion.id}
        >
            {suggestion.value}
        </div>
    );
};

const renderInput = ({
    getInputProps,
    placeholder,
    openMenu,
    value,
    onBlur,
    id
}) => {
    const inputProps = getInputProps({
        onFocus: openMenu,
        placeholder: placeholder,
        value
    });

    if (id) {
        inputProps.id = id;
    }

    if (typeof onBlur === 'function') {
        inputProps.onBlur = onBlur;
    }

    return <input {...inputProps} className={styles.Input} />;
};

const renderAction = ({
    actionTitle,
    onAction,
    actionDisabled,
    items,
    value
}) => {
    const showAction =
        typeof onAction === 'function' && !hasExactMatch(value, items);
    const isDisabled = value.trim().length === 0 || actionDisabled;

    return showAction ? (
        <div
            className={classnames(
                styles.InputAction,
                isDisabled ? styles.Disabled : ''
            )}
        >
            <Button
                look="Transparent"
                title={actionTitle}
                onClick={() => onAction(value)}
                disabled={isDisabled}
            >
                <AddIcon />
            </Button>
        </div>
    ) : null;
};

const trimValue = value => (!!value ? value.trim().toLowerCase() : '');

const getSuggestions = (value, items, showAllSuggestionsOnFocus) => {
    const inputValue = trimValue(value);
    const defaultList = showAllSuggestionsOnFocus ? items : [];
    return inputValue.length === 0
        ? defaultList
        : items.filter(i => trimValue(i.value).indexOf(inputValue) > -1);
};

const hasExactMatch = (value, items) => {
    const trimmedValue = trimValue(value);
    return items.filter(i => trimValue(i.value) === trimmedValue).length > 0;
};

const Autocomplete = props => {
    const {
        controlledValue,
        onValueChange,
        placeholder,
        items,
        actionTitle,
        onAction,
        actionDisabled,
        showAllSuggestionsOnFocus,
        input,
        loading,
        id
    } = props;

    return (
        <Downshift
            {...input}
            onChange={selection => selection && onValueChange(selection.value)}
            onInputValueChange={inputValue => {
                input && input.onChange(inputValue);
                onValueChange(inputValue);
            }}
            itemToString={item =>
                item.hasOwnProperty('value') ? item.value : controlledValue
            }
            selectedItem={controlledValue}
            defaultHighlightedIndex={0}
        >
            {({
                getInputProps,
                getItemProps,
                inputValue,
                isOpen,
                selectedItem,
                highlightedIndex,
                openMenu
            }) => (
                <div className={styles.Container}>
                    <div className={styles.InputContainer}>
                        {renderInput({
                            getInputProps,
                            placeholder,
                            value: controlledValue,
                            openMenu: showAllSuggestionsOnFocus
                                ? openMenu
                                : null,
                            onBlur:
                                input instanceof Object ? input.onBlur : null,
                            id
                        })}
                        {renderAction({
                            actionTitle,
                            onAction,
                            actionDisabled,
                            items,
                            value: controlledValue
                        })}
                    </div>
                    {isOpen ? (
                        <div className={styles.SuggestionContainer}>
                            <Loading loading={!!loading} mode="text">
                                {getSuggestions(
                                    inputValue,
                                    items,
                                    showAllSuggestionsOnFocus
                                ).map((suggestion, index) =>
                                    renderSuggestion({
                                        suggestion,
                                        itemProps: getItemProps({
                                            key: suggestion.id,
                                            item: suggestion,
                                            index
                                        }),
                                        index,
                                        highlightedIndex,
                                        selectedItem
                                    })
                                )}
                            </Loading>
                        </div>
                    ) : null}
                </div>
            )}
        </Downshift>
    );
};

Autocomplete.propTypes = {
    controlledValue: PropTypes.string,
    onValueChange: PropTypes.func,
    items: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
            value: PropTypes.string
        })
    ),
    placeholder: PropTypes.string,
    onAction: PropTypes.func,
    actionTitle: PropTypes.string,
    showAllSuggestionsOnFocus: PropTypes.bool,
    loading: PropTypes.bool,
    id: PropTypes.string
};

Autocomplete.defaultProps = {
    controlledValue: ''
};

export default Autocomplete;
