import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';

import { unassignTagFromInstrumentsAction } from '../../controllers/tags';
import Confirmation from '../confirmation/Confirmation';

const RemoveTagFromInstrumentsForm = props => {
    const {
        tagId,
        tagList,
        selectedInstruments,
        unassignTagFromInstrumentsAction
    } = props;

    const tagName = tagList.find(t => t.id === tagId).name;

    const message = `You are about to remove <span class="ConfirmMessageHighlightSpace"> ${
        selectedInstruments.length
    } </span>
    ${
        selectedInstruments.length === 1 ? 'instrument' : 'instruments'
    } from tag '<span class="ConfirmMessageHighlight">${tagName}</span>'`;

    return (
        <Confirmation
            onConfirm={() =>
                unassignTagFromInstrumentsAction(tagId, selectedInstruments)
            }
            message={parse(message)}
        />
    );
};

RemoveTagFromInstrumentsForm.propTypes = {
    tagList: PropTypes.array,
    selectedInstruments: PropTypes.arrayOf(PropTypes.string),
    unassignTagFromInstrumentsAction: PropTypes.func
};

export default connect(
    ({ selectedInstruments, tags }) => ({
        selectedInstruments,
        tagList: tags.items
    }),
    {
        unassignTagFromInstrumentsAction
    }
)(RemoveTagFromInstrumentsForm);
