import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Input from '../input/Input';
import Button from '../button/Button';
import Loading from '../loading/Loading';
import styles from './modkit.module.scss';
import { fetchTypesAction } from '../../controllers/modkit/actions';
import errors from '../input/errorMessages.json';

const renderDropDownOptions = items =>
    items.map(item => (
        <option key={item} value={item}>
            {item}
        </option>
    ));

export const validateDocumentNumber = value => {
    if (typeof value === 'undefined') {
        return errors['Required'];
    }

    const regex = new RegExp('^[a-zA-Z.0-9_-]+$');
    if (!regex.test(value)) {
        return 'The value can contain only digits, letters, fullstops, minuses or underscores!';
    }

    if (value.length > 50) {
        return 'The value can not exceed 50 symbols!';
    }

    return undefined;
};

const FormContent = ({
    handleSubmit,
    pristine,
    invalid,
    fetchTypesAction,
    items,
    loading,
    message,
    isMessageLoading,
    form
}) => {
    useEffect(() => {
        fetchTypesAction();
    }, []);

    const onSubmit = event => {
        event.preventDefault();
        if (pristine || invalid || isMessageLoading) return;
        handleSubmit(event).then(() => form.reset(form.getState().values));
    };

    return loading ? (
        <Loading loading={loading} imageSize={48} />
    ) : (
        <form className="CreateModkitForm" onSubmit={onSubmit}>
            <div className={styles.ButtonGroup}>
                <Input
                    name="documentNumber"
                    label="Document Number"
                    type="text"
                    component="input"
                    validate={validateDocumentNumber}
                    required
                />
                <Input
                    name="platformType"
                    label="Platform Type"
                    type="dropdown"
                    component="select"
                    required
                >
                    {renderDropDownOptions(items)}
                </Input>
                <Input
                    name="name"
                    label="Description"
                    type="multiline"
                    component="textarea"
                />
                <div className={styles.MessageContainer}>
                    {isMessageLoading && (
                        <Loading
                            loading={isMessageLoading}
                            text="Submitting"
                            mode="text"
                        />
                    )}
                </div>
                <Button
                    type="submit"
                    look="Primary"
                    behaviour="button"
                    width="FullWidth"
                    disabled={pristine || invalid || isMessageLoading}
                >
                    Create
                </Button>
            </div>
        </form>
    );
};

export default connect(
    ({ platformTypes, createModKit }) => ({
        items: platformTypes.items,
        loading: platformTypes.loading,
        message: createModKit.message,
        isMessageLoading: createModKit.loading
    }),
    {
        fetchTypesAction
    }
)(FormContent);
