import {
    ADD_MODKIT_TO_STORE,
    CHANGE_MODKIT_STATUS,
    CHANGE_MODKIT_STATUS_FOR_INSTRUMENT,
    REMOVE_MODKIT_FROM_STORE
} from '../constants';
import { orderModKits } from './orderModkits';
import { FETCH_SUCCESS } from '../../fetch';
import { MODKIT_STATUS } from '../../../common/utils/details';

export const modifyModkitListReducer = (
    state,
    { type, id, payload, modkit, operation, actionStatus }
) => {
    switch (type) {
        case ADD_MODKIT_TO_STORE: {
            return {
                ...state,
                items: orderModKits(state.items.concat([modkit]))
            };
        }
        case REMOVE_MODKIT_FROM_STORE: {
            return {
                ...state,
                items: state.items.filter(modkit => modkit.id !== id)
            };
        }
        case CHANGE_MODKIT_STATUS_FOR_INSTRUMENT: {
            if (operation === 'apply' && actionStatus === FETCH_SUCCESS) {
                return {
                    ...state,
                    items: state.items.map(modkit => {
                        if (modkit.id === id) {
                            return {
                                ...modkit,
                                status: MODKIT_STATUS.USED.toUpperCase()
                            };
                        }
                        return modkit;
                    })
                };
            }

            return state;
        }
        case CHANGE_MODKIT_STATUS: {
            return {
                ...state,
                items: state.items.map(modkit => {
                    if (modkit.id === id) {
                        return {
                            ...modkit,
                            ...payload
                        };
                    }

                    return modkit;
                })
            };
        }
        default:
            return state;
    }
};
