import { CLEAR_CHANGE_MODKIT_STATUS } from '../constants';

export const changeModKitStatusReducer = (state, { type }) => {
    switch (type) {
        case CLEAR_CHANGE_MODKIT_STATUS:
            return {};
        default:
            return state;
    }
};
