export const getUpdateNameFromPath = path => {
    if (!path) {
        return undefined;
    }

    return path
        .toString()
        .split('/')
        .slice(-1)[0];
};

export const UPDATE_STATUS = {
    NEW: 'New',
    CANCELLED: 'Cancelled'
};

export const UPDATE_TYPE = {
    UPDATE_SOFTWARE: 'UPDATE_SOFTWARE',
    UPDATE_CONFIGURATION: 'UPDATE_CONFIGURATION'
};

export const getKeyByValue = (obj, value) => {
    if (!obj) return undefined;
    return Object.keys(obj).find(key => obj[key] === value);
};
